import classNames from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { OrderItemFragment } from "src/common/types/Order";
import styles from "src/pages/Order/ItemsList/OrderItem/styles.module.scss";
import { State } from "src/state/state";

interface ComponentProps {
  orderItem: OrderItemFragment;
}

export const OrderItem = ({ orderItem }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const dealApplied = useSelector(
    (state: State) =>
      restaurant &&
      orderItem.dealAppliedId &&
      state.deals[restaurant.id] &&
      state.deals[restaurant.id][orderItem.dealAppliedId],
  );

  const selectedOptionsText = useMemo(() => {
    if (orderItem.orderItemOptions.length === 0) {
      return "";
    }

    let finalText = "\n";

    for (const optionSelected of orderItem.orderItemOptions) {
      const optionName = optionSelected.option.name;
      const optionValueNames = optionSelected.optionValues.map(
        (optionValue) => optionValue.name,
      );

      finalText += `${optionName}: ${optionValueNames.join(", ")}\n`;
    }

    return finalText.slice(0, -1);
  }, [orderItem]);

  const optionsPrice = useMemo(
    () =>
      orderItem.priceAfterDealAfterOptions -
      orderItem.priceAfterDealBeforeOptions,
    [orderItem],
  );

  return (
    <div
      className={classNames(styles.OrderItem, {
        [styles.refunded]: orderItem.isRefunded,
      })}
      data-testid={`order-screen-item-${orderItem.itemId}-container`}
    >
      <div className={styles.detailsRow}>
        <p
          data-testid={`order-screen-item-${orderItem.itemId}-quantity`}
          className={classNames(styles.mainText, styles.setTextWidth)}
        >
          <span
            className={styles.quantityText}
          >{`${orderItem.quantity}x`}</span>
          {` ${orderItem.itemName}${orderItem.isRefunded ? " (Refunded)" : ""}`}
        </p>
        {dealApplied &&
        orderItem.priceBeforeDealBeforeOptions !==
          orderItem.priceAfterDealBeforeOptions ? (
          <div className={styles.strikethroughRow}>
            <p
              data-testid={`order-screen-item-${orderItem.itemId}-deal-applied-old-price`}
              className={classNames(styles.mainText, styles.beforeDealPrice)}
            >{`$${orderItem.priceBeforeDealBeforeOptions.toFixed(2)}`}</p>
            <p
              data-testid={`order-screen-item-${orderItem.itemId}-deal-applied-new-price`}
              className={styles.mainText}
            >{`$${orderItem.priceAfterDealBeforeOptions.toFixed(2)}`}</p>
          </div>
        ) : (
          <p
            data-testid={`order-screen-item-${orderItem.itemId}-price-before-options`}
            className={styles.mainText}
          >{`$${orderItem.priceAfterDealBeforeOptions.toFixed(2)}`}</p>
        )}
      </div>
      {selectedOptionsText.length > 0 && (
        <div className={styles.detailsRow}>
          <p
            className={classNames(styles.subText, styles.setTextWidth)}
          >{`${selectedOptionsText}`}</p>
          <p
            className={styles.subText}
            data-testid={`order-screen-item-${orderItem.itemId}-options-price`}
          >{`$${optionsPrice.toFixed(2)}`}</p>
        </div>
      )}
      {dealApplied &&
        orderItem.priceBeforeDealBeforeOptions !==
          orderItem.priceAfterDealBeforeOptions && (
          <p
            data-testid={`order-screen-item-${orderItem.itemId}-deal-applied-name`}
            className={classNames(
              styles.dealRow,
              styles.subText,
              styles.setTextWidth,
            )}
          >{`Deal Applied: ${dealApplied.name}`}</p>
        )}
    </div>
  );
};
