// Interacts with the server
import { getEnvVariable, getCurrentEnvironment } from "src/config/getConfig";
import { getIdTokenFromAWSCognito } from "src/common/auth";
import { captureManualSentryException } from "src/common/sentry";

type Service = "thanos" | "eugene";

interface Request {
  service: Service;
  endpoint: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any>;
}

const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

// Makes a post request to a service and returns the json
export const post = async (
  request: Request,
  currentTryCount = 1,
): Promise<object> => {
  const { service, endpoint, params } = request;

  if (getCurrentEnvironment() === "test") {
    return await postWithoutAuth(request);
  }

  const endpointUrl = constructEndpointURL(service, endpoint);
  const headers = await constructPostHeaders();

  let response: Response | undefined = undefined;
  let json = undefined;
  let error: Error | undefined = undefined;

  try {
    response = await fetch(endpointUrl, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    });

    if (response.status === 200) {
      json = await response.json();
    }
  } catch (e) {
    error = e as Error;
  }

  if (!json || !response || error || (response && response.status !== 200)) {
    if (currentTryCount === 5) {
      captureManualSentryException(
        new Error(`Failed to post to ${endpointUrl} after 5 tries.`),
      );

      return {};
    } else {
      const timeInMsBetweenRetries = 10000;
      await sleep(timeInMsBetweenRetries);

      return await post(request, currentTryCount + 1);
    }
  } else {
    return json;
  }
};

export const postWithoutAuth = async (request: Request) => {
  const { service, endpoint, params } = request;
  const endpointUrl = constructEndpointURL(service, endpoint);

  const response = await fetch(endpointUrl, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();

  return json;
};

const constructEndpointURL = (service: Service, endpoint: string) => {
  const serviceUrl =
    service === "thanos"
      ? getEnvVariable("THANOS_URL")
      : getEnvVariable("EUGENE_URL");
  return serviceUrl + endpoint;
};

const constructPostHeaders = async () => {
  const ourRefreshToken = getEnvVariable("AWS_COGNITO_ANONYMOUS_REFRESH_TOKEN");

  const newIdToken = await getIdTokenFromAWSCognito(ourRefreshToken);

  const XCognitoUserPoolId = getEnvVariable(
    "AWS_COGNITO_ANONYMOUS_USER_POOL_ID",
  );
  const XCognitoClientId = getEnvVariable("AWS_COGNITO_ANONYMOUS_CLIENT_ID");

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${newIdToken}`,
    "X-Cognito-User-Pool-Id": XCognitoUserPoolId,
    "X-Cognito-Client-Id": XCognitoClientId,
  };
};
