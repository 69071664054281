// Set of functions to interact and log to BigQuery
import { captureManualSentryException } from "src/common/sentry";
import { post } from "src/api/server";
import { DatePickerType } from "src/components/DateRangePicker/DateRangePicker";
import { ORDER_STATUS } from "src/common/types/Order";
import { EXPORT_TYPE } from "src/common/types/Export";
import { store } from "src/state/store";

export enum AnalyticsEvent {
  LOG_IN_FAILURE = "LOG_IN_FAILURE",
  LOG_IN_INITIATED = "LOG_IN_INITIATED",
  LOG_IN_SUCCESS = "LOG_IN_SUCCESS",
  CHANGE_ANALYTICS_TIMEFRAME = "CHANGE_ANALYTICS_TIMEFRAME",
  WEBSITE_VISIT = "WEBSITE_VISIT",
  UPDATE_CATEGORIES_PUBLISHED_STATUS = "UPDATE_CATEGORIES_PUBLISHED_STATUS",
  UPDATE_ITEMS_PUBLISHED_STATUS = "UPDATE_ITEMS_PUBLISHED_STATUS",
  UPDATE_OPTIONS_PUBLISHED_STATUS = "UPDATE_OPTIONS_PUBLISHED_STATUS",
  UPDATE_DEALS_PUBLISHED_STATUS = "UPDATE_DEALS_PUBLISHED_STATUS",
  SIGN_OUT = "SIGN_OUT",
  UPDATE_HOURS_OF_OPERATION_MODE = "UPDATE_HOURS_OF_OPERATION_MODE",
  UPDATE_HOURS_OF_OPERATION = "UPDATE_HOURS_OF_OPERATION",
  UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS",
  COMPLETE_ORDER = "COMPLETE_ORDER",
  CANCEL_ORDER = "CANCEL_ORDER",
  EXPORT_REPORT = "EXPORT_REPORT",
  EXPORT_MONTHLY_STATEMENT = "EXPORT_MONTHLY_STATEMENT",
  UPDATE_DESIGN_SETTINGS = "UPDATE_DESIGN_SETTINGS",
  REFUND_ORDER_ITEM = "REFUND_ORDER_ITEM",
  VIEW_CATERING_TAB = "VIEW_CATERING_TAB",
  VIEW_CATERING_REQUEST = "VIEW_CATERING_REQUEST",
  CLICK_CREATE_INVOICE_MODAL = "CLICK_CREATE_INVOICE_MODAL",
  RESTAURANT_CONTACT_US_VIEWED = "RESTAURANT_CONTACT_US_VIEWED",
  RESTAURANT_CONTACT_US_SUBMITTED = "RESTAURANT_CONTACT_US_SUBMITTED",
  UPDATE_SOCIAL_MEDIA_LINKS = "UPDATE_SOCIAL_MEDIA_LINKS",
  UPDATE_ITEM = "UPDATE_ITEM",
  DELETE_ITEM = "DELETE_ITEM",
}

export const createAnalyticsEventInBigQuery = async (
  event: AnalyticsEvent,
  params: Record<string, unknown>,
  restaurantId?: string,
) => {
  try {
    const sessionId = store.getState().session.sessionId as string;

    await post({
      service: "eugene",
      endpoint: "/analytics/create",
      params: {
        analyticsEvent: {
          event,
          params,
          user_id: restaurantId,
          product: "restaurant_website",
          sessionId,
        },
      },
    });
  } catch (e) {
    captureManualSentryException(e as Error);
  }
};

export type LogInFailureReason =
  | "FIELDS_MISSING"
  | "INVALID_EMAIL"
  | "INCORRECT_EMAIL_OR_PASSWORD"
  | "UNKNOWN_ERROR";

export const logLogInFailureToAnalytics = (
  failureReason: LogInFailureReason,
  otherError?: string,
) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.LOG_IN_FAILURE, {
    failureReason,
    otherError,
  });
};

export const logLogInInitiatedToAnalytics = () => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.LOG_IN_INITIATED, {});
};

export const logLogInSuccessToAnalytics = (restaurantId: string) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.LOG_IN_SUCCESS,
    {},
    restaurantId,
  );
};

export const logChangeAnalyticsTimeframeToAnalytics = (
  timeframe: DatePickerType,
  restaurantId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CHANGE_ANALYTICS_TIMEFRAME,
    {
      timeframe,
    },
    restaurantId,
  );
};

export const logWebsiteVisitToAnalytics = (restaurantId: string) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.WEBSITE_VISIT,
    {},
    restaurantId,
  );
};

export const logUpdateCategoriesPublishedStatusToAnalytics = (
  restaurantId: string,
  categoryIds: string[],
  newStatus: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_CATEGORIES_PUBLISHED_STATUS,
    {
      categoryIds,
      newStatus,
    },
    restaurantId,
  );
};

export const logUpdateItemsPublishedStatusToAnalytics = (
  restaurantId: string,
  itemIds: string[],
  newStatus: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_ITEMS_PUBLISHED_STATUS,
    {
      itemIds,
      newStatus,
    },
    restaurantId,
  );
};

export const logUpdateOptionsPublishedStatusToAnalytics = (
  restaurantId: string,
  optionIds: string[],
  newStatus: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_OPTIONS_PUBLISHED_STATUS,
    {
      optionIds,
      newStatus,
    },
    restaurantId,
  );
};

export const logUpdateDealsPublishedStatusToAnalytics = (
  restaurantId: string,
  dealIds: string[],
  newStatus: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_DEALS_PUBLISHED_STATUS,
    {
      dealIds,
      newStatus,
    },
    restaurantId,
  );
};

export const logSignOutToAnalytics = (restaurantId: string) => {
  createAnalyticsEventInBigQuery(AnalyticsEvent.SIGN_OUT, {}, restaurantId);
};

export const logUpdateHoursOfOperationModeInAnalytics = (
  restaurantId: string,
  locationId: string,
  mode: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_HOURS_OF_OPERATION_MODE,
    {
      restaurantId,
      locationId,
      mode,
    },
    restaurantId,
  );
};

export const logUpdateHoursOfOperationInAnalytics = (
  restaurantId: string,
  locationId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_HOURS_OF_OPERATION,
    {
      restaurantId,
      locationId,
    },
    restaurantId,
  );
};

export const logUpdateOrderStatusInAnalytics = (
  restaurantId: string,
  orderId: string,
  orderStatus: ORDER_STATUS,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_ORDER_STATUS,
    {
      orderId,
      orderStatus,
    },
    restaurantId,
  );
};

export const logCompleteOrderInAnalytics = (
  restaurantId: string,
  orderId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.COMPLETE_ORDER,
    {
      orderId,
    },
    restaurantId,
  );
};

export const logCancelOrderInAnalytics = (
  restaurantId: string,
  orderTotal: number,
  cancellationReason: string,
) => {
  const params: Record<string, unknown> = {
    orderTotal,
    cancellationReason: cancellationReason,
  };

  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CANCEL_ORDER,
    params,
    restaurantId,
  );
};

export const logExportReportInAnalytics = (
  restaurantId: string,
  reportType: EXPORT_TYPE,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.EXPORT_REPORT,
    {
      reportType,
    },
    restaurantId,
  );
};

export const logExportMonthlyStatementInAnalytics = (
  restaurantId: string,
  month: string,
  year: number,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.EXPORT_MONTHLY_STATEMENT,
    {
      month,
      year,
    },
    restaurantId,
  );
};

export const logUpdateDesignSettingsInAnalytics = (
  restaurantId: string,
  announcementBar: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_DESIGN_SETTINGS,
    { announcementBar },
    restaurantId,
  );
};

export const logUpdateSocialMediaLinksInAnalytics = (
  restaurantId: string,
  facebook: string,
  instagram: string,
  yelp: string,
  doordash: string,
  ubereats: string,
  grubhub: string,
  postmates: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_SOCIAL_MEDIA_LINKS,
    {
      facebook,
      instagram,
      yelp,
      doordash,
      ubereats,
      grubhub,
      postmates,
    },
    restaurantId,
  );
};

export const logRefundOrderItemToAnalytics = (
  restaurantId: string,
  orderId: string,
  orderItemId: string,
  itemId: string,
  itemName: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.REFUND_ORDER_ITEM,
    {
      orderId,
      orderItemId,
      itemId,
      itemName,
    },
    restaurantId,
  );
};

export const logViewCateringTabToAnalytics = (restaurantId: string) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.VIEW_CATERING_TAB,
    {},
    restaurantId,
  );
};

export const logViewCateringRequestToAnalytics = (
  restaurantId: string,
  cateringRequestId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.VIEW_CATERING_REQUEST,
    { cateringRequestId },
    restaurantId,
  );
};

export const logClickCreateInvoiceModalToAnalytics = (
  restaurantId: string,
  cateringRequestId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.CLICK_CREATE_INVOICE_MODAL,
    { cateringRequestId },
    restaurantId,
  );
};

export const logRestaurantContactUsViewedToAnalytics = () => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.RESTAURANT_CONTACT_US_VIEWED,
    {},
  );
};

export const logRestaurantContactUsSubmittedToAnalytics = (
  restaurantName: string,
  emailAddress: string,
  phoneNumber: string,
  requestType: string,
  additionalNotes: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.RESTAURANT_CONTACT_US_SUBMITTED,
    {
      restaurantName,
      emailAddress,
      phoneNumber,
      requestType,
      additionalNotes,
    },
  );
};

export const logUpdateItemInAnalytics = (
  restaurantId: string,
  itemId: string,
  itemNameUpdated: boolean,
  itemDescriptionUpdated: boolean,
  itemPriceUpdated: boolean,
  itemImageUpdated: boolean,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.UPDATE_ITEM,
    {
      itemId,
      itemNameUpdated,
      itemDescriptionUpdated,
      itemPriceUpdated,
      itemImageUpdated,
    },
    restaurantId,
  );
};

export const logDeleteItemInAnalytics = (
  restaurantId: string,
  itemId: string,
) => {
  createAnalyticsEventInBigQuery(
    AnalyticsEvent.DELETE_ITEM,
    {
      itemId,
    },
    restaurantId,
  );
};
