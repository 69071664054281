import { PageTitle } from "src/components";
import styles from "src/pages/Settings/styles.module.scss";
import { SettingsItem } from "src/pages/Settings/SettingsItem/SettingsItem";
import {
  getDesignRoute,
  getHoursOfOperationRoute,
  getSocialMediaLinks,
} from "src/Router/routes";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faPaintBrush, faUsers } from "@fortawesome/free-solid-svg-icons";

export const Settings = () => {
  return (
    <div className={styles.Settings} data-testid="settings-container">
      <PageTitle
        title="Settings"
        subtitle={`Manage settings for your online restaurant.`}
      />
      <div className={styles.settingsItemsContainer}>
        <SettingsItem
          testId="settings-hours-of-operation"
          icon={faClock}
          title="Hours of Operation"
          subtitle="Manage what times your customers can order from your locations."
          route={getHoursOfOperationRoute()}
        />
        <SettingsItem
          testId="settings-design"
          icon={faPaintBrush}
          title="Design"
          subtitle="Manage your restaurant's visual and design settings."
          route={getDesignRoute()}
        />
        <SettingsItem
          testId="settings-social-media"
          icon={faUsers}
          title="Social Media Links"
          subtitle="Customize which social media links are displayed on your website."
          route={getSocialMediaLinks()}
        />
      </div>
    </div>
  );
};
