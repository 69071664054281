import { useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "src/pages/Order/ItemsList/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { OrderItem } from "src/pages/Order/ItemsList/OrderItem/OrderItem";

interface ComponentProps {
  orderId: string;
}

export const ItemsList = ({ orderId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      state.orders[restaurant.id][orderId],
  );

  const orderItems = useMemo(() => order.orderItems, [order]);

  return (
    <div className={styles.ItemList}>
      <h3
        className={styles.title}
      >{`${orderItems.length} Item${orderItems.length > 1 ? "s" : ""}`}</h3>
      {orderItems.map((orderItem) => (
        <OrderItem key={orderItem.itemId} orderItem={orderItem} />
      ))}
    </div>
  );
};
