import Modal from "react-awesome-modal";
import { useMediaQuery } from "react-responsive";
import { LineItem } from "src/state/catering/types";
import styles from "src/pages/CateringRequest/CreateInvoiceModal/styles.module.scss";
import { RequestDetails } from "src/pages/CateringRequest/RequestDetails/RequestDetails";
import { CustomerDetails } from "src/pages/CateringRequest/CustomerDetails/CustomerDetails";
import { Button, TextInput } from "src/components";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { formatNumber, isValidPositiveNumber } from "src/common/number";
import {
  createCateringInvoiceInDatabase,
  getSalesTaxRateForCateringRequest,
} from "src/common/catering";
import { getServiceFeeForCateringRequest } from "src/common/serviceFee";
import { showToast } from "src/common/toast";

interface CreateInvoiceModalProps {
  cateringRequestId: string;
  isVisible: boolean;
  onClose: () => void;
}

type LineItemInput = {
  title: string;
  amountInDollars: string;
};

export const CreateInvoiceModal = ({
  cateringRequestId,
  isVisible,
  onClose,
}: CreateInvoiceModalProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 875px)" });

  const [lineItems, setLineItems] = useState<LineItemInput[]>([
    { title: "", amountInDollars: "" },
  ]);
  const [isAmountError, setIsAmountError] = useState(false);
  const [isTitleError, setIsTitleError] = useState(false);

  const [subtotal, setSubtotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [salesTaxRate, setSalesTaxRate] = useState(0);
  const [isLoadingSalesTaxRate, setIsLoadingSalesTaxRate] = useState(false);
  const [isTipRequired, setIsTipRequired] = useState(false);
  const [requiredTipAmount, setRequiredTipAmount] = useState("");
  const [isTipError, setIsTipError] = useState(false);

  const loadSalesTaxRate = useCallback(async () => {
    setIsLoadingSalesTaxRate(true);
    const calculatedSalesTaxRate =
      await getSalesTaxRateForCateringRequest(cateringRequestId);
    setSalesTaxRate(calculatedSalesTaxRate);
    setIsLoadingSalesTaxRate(false);
  }, []);

  useEffect(() => {
    if (isVisible) {
      loadSalesTaxRate();
    }
  }, [cateringRequestId, isVisible]);

  useEffect(() => {
    let newSubtotal = 0;
    let newTipAmount = 0;

    lineItems.forEach((item) => {
      if (isValidPositiveNumber(item.amountInDollars)) {
        newSubtotal += parseFloat(item.amountInDollars);
      }
    });

    setSubtotal(newSubtotal);

    if (isTipRequired && isValidPositiveNumber(requiredTipAmount)) {
      newTipAmount = parseFloat(requiredTipAmount);
    }

    const salesTaxTotal = newSubtotal * parseFloat(salesTaxRate.toString());
    const serviceFee =
      newSubtotal === 0 ? 0 : getServiceFeeForCateringRequest(newSubtotal);

    setTaxes(salesTaxTotal);
    setServiceFee(serviceFee);
    setTotal(newSubtotal + salesTaxTotal + serviceFee + newTipAmount);
  }, [lineItems, requiredTipAmount, isVisible]);

  const handleCreateInvoice = useCallback(async () => {
    let hasError = false;
    const localValidatedLineItems: LineItem[] = [];

    lineItems.forEach((item) => {
      if (item.title === "" && item.amountInDollars === "") {
        hasError = true;
        setIsTitleError(true);
        setIsAmountError(true);
        return;
      }

      if (item.title === "" && item.amountInDollars !== "") {
        hasError = true;
        setIsTitleError(true);
        setIsAmountError(false);
        return;
      }

      if (item.title !== "" && item.amountInDollars === "") {
        hasError = true;
        setIsAmountError(true);
        setIsTitleError(false);
        return;
      }

      if (!isValidPositiveNumber(item.amountInDollars)) {
        hasError = true;
        setIsAmountError(true);
        return;
      }

      localValidatedLineItems.push({
        title: item.title,
        amountInDollars: parseFloat(item.amountInDollars),
      });
    });

    if (
      !hasError &&
      isTipRequired &&
      !isValidPositiveNumber(requiredTipAmount)
    ) {
      setIsTipError(true);
      return;
    }

    if (hasError) {
      return;
    }

    setIsAmountError(false);
    setIsTitleError(false);
    setIsTipError(false);

    const requiredTipAmountInDollars = isTipRequired
      ? parseFloat(requiredTipAmount)
      : 0;

    await createCateringInvoiceInDatabase(
      cateringRequestId,
      localValidatedLineItems,
      requiredTipAmountInDollars,
    );

    setLineItems([{ title: "", amountInDollars: "" }]);
    setRequiredTipAmount("");
    onClose();
    showToast("Invoice sent to customer successfully");
  }, [
    cateringRequestId,
    lineItems,
    requiredTipAmount,
    onClose,
    setIsAmountError,
    setIsTitleError,
    setIsTipError,
  ]);

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      visible={isVisible}
      width={isDesktop ? "750" : "350"}
      height={isDesktop ? "725" : "550"}
      effect="fadeInUp"
      onClickAway={onClose}
    >
      <div
        className={styles.CreateInvoiceModal}
        data-testid="create-invoice-modal-container"
      >
        <h2 className={styles.title} data-testid="invoice-title">
          Invoice
        </h2>
        <div
          className={styles.requestDetailsContainer}
          data-testid="request-details-container"
        >
          <RequestDetails cateringRequestId={cateringRequestId} />
          <CustomerDetails cateringRequestId={cateringRequestId} />
        </div>
        <h4 className={styles.lineItemsTitle} data-testid="line-items-title">
          Line items to be included in the invoice:
        </h4>
        <div
          className={styles.lineItemsContainer}
          data-testid="line-items-container"
        >
          {lineItems.map((item, index) => (
            <div
              className={styles.lineItemsRow}
              key={index}
              data-testid={`line-item-row-${index}`}
            >
              <div
                className={classNames(styles.eachLineItem, {
                  [styles.eachLineItemWithRemove]: lineItems.length > 1,
                })}
                data-testid={`each-line-item-${index}`}
              >
                <TextInput
                  type="text"
                  className={styles.titleInput}
                  placeholder="Subtotal"
                  value={item.title}
                  onChangeText={(value) => {
                    setIsTitleError(false);
                    setIsAmountError(false);
                    const newItems = [...lineItems];
                    newItems[index].title = value;
                    setLineItems(newItems);
                  }}
                  testIdPrefix={`title-input-${index}`}
                />
              </div>
              <div
                className={classNames(styles.eachLineItem, {
                  [styles.eachLineItemWithRemove]: lineItems.length > 1,
                })}
                data-testid={`each-line-item-amount-${index}`}
              >
                <div
                  className={styles.amountInputContainer}
                  data-testid={`amount-input-container-${index}`}
                >
                  <span
                    className={styles.moneySymbol}
                    data-testid={`money-symbol-${index}`}
                  >
                    $
                  </span>
                  <TextInput
                    type="text"
                    className={styles.amountInput}
                    placeholder="0"
                    value={item.amountInDollars}
                    onChangeText={(value) => {
                      setIsTitleError(false);
                      setIsAmountError(false);
                      const newItems = [...lineItems];
                      newItems[index].amountInDollars = value;
                      setLineItems(newItems);
                    }}
                    testIdPrefix={`amount-input-${index}`}
                  />
                </div>
              </div>
              {lineItems.length > 1 && (
                <div
                  className={styles.removeLineButton}
                  data-testid={`remove-line-item-button-${index}`}
                  onClick={() => {
                    const newLineItems = [...lineItems];
                    newLineItems.splice(index, 1);
                    setLineItems(newLineItems);
                  }}
                >
                  <p className={styles.removeLineText}>-</p>
                </div>
              )}
            </div>
          ))}
          <Button
            testId="add-line-item-button"
            text="Add Line Item"
            className={styles.addLineButton}
            onClick={() =>
              setLineItems([...lineItems, { title: "", amountInDollars: "" }])
            }
          />
        </div>

        <div className={styles.totalsContainer} data-testid="totals-container">
          <div className={styles.requiredTipRow} data-testid="tip-row">
            <div className={styles.tipCheckboxContainer}>
              <input
                type="checkbox"
                className={styles.tipCheckbox}
                checked={isTipRequired}
                onChange={() => {
                  setIsTipRequired(!isTipRequired);
                  setIsTipError(false);
                  setRequiredTipAmount("");
                }}
                data-testid="tip-checkbox"
              />
              <p className={styles.subtotalsText} data-testid="tip-text">
                Include Required Tip
              </p>
            </div>
            {isTipRequired && (
              <div
                className={styles.amountInputContainer}
                data-testid="tip-input-container"
              >
                <span className={styles.moneySymbol}>$</span>
                <TextInput
                  type="text"
                  className={styles.amountInput}
                  placeholder="0"
                  value={requiredTipAmount}
                  onChangeText={(value) => {
                    setIsTipError(false);
                    setRequiredTipAmount(value);
                  }}
                  testIdPrefix="tip-amount"
                />
              </div>
            )}
          </div>

          <div className={styles.totalsRow} data-testid="subtotal-row">
            <p className={styles.subtotalsText} data-testid="subtotal-text">
              Subtotal
            </p>
            <p className={styles.subtotalsText} data-testid="subtotal-amount">
              {formatNumber(subtotal)}
            </p>
          </div>

          <div className={styles.totalsRow} data-testid="taxes-and-fees-row">
            <p
              className={styles.subtotalsText}
              data-testid="taxes-and-fees-text"
            >
              Taxes
            </p>
            {isLoadingSalesTaxRate ? (
              <p className={styles.subtotalsText}>-</p>
            ) : (
              <p
                className={styles.subtotalsText}
                data-testid="taxes-and-fees-amount"
              >
                {formatNumber(taxes)}
              </p>
            )}
          </div>
          <div className={styles.totalsRow} data-testid="service-fee-row">
            <p className={styles.subtotalsText} data-testid="subtotal-text">
              Platter Service Fee
            </p>
            <p
              className={styles.subtotalsText}
              data-testid="service-fee-amount"
            >
              {formatNumber(serviceFee)}
            </p>
          </div>
          <div className={styles.totalsRow} data-testid="total-charged-row">
            <p className={styles.totalsText} data-testid="total-charged-text">
              Total
            </p>
            <p className={styles.totalsText} data-testid="total-charged-amount">
              {formatNumber(total)}
            </p>
          </div>
        </div>

        <div className={styles.buttonContainer} data-testid="button-container">
          <Button
            testId="cancel-create-invoice-button"
            text="Cancel"
            className={styles.cancelButton}
            secondaryError
            onClick={onClose}
          />
          <Button
            testId="create-invoice-button-modal-submit"
            text="Create & Send Invoice"
            className={styles.createInvoiceButton}
            onClick={handleCreateInvoice}
          />
        </div>
        {isAmountError && (
          <p className={styles.errorText} data-testid="amount-error-text">
            Invalid amount. Ensure all line item amounts are valid, positive
            numbers.
          </p>
        )}
        {isTitleError && (
          <p className={styles.errorText} data-testid="title-error-text">
            Invalid Line Item Name. Ensure all line items have a name.
          </p>
        )}
        {isTipError && (
          <p className={styles.errorText} data-testid="tip-error-text">
            Invalid tip amount.
          </p>
        )}
      </div>
    </Modal>
  );
};
