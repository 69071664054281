// Restaurant reducer
import {
  ITEM_ACTION,
  UpdatePublishedStatusForMultipleItemsInDatabaseAction,
  GetAllItemsForRestaurantFromDatabaseAction,
  UpdateItemInDatabaseAction,
  DeleteItemFromDatabaseAction,
  CreateItemInDatabaseAction,
} from "src/state/item/actions";
import { ItemState } from "src/state/item/types";

type ItemAction =
  | UpdateItemInDatabaseAction
  | UpdatePublishedStatusForMultipleItemsInDatabaseAction
  | GetAllItemsForRestaurantFromDatabaseAction
  | DeleteItemFromDatabaseAction
  | CreateItemInDatabaseAction;

export const itemReducer = (state: ItemState = {}, action: ItemAction) => {
  switch (action.type) {
    case ITEM_ACTION.CREATE_ITEM_IN_DATABASE_ACTION: {
      const next: ItemState = { ...state };

      const { response: item } = action;

      next[item.restaurantId][item.id] = item;

      return next;
    }
    case ITEM_ACTION.UPDATE_ITEM_IN_DATABASE_ACTION: {
      const next: ItemState = { ...state };

      const { response: item } = action;

      next[item.restaurantId][item.id] = item;

      return next;
    }
    case ITEM_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION: {
      const next: ItemState = { ...state };

      const { isPublished, itemIds, restaurantId } = action.response;

      itemIds.forEach((itemId) => {
        next[restaurantId][itemId] = {
          ...(next[restaurantId][itemId] || {}),
          isPublished,
        };
      });

      return next;
    }
    case ITEM_ACTION.GET_ALL_ITEMS_FOR_RESTAUARNT_FROM_DATABASE_ACTION: {
      const { response: items, restaurantId } = action;

      const next: ItemState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      items.forEach((item) => {
        next[restaurantId][item.id] = item;
      });

      return next;
    }
    case ITEM_ACTION.DELETE_ITEM_FROM_DATABASE_ACTION: {
      const next: ItemState = { ...state };

      const { restaurantId, itemId } = action;

      delete next[restaurantId][itemId];

      return next;
    }
    default: {
      return state;
    }
  }
};
