import { LocationFragment } from "src/common/types/Location";

export const getAddressStringFromLocationEntity = (
  location: LocationFragment,
): string => {
  let string = "";

  string += `${location.line1}`;

  if (location.line2) {
    string += ` ${location.line2}`;
  }

  string += `, ${location.city}, ${location.state}`;

  return string;
};
