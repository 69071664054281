import { DayOfTheWeekHours } from "src/common/types/DayOfTheWeekHours";
import {
  HoursOfOperationFragment,
  HoursOfOperationFragmentWithMode,
  HoursOfOperationMode,
} from "src/common/types/HoursOfOperation";

export const NINE_AM_AS_DATE_OBJ = new Date(0, 0, 0, 9, 0, 0, 0);
export const NINE_PM_AS_DATE_OBJ = new Date(0, 0, 0, 21, 0, 0, 0);
export const NINE_AM_AS_ISO_STRING = NINE_AM_AS_DATE_OBJ.toISOString();
export const NINE_PM_AS_ISO_STRING = NINE_PM_AS_DATE_OBJ.toISOString();

export const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const areAllHoursNonOverlapping = (
  hours: HoursOfOperationFragment,
): boolean => {
  for (const day of daysOfWeek) {
    const dayCorrectType = day as keyof HoursOfOperationFragment;

    if (!hours[dayCorrectType]) {
      continue;
    }

    for (
      let i = 0;
      i < (hours[dayCorrectType] as DayOfTheWeekHours[]).length;
      i++
    ) {
      for (
        let j = i + 1;
        j < (hours[dayCorrectType] as DayOfTheWeekHours[]).length;
        j++
      ) {
        const thisDayOpen = new Date(
          (hours[dayCorrectType] as DayOfTheWeekHours[])[i].open,
        );
        const thisDayClose = new Date(
          (hours[dayCorrectType] as DayOfTheWeekHours[])[i].close,
        );

        const nextDayOpen = new Date(
          (hours[dayCorrectType] as DayOfTheWeekHours[])[j].open,
        );
        const nextDayClose = new Date(
          (hours[dayCorrectType] as DayOfTheWeekHours[])[j].close,
        );

        if (
          thisDayOpen.getHours() < nextDayClose.getHours() &&
          thisDayOpen.getHours() > nextDayOpen.getHours()
        ) {
          return false;
        }

        if (
          thisDayClose.getHours() < nextDayClose.getHours() &&
          thisDayClose.getHours() > nextDayOpen.getHours()
        ) {
          return false;
        }

        if (
          thisDayOpen.getHours() <= nextDayOpen.getHours() &&
          thisDayClose.getHours() > nextDayClose.getHours()
        ) {
          return false;
        }

        if (
          nextDayOpen.getHours() < thisDayOpen.getHours() &&
          nextDayClose.getHours() > thisDayClose.getHours()
        ) {
          return false;
        }

        if (
          thisDayOpen.getHours() >= nextDayOpen.getHours() &&
          thisDayOpen.getHours() < nextDayClose.getHours()
        ) {
          return false;
        }

        if (
          thisDayOpen.getHours() < nextDayOpen.getHours() &&
          thisDayClose.getHours() >= nextDayClose.getHours()
        ) {
          return false;
        }

        if (
          thisDayOpen.getHours() === nextDayOpen.getHours() &&
          thisDayClose.getHours() === nextDayClose.getHours()
        ) {
          return false;
        }

        if (
          thisDayOpen.getHours() === nextDayClose.getHours() &&
          thisDayOpen.getMinutes() < nextDayClose.getMinutes()
        ) {
          return false;
        }

        if (
          thisDayClose.getHours() === nextDayOpen.getHours() &&
          thisDayClose.getMinutes() > nextDayOpen.getMinutes()
        ) {
          return false;
        }
      }
    }
  }
  return true;
};

export const areAllCloseTimesAfterOpenTimes = (
  hours: HoursOfOperationFragment,
): boolean => {
  for (const day of daysOfWeek) {
    const dayCorrectType = day as keyof HoursOfOperationFragment;

    if (!hours[dayCorrectType]) {
      continue;
    }

    for (const time of hours[dayCorrectType] as DayOfTheWeekHours[]) {
      const openTime = new Date(time.open);
      const closeTime = new Date(time.close);

      if (closeTime.getHours() < openTime.getHours()) {
        return false;
      }

      if (
        closeTime.getHours() === openTime.getHours() &&
        closeTime.getMinutes() < openTime.getMinutes()
      ) {
        return false;
      }
    }
  }

  return true;
};

export const formatISOStringToHoursAndMinutes = (ISOString: string) => {
  const date = new Date(ISOString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours12}:${minutesString} ${amPm}`;
};

export const formatISOStringTo24HourTime = (ISOString: string) => {
  const date = new Date(ISOString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  return `${hoursString}:${minutesString}`;
};

export const format24HourTimeToISOString = (time: string) => {
  const hours = parseInt(time.split(":")[0]);
  const minutes = parseInt(time.split(":")[1]);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date.toISOString();
};

export const isNowWithinOpenHoursOfOperation = (
  hoursOfOperation: HoursOfOperationFragmentWithMode,
): boolean => {
  if (
    hoursOfOperation.modeSelected === HoursOfOperationMode.CLOSED_UNTIL_X ||
    hoursOfOperation.modeSelected === HoursOfOperationMode.MANUALLY_CLOSED
  ) {
    return false;
  }

  const nowDate = new Date();
  const nowDayIndex = nowDate.getDay();

  const nowDayString = daysOfWeek[
    nowDayIndex
  ] as keyof HoursOfOperationFragmentWithMode;

  if (
    hoursOfOperation[nowDayString] === null ||
    hoursOfOperation[nowDayString] === undefined
  ) {
    return false;
  }

  const thisDayHoursOfOperation = hoursOfOperation[
    nowDayString
  ] as DayOfTheWeekHours[];

  return isNowWithinGivenHours(thisDayHoursOfOperation, nowDate);
};

export const isNowWithinGivenHours = (
  dayOfTheWeekHours: DayOfTheWeekHours[],
  nowDate: Date,
) => {
  const nowHour = nowDate.getHours();
  const nowMinute = nowDate.getMinutes();

  if (!dayOfTheWeekHours) {
    return false;
  } else {
    for (const eachActiveTime of dayOfTheWeekHours) {
      const open = new Date(eachActiveTime.open);
      const close = new Date(eachActiveTime.close);

      const openHour = open.getHours();
      const openMinute = open.getMinutes();

      const closeHour = close.getHours();
      const closeMinute = close.getMinutes();

      if (nowHour > openHour && nowHour < closeHour) {
        return true;
      }

      if (nowHour === openHour && nowHour === closeHour) {
        if (nowMinute >= openMinute && nowMinute < closeMinute) {
          return true;
        }
      }

      if (
        nowHour === openHour &&
        nowHour !== closeHour &&
        nowMinute >= openMinute
      ) {
        return true;
      }

      if (
        nowHour === closeHour &&
        nowHour !== openHour &&
        nowMinute < closeMinute
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getNextOpenTime = (
  hoursOfOperation: HoursOfOperationFragmentWithMode,
): string => {
  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + 1);

  let nextDayOfWeek = nextDate.getDay();
  let nextDayString = daysOfWeek[
    nextDayOfWeek
  ] as keyof HoursOfOperationFragmentWithMode;
  let nextHours = hoursOfOperation[nextDayString] as DayOfTheWeekHours[] | null;

  while (!nextHours) {
    nextDate.setDate(nextDate.getDate() + 1);
    nextDayOfWeek = nextDate.getDay();
    nextDayString = daysOfWeek[
      nextDayOfWeek
    ] as keyof HoursOfOperationFragmentWithMode;
    nextHours = hoursOfOperation[nextDayString] as DayOfTheWeekHours[] | null;
  }

  const firstHourOpen = nextHours[0].open;

  nextDate.setHours(new Date(firstHourOpen).getHours());
  nextDate.setMinutes(new Date(firstHourOpen).getMinutes());
  nextDate.setSeconds(0);
  nextDate.setMilliseconds(0);

  return nextDate.toISOString();
};

export const formatISOStringToShortDateAndTime = (ISOString: string) => {
  const date = new Date(ISOString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  return `${month}/${day}/${year} @ ${hours12}:${minutesString} ${amPm}`;
};

export const formatISOStringToLongDateAndTime = (ISOString: string) => {
  const date = new Date(ISOString);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix}, ${year} @ ${hours12}:${minutesString} ${amPm}`;
};

export const formatISOStringToLongDate = (ISOString: string) => {
  const date = new Date(ISOString);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();

  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix}, ${year}`;
};

export const formatTimeToLongDateTime = (time: number) => {
  const date = new Date(time);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix} @ ${hours12}:${minutesString} ${amPm}`;
};

export const formatTimeToDate = (time: number) => {
  const date = new Date(time);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return `${month} ${day}${suffix}`;
};
