import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState, useCallback, useMemo } from "react";
import styles from "src/Router/styles.module.scss";
import {
  Financials,
  Home,
  HoursOfOperation,
  Login,
  Menu,
  Order,
  Orders,
  Settings,
  Catering,
  RestaurantDesign,
  CateringRequest,
  RestaurantContactUs,
  Item,
  SocialMediaLinks,
} from "src/pages";
import { Header, SkeletonLoading, SideMenu } from "src/components";
import {
  getCateringRoute,
  getCateringRequestRoute,
  getHomeRoute,
  getHoursOfOperationRoute,
  getLoginPath,
  getMenuRoute,
  getOrderRoute,
  getOrdersRoute,
  getFinancialsRoute,
  getSettingsRoute,
  getDesignRoute,
  getWildcardRoute,
  getRestaurantContactUsRoute,
  getItemRoute,
  getSocialMediaLinks,
} from "src/Router/routes";
import { getItemFromLocalStorage } from "src/common/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { initializeState } from "src/state/initializeState";
import { State } from "src/state/state";
import { logWebsiteVisitToAnalytics } from "src/common/analytics";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import { setSessionIdAction } from "src/state/session/actions";

export const Router = () => {
  const dispatch = useDispatch();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const [isLoading, setIsLoading] = useState(true);

  const initializeAuth = useCallback(async () => {
    const userId = getItemFromLocalStorage("USER_ID");
    const refreshToken = getItemFromLocalStorage("REFRESH_TOKEN");
    const sessionAction = await setSessionIdAction()(dispatch);

    if (userId && refreshToken) {
      const { restaurant } = await initializeState(userId, dispatch);
      logWebsiteVisitToAnalytics(userId);

      Sentry.setContext("user_info", {
        restaurantId: restaurant.id,
        restaurantName: restaurant.restaurantName,
        sessionId: sessionAction.sessionId,
      });
    }

    setIsLoading(false);
  }, [localStorage, dispatch]);

  const getAuthenticatedComponent = useCallback(
    (route: JSX.Element): JSX.Element => {
      return restaurant ? route : <Navigate replace to={getLoginPath()} />;
    },
    [restaurant],
  );

  const isRestaurantContactUsRoute = useMemo(() => {
    return window.location.pathname === getRestaurantContactUsRoute();
  }, [window.location.pathname]);

  useEffect(() => {
    initializeAuth();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.Router} data-testid="router-loading">
        <div className={styles.loading}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className={styles.Router}>
        {restaurant && !isRestaurantContactUsRoute && <Header />}
        <div className={styles.content}>
          {restaurant && !isRestaurantContactUsRoute && <SideMenu />}
          <ToastContainer />
          <Routes>
            <Route
              path={getLoginPath()}
              element={
                restaurant ? (
                  <Navigate replace to={getHomeRoute()} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path={getHomeRoute()}
              element={getAuthenticatedComponent(<Home />)}
            />
            <Route
              path={getMenuRoute()}
              element={getAuthenticatedComponent(<Menu />)}
            />
            <Route
              path={getOrdersRoute()}
              element={getAuthenticatedComponent(<Orders />)}
            />
            <Route
              path={getOrderRoute(":orderId")}
              element={getAuthenticatedComponent(<Order />)}
            />
            <Route
              path={getItemRoute(":itemId")}
              element={getAuthenticatedComponent(<Item />)}
            />
            <Route
              path={getFinancialsRoute()}
              element={getAuthenticatedComponent(<Financials />)}
            />
            <Route
              path={getCateringRoute()}
              element={getAuthenticatedComponent(<Catering />)}
            />
            <Route
              path={getCateringRequestRoute(":cateringRequestId")}
              element={getAuthenticatedComponent(<CateringRequest />)}
            />
            <Route
              path={getSettingsRoute()}
              element={getAuthenticatedComponent(<Settings />)}
            />
            <Route
              path={getHoursOfOperationRoute()}
              element={getAuthenticatedComponent(<HoursOfOperation />)}
            />
            <Route
              path={getDesignRoute()}
              element={getAuthenticatedComponent(<RestaurantDesign />)}
            />
            <Route
              path={getSocialMediaLinks()}
              element={getAuthenticatedComponent(<SocialMediaLinks />)}
            />
            <Route
              path={getRestaurantContactUsRoute()}
              element={<RestaurantContactUs />}
            />
            <Route
              path={getWildcardRoute()}
              element={
                <Navigate
                  replace
                  to={restaurant ? getHomeRoute() : getLoginPath()}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};
