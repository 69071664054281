import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import styles from "src/pages/Financials/MonthlyStatements/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { createMonthlyStatementExport } from "src/common/export";
import { downloadFileFromRemote } from "src/common/download";
import { logExportMonthlyStatementInAnalytics } from "src/common/analytics";
import { useSelector } from "react-redux";

export const MonthlyStatements = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const [indexLoading, setIndexLoading] = useState(-1);

  const allMonthsAndYearsSinceCreatedTillNow = useMemo(() => {
    const { createdAt } = restaurant;

    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();

    const monthsAndYearsSinceCreate: {
      monthString: string;
      monthNumber: number;
      year: number;
    }[] = [];
    let currentMonth = createdAtDate.getMonth();
    let currentYear = createdAtDate.getFullYear();

    while (
      currentYear < currentDate.getFullYear() ||
      (currentYear === currentDate.getFullYear() &&
        currentMonth <= currentDate.getMonth())
    ) {
      monthsAndYearsSinceCreate.push({
        monthString: new Date(currentYear, currentMonth).toLocaleString(
          "default",
          { month: "long" },
        ),
        monthNumber: currentMonth,
        year: currentYear,
      });

      currentMonth += 1;

      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
    }

    return monthsAndYearsSinceCreate.reverse();
  }, [restaurant]);

  const exportMonthlyStatement = useCallback(
    async (index: number) => {
      setIndexLoading(index);

      const toExport = allMonthsAndYearsSinceCreatedTillNow[index];

      const createdExport = await createMonthlyStatementExport(
        restaurant.id,
        toExport.monthNumber,
        toExport.year,
      );

      const fileName = `platter-${toExport.monthString}-${toExport.year}-statement.pdf`;

      await downloadFileFromRemote(createdExport.url, fileName);

      logExportMonthlyStatementInAnalytics(
        restaurant.id,
        toExport.monthString,
        toExport.year,
      );

      setIndexLoading(-1);
    },
    [restaurant, allMonthsAndYearsSinceCreatedTillNow],
  );

  return (
    <div className={styles.MonthlyStatements}>
      <div className={classNames(styles.headerRow, styles.row)}>
        <div className={styles.monthColumn}>
          <h3 className={styles.headerText}>Month</h3>
        </div>
        <div className={styles.downloadColumn}>
          <h3 className={styles.headerText}>Download</h3>
        </div>
      </div>
      {allMonthsAndYearsSinceCreatedTillNow.map((monthAndYear, index) => (
        <div
          key={index}
          className={classNames(styles.row)}
          onClick={async () => {
            if (indexLoading !== index) {
              await exportMonthlyStatement(index);
            }
          }}
        >
          <div className={styles.monthColumn}>
            <h3
              className={styles.rowText}
              data-testid={`financials-monthly-statement-${index}`}
            >
              {monthAndYear.monthString} {monthAndYear.year}
            </h3>
          </div>
          <div className={styles.downloadColumn}>
            {indexLoading === index ? (
              <div
                data-testid={`financials-monthly-statement-loading-${index}`}
              >
                <ReactLoading
                  type="spin"
                  color={palette.blue}
                  height={30}
                  width={30}
                />
              </div>
            ) : (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  className={styles.downloadIcon}
                  icon={faDownload}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
