// Utilities for interacting with AWS S3
import { getEnvVariable } from "src/config/getConfig";
import { S3 } from "aws-sdk";

export const getItemImageURLFromItemId = (itemId: string): string => {
  return `https://${getEnvVariable(
    "AWS_S3_BUCKET_NAME",
  )}.s3.amazonaws.com/item-images/${itemId}?${Date.now()}`;
};

export const getCategoryImageURLFromCategoryId = (
  categoryId: string,
): string => {
  return `https://${getEnvVariable(
    "AWS_S3_BUCKET_NAME",
  )}.s3.amazonaws.com/category-images/${categoryId}?${Date.now()}`;
};

export const uploadItemImageBase64ToS3 = async (
  itemImage: string,
  itemId: string,
): Promise<S3.ManagedUpload.SendData> => {
  const s3Bucket = new S3({
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    region: getEnvVariable("AWS_REGION"),
  });

  const blob = await (await fetch(itemImage)).blob();

  const params: S3.PutObjectRequest = {
    Bucket: getEnvVariable("AWS_S3_BUCKET_NAME"),
    Key: `item-images/${itemId}`,
    Body: blob,
    ContentEncoding: "base64",
    ContentType: "image/jpeg",
  };

  return new Promise((resolve, reject) => {
    s3Bucket.upload(params, (err: Error, data: S3.ManagedUpload.SendData) => {
      if (err) {
        reject(err);
      }
      resolve(data);
    });
  });
};

export const deleteItemImageFromS3 = async (itemId: string): Promise<void> => {
  const s3Bucket = new S3({
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    region: getEnvVariable("AWS_REGION"),
  });

  const params: S3.DeleteObjectRequest = {
    Bucket: getEnvVariable("AWS_S3_BUCKET_NAME"),
    Key: `item-images/${itemId}`,
  };

  return new Promise((resolve, reject) => {
    s3Bucket.deleteObject(params, (err: Error, _: S3.DeleteObjectOutput) => {
      if (err) {
        reject(err);
      }
      resolve();
    });
  });
};
