import { post } from "src/api/server";

export interface PayoutsResponse {
  has_more: boolean;
  object: string;
  url: string;
  data: {
    amount: number;
    application_fee: number;
    application_fee_amount: number;
    arrival_date: number;
    automatic: boolean;
    balance_transaction: string;
    created: number;
    currency: string;
    description: string;
    destination: string;
    failure_balance_transaction: string;
    failure_code: string;
    failure_message: string;
    id: string;
    livemode: boolean;
    method: string;
    object: string;
    reconciliation_status: string;
    source_type: string;
    status: string;
    type: string;
  }[];
}

export const getUnpaidBalanceForRestaurant = async (
  restaurantId: string,
): Promise<number> => {
  const response = (await post({
    service: "thanos",
    endpoint: "/restaurant/get_unpaid_balance",
    params: {
      restaurantId,
    },
  })) as { unpaidBalance: number };

  return response.unpaidBalance;
};

export const listPayoutsForRestaurant = async (
  restaurantId: string,
  startingAfter: string | undefined,
  endingBefore: string | undefined,
) => {
  const response = await post({
    service: "thanos",
    endpoint: "/restaurant/list_payouts",
    params: {
      restaurantId,
      startingAfter,
      endingBefore,
    },
  });

  return response as PayoutsResponse;
};
