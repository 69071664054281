// Component for circular buttons that can be selected
import classNames from "classnames";
import styles from "src/components/SelectableCircle/styles.module.scss";
import palette from "src/common/styles/palette.module.scss";

interface ComponentProps {
  className?: string;
  testID?: string;
  scale?: number;
  isSelected: boolean;
  onClick: () => void;
}

export const SelectableCircle = ({
  className,
  testID,
  scale = 1,
  isSelected,
  onClick,
}: ComponentProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.SelectableCircle, className)}
      style={{
        minWidth: `${30 * scale}px`,
        minHeight: `${30 * scale}px`,
        borderRadius: `${15 * scale}px`,
        borderStyle: "solid",
        borderColor: palette.blue,
        borderWidth: `${1 * scale}px`,
      }}
      data-testid={testID}
    >
      <div
        className={classNames(isSelected && styles.selected)}
        style={
          isSelected
            ? {
                minWidth: `${24 * scale}px`,
                minHeight: `${24 * scale}px`,
                borderRadius: `${12 * scale}px`,
              }
            : undefined
        }
      />
    </div>
  );
};
