import classNames from "classnames";
import { ORDER_STATUS } from "src/common/types/Order";
import styles from "src/components/StatusIndicator/styles.module.scss";

interface ComponentProps {
  className?: string;
  textTestId?: string;
  status: ORDER_STATUS;
}

export const StatusIndicator = ({
  className,
  status,
  textTestId,
}: ComponentProps) => {
  const statusIndicatorText = {
    [ORDER_STATUS.NEW_ORDER]: "Needs Action",
    [ORDER_STATUS.IN_PROGRESS]: "In Progress",
    [ORDER_STATUS.READY_FOR_PICKUP]: "Ready",
    [ORDER_STATUS.EN_ROUTE_TO_CUSTOMER]: "Delivering",
    [ORDER_STATUS.COMPLETE]: "Completed",
    [ORDER_STATUS.CANCELLED]: "Cancelled",
  };

  return (
    <div
      className={classNames(
        styles.statusIndicator,
        {
          [styles.redIndicator]: status === ORDER_STATUS.CANCELLED,
          [styles.greenIndicator]: status === ORDER_STATUS.COMPLETE,
        },
        className,
      )}
    >
      <h4 className={styles.statusIndicatorText} data-testid={textTestId}>
        {statusIndicatorText[status]}
      </h4>
    </div>
  );
};
