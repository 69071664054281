import { useSelector } from "react-redux";
import { CateringRequestFragment } from "src/state/catering/types";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import styles from "src/pages/CateringRequest/AdditionalNotes/styles.module.scss";

interface ComponentProps {
  cateringRequestId: string;
}

export const AdditionalNotes = ({ cateringRequestId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const cateringRequest = useSelector(
    (state: State) =>
      restaurant &&
      state.catering[restaurant.id] &&
      state.catering[restaurant.id][cateringRequestId],
  ) as CateringRequestFragment;

  return (
    <div
      className={styles.AdditionalNotes}
      data-testid="catering-request-screen-additional-notes-container"
    >
      <h3 className={styles.title}>Additional Request Notes</h3>
      <p
        className={styles.additionalNotesText}
        data-testid="catering-request-screen-additional-notes"
      >
        {cateringRequest.notes}
      </p>
    </div>
  );
};
