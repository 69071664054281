import { useDispatch, useSelector } from "react-redux";
import { BasicModal, Button, SkeletonLoading, TextInput } from "src/components";
import styles from "src/pages/Item/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { showToast } from "src/common/toast";
import {
  logDeleteItemInAnalytics,
  logUpdateItemInAnalytics,
  logUpdateItemsPublishedStatusToAnalytics,
} from "src/common/analytics";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { PhotoChooser } from "src/components/PhotoChooser/PhotoChooser";
import {
  deleteItemFromDatabaseAction,
  getAllItemsForRestaurantFromDatabaseAction,
  updateItemInDatabaseAction,
  updatePublishedStatusForMultipleItemsInDatabaseAction,
} from "src/state/item/actions";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-awesome-modal";
import { getMenuRoute } from "src/Router/routes";
import { getDealsForRestaurantAction } from "src/state/deal/actions";

type ItemUrlParams = "itemId";

export const Item = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { itemId } = useParams<ItemUrlParams>() as { itemId: string };

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  ) as RestaurantFragment;
  const items = useSelector((state: State) => state.items[restaurant.id]);
  const deals = useSelector((state: State) => state.deals[restaurant.id]);
  const item = useSelector(
    (state: State) =>
      state.items[restaurant.id] && state.items[restaurant.id][itemId],
  );

  const [isLoadingItem, setIsLoadingItem] = useState(true);
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const [photoSelected, setPhotoSelected] = useState<string | undefined>(
    undefined,
  );
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const isThereChangesToItemDetails = useMemo(() => {
    if (!item) {
      return false;
    }

    return (
      item.name !== itemName ||
      item.description !== itemDescription ||
      `${item.price}` !== `${itemPrice}` ||
      photoSelected !== item.imageURL
    );
  }, [item, itemName, itemDescription, itemPrice, photoSelected]);

  const areInputsValid = useCallback((): boolean => {
    if (itemName.trim() === "") {
      setError("Please enter an item name.");
      return false;
    }
    if (itemDescription.trim() === "") {
      setError("Please enter an item description.");
      return false;
    }

    if (item.isCateringItem) {
      if (
        itemPrice.trim() !== "" &&
        (isNaN(parseFloat(itemPrice)) || parseFloat(itemPrice) <= 0)
      ) {
        setError("Please enter a valid item price.");
        return false;
      }
    } else {
      if (
        itemPrice.trim() === "" ||
        isNaN(parseFloat(itemPrice)) ||
        parseFloat(itemPrice) <= 0
      ) {
        setError("Please enter a valid item price.");
        return false;
      }
    }

    return true;
  }, [item, photoSelected, itemName, itemDescription, itemPrice]);

  const updateItemInDatabase = useCallback(async () => {
    if (!areInputsValid()) {
      return;
    }

    setIsUpdatingItem(true);

    await updateItemInDatabaseAction(
      item.id,
      itemName,
      itemDescription,
      item.hasImage,
      item.isCateringItem,
      itemPrice.trim() === "" ? undefined : parseFloat(itemPrice),
      photoSelected,
    )(dispatch);

    showToast("Item saved successfully");

    logUpdateItemInAnalytics(
      restaurant.id,
      item.id,
      itemName !== item.name,
      itemDescription !== item.description,
      itemPrice.trim() !== "" && parseFloat(itemPrice) !== item.price,
      item.hasImage !== (photoSelected !== undefined),
    );

    setIsUpdatingItem(false);
  }, [
    item,
    photoSelected,
    itemName,
    itemDescription,
    itemPrice,
    restaurant.id,
    dispatch,
  ]);

  const loadItemsIfNeeded = useCallback(async () => {
    if (!items || !deals) {
      setIsLoadingItem(true);
      await Promise.all([
        getAllItemsForRestaurantFromDatabaseAction(restaurant.id)(dispatch),
        getDealsForRestaurantAction(restaurant.id)(dispatch),
      ]);
      await getAllItemsForRestaurantFromDatabaseAction(restaurant.id)(dispatch);
      setIsLoadingItem(false);
    } else {
      setIsLoadingItem(false);
    }
  }, [items, restaurant, dispatch]);

  useEffect(() => {
    loadItemsIfNeeded();
  }, [itemId]);

  useEffect(() => {
    if (item) {
      if (item.hasImage) {
        setPhotoSelected(item.imageURL);
      } else {
        setPhotoSelected(undefined);
      }

      setItemName(item.name);
      setItemDescription(item.description);
      setItemPrice(`${item.price}`);
    }
  }, [item]);

  if (isLoadingItem || !item) {
    return (
      <div className={styles.Item} data-data-testid="item-loading-container">
        <div className={styles.loadingContainer}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.ItemContainer}>
      <div className={styles.Item} data-testid="item-container">
        <div className={styles.headerRow}>
          <h2 className={styles.pageTitle}>{item.name}</h2>
          <div
            data-testid="item-options-button"
            className={styles.menuButton}
            onClick={() => setIsOptionsModalVisible(true)}
          >
            <FontAwesomeIcon
              className={styles.icon}
              icon={faEllipsisVertical}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.statusPill,
            item.isPublished ? styles.published : styles.unpublished,
          )}
        >
          <h4 className={styles.statusPillText}>
            {item.isPublished ? "Published" : "Unpublished"}
          </h4>
        </div>
        <PhotoChooser
          photo={photoSelected}
          onPhotoChange={(photo) => setPhotoSelected(photo)}
        />
      </div>
      <TextInput
        label="Name"
        className={styles.nameInput}
        data-testid="item-name-input"
        value={itemName}
        onChangeText={(newText) => setItemName(newText)}
        placeholder="Enter item name..."
      />
      <TextInput
        label="Description"
        className={styles.descriptionInput}
        data-testid="item-description-input"
        value={itemDescription}
        onChangeText={(newText) => setItemDescription(newText)}
        placeholder="Enter item description..."
        multiline={true}
      />
      <TextInput
        label="Price"
        className={styles.priceInput}
        data-testid="item-price-input"
        value={itemPrice}
        onChangeText={(newText) => setItemPrice(newText)}
        placeholder="Enter item price..."
        textPrefix="$"
      />
      {isThereChangesToItemDetails && (
        <div
          data-testid="item-save-button"
          className={styles.bottomSaveButton}
          onClick={() => {
            if (!isUpdatingItem) {
              updateItemInDatabase();
            }
          }}
        >
          {isUpdatingItem ? (
            <ReactLoading
              data-testid={"item-loading-indicator"}
              type="spin"
              color={palette.white}
              height={30}
              width={30}
            />
          ) : (
            <p className={styles.saveText}>Save Changes</p>
          )}
        </div>
      )}
      <BasicModal
        testId="item-error-modal"
        isModalVisible={error !== undefined}
        title="Error"
        message={error}
        onClickOutside={() => setError(undefined)}
        onConfirm={() => setError(undefined)}
        confirmText="Ok"
      />
      <Modal
        visible={isOptionsModalVisible}
        width="300"
        height={"250"}
        effect="fadeInUp"
        onClickAway={() => setIsOptionsModalVisible(false)}
      >
        <div
          className={styles.modal}
          data-testid={`item-options-modal-container`}
        >
          <h3 className={styles.modalTitle}>Options</h3>
          {isUpdatingItem ? (
            <div className={styles.modalLoadingContainer}>
              <ReactLoading
                type="spin"
                color={palette.blue}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <>
              <Button
                testId="item-update-published-status-button"
                className={styles.modalButton}
                secondaryError={item.isPublished}
                secondary={!item.isPublished}
                text={item.isPublished ? "Unpublish Item" : "Publish Item"}
                onClick={async () => {
                  if (!isUpdatingItem) {
                    const newPublishedStatus = !item.isPublished;

                    setIsUpdatingItem(true);
                    await dispatch(
                      updatePublishedStatusForMultipleItemsInDatabaseAction(
                        [item.id],
                        restaurant.id,
                        newPublishedStatus,
                      ),
                    );
                    setIsUpdatingItem(false);
                    setIsOptionsModalVisible(false);
                    showToast("Item updated successfully");
                    logUpdateItemsPublishedStatusToAnalytics(
                      restaurant.id,
                      [item.id],
                      newPublishedStatus,
                    );
                  }
                }}
              />
              <Button
                testId="item-delete-button"
                className={styles.modalButton}
                secondaryError={true}
                text={"Delete Item"}
                onClick={async () => {
                  if (!isUpdatingItem) {
                    setIsUpdatingItem(true);
                    await dispatch(
                      deleteItemFromDatabaseAction(restaurant.id, item.id),
                    );
                    setIsUpdatingItem(false);
                    setIsOptionsModalVisible(false);
                    showToast("Item deleted successfully");
                    logDeleteItemInAnalytics(restaurant.id, item.id);
                    navigate(getMenuRoute("items"));
                  }
                }}
              />
              <Button
                className={styles.modalButton}
                text="Close"
                onClick={() => setIsOptionsModalVisible(false)}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
