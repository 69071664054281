import { toast } from "react-toastify";
import palette from "src/common/styles/palette.module.scss";

export const showToast = (
  message: string,
  backgroundColor: string = palette.blue,
  textColor: string = palette.white,
) => {
  toast.success(message, {
    position: "top-right",
    hideProgressBar: true,
    style: {
      backgroundColor,
      color: textColor,
    },
    autoClose: 3000,
    closeButton: false,
    closeOnClick: true,
  });
};
