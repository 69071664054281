// Functions & types for interacting with the locally persisted async storage
export type StorageKey = "USER_ID" | "REFRESH_TOKEN";

export const putItemIntoLocalStorage = (
  key: StorageKey,
  value: string,
): void => {
  localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key: StorageKey): string => {
  const value = localStorage.getItem(key) as string;

  return value;
};

export const removeItemFromLocalStorage = (key: StorageKey): void => {
  localStorage.removeItem(key);
};
