export const formatNumber = (num: number) => {
  return num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const roundToNearestCent = (price: number) => {
  return Math.round(price * 100) / 100;
};

export const isValidPositiveNumber = (number: string) => {
  if (
    number.trim() === "" ||
    isNaN(parseFloat(number)) ||
    parseFloat(number) <= 0
  ) {
    return false;
  }

  return true;
};
