import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ORDER_STATUS,
  ORDER_TYPE,
  OrderFragment,
  OrderItemFragment,
} from "src/common/types/Order";
import { Button, SkeletonLoading, StatusIndicator } from "src/components";
import styles from "src/pages/Order/styles.module.scss";
import { getDealsForRestaurantAction } from "src/state/deal/actions";
import {
  getSpecificOrderAction,
  refundOrderItemAction,
  updateOrderStatusAction,
} from "src/state/order/actions";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { useMediaQuery } from "react-responsive";
import ReactLoading from "react-loading";
import { formatISOStringToLongDateAndTime } from "src/common/date";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { ItemsList } from "src/pages/Order/ItemsList/ItemsList";
import { BasicDetails } from "src/pages/Order/BasicDetails/BasicDetails";
import { Pricing } from "src/pages/Order/Pricing/Pricing";
import { CustomerDetails } from "src/pages/Order/CustomerDetails/CustomerDetails";
import { AdditionalNotes } from "src/pages/Order/AdditionalNotes/AdditionalNotes";
import Modal from "react-awesome-modal";
import palette from "src/common/styles/palette.module.scss";
import { showToast } from "src/common/toast";
import {
  logCancelOrderInAnalytics,
  logCompleteOrderInAnalytics,
  logRefundOrderItemToAnalytics,
  logUpdateOrderStatusInAnalytics,
} from "src/common/analytics";
import { SelectableCircle } from "src/components/SelectableCircle/SelectableCircle";

type OrderUrlParams = "orderId";

export const Order = () => {
  const dispatch = useDispatch();
  const isLargeDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const { orderId } = useParams<OrderUrlParams>() as { orderId: string };

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      (state.orders[restaurant.id][orderId] as OrderFragment | undefined),
  );
  const deals = useSelector(
    (state: State) =>
      restaurant && state.deals[restaurant.id] && state.deals[restaurant.id],
  );

  const [isLoading, setIsLoading] = useState(order && deals ? false : true);
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
  const [
    isCancellationReasonsModalVisible,
    setIsCancellationReasonsModalVisible,
  ] = useState(false);
  const [
    isRefundSpecificItemModalVisible,
    setIsRefundSpecificItemModalVisible,
  ] = useState(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [selectedOrderItemIdToRefund, setSelectedOrderItemIdToRefund] =
    useState<string | undefined>(undefined);

  const shouldShowUpdateButton = useMemo(() => {
    if (!order) {
      return false;
    }

    const { status, orderType } = order;

    if (status === ORDER_STATUS.COMPLETE || status === ORDER_STATUS.CANCELLED) {
      return false;
    }

    if (orderType === ORDER_TYPE.PICKUP) {
      return true;
    }

    if (
      orderType === ORDER_TYPE.DELIVERY &&
      status === ORDER_STATUS.NEW_ORDER
    ) {
      return true;
    }

    return false;
  }, [order]);

  const shouldShowCancelButton = useMemo(() => {
    if (!order) {
      return false;
    }

    if (order.status === ORDER_STATUS.CANCELLED) {
      return false;
    }

    if (order.orderType === ORDER_TYPE.PICKUP) {
      return true;
    }

    if (
      order.orderType === ORDER_TYPE.DELIVERY &&
      order.status === ORDER_STATUS.NEW_ORDER
    ) {
      return true;
    }

    return false;
  }, [order]);

  const updateStatusText = useMemo(() => {
    if (!order) {
      return "";
    }

    switch (order.status) {
      case ORDER_STATUS.NEW_ORDER:
        return "Accept Order";
      case ORDER_STATUS.IN_PROGRESS:
        return "Mark as Ready";
      default:
        return "Complete Order";
    }
  }, [order]);

  const updateOrderStatus = useCallback(async () => {
    if (!order) {
      return;
    }

    setIsUpdatingOrder(true);

    if (order.status === ORDER_STATUS.NEW_ORDER) {
      await updateOrderStatusAction(
        order.restaurantId,
        order.id,
        ORDER_STATUS.IN_PROGRESS,
      )(dispatch);

      showToast("Order updated to in progress.");

      logUpdateOrderStatusInAnalytics(
        order.restaurantId,
        order.id,
        ORDER_STATUS.IN_PROGRESS,
      );
    } else if (order.status === ORDER_STATUS.IN_PROGRESS) {
      await updateOrderStatusAction(
        order.restaurantId,
        order.id,
        ORDER_STATUS.READY_FOR_PICKUP,
      )(dispatch);

      showToast("Order updated to ready for pickup.");

      logUpdateOrderStatusInAnalytics(
        order.restaurantId,
        order.id,
        ORDER_STATUS.READY_FOR_PICKUP,
      );
    } else {
      await updateOrderStatusAction(
        order.restaurantId,
        order.id,
        ORDER_STATUS.COMPLETE,
      )(dispatch);

      showToast("Order has been completed.");

      logCompleteOrderInAnalytics(order.restaurantId, order.id);
    }

    setIsUpdatingOrder(false);
  }, [order, dispatch]);

  const cancelOrder = useCallback(
    async (cancellationReason: string) => {
      if (!order) {
        return;
      }

      setIsUpdatingOrder(true);

      await updateOrderStatusAction(
        order.restaurantId,
        order.id,
        ORDER_STATUS.CANCELLED,
        cancellationReason,
      )(dispatch);

      showToast("Order cancelled successfully.");

      logCancelOrderInAnalytics(
        order.restaurantId,
        order.totalPriceCharged,
        cancellationReason || "Other",
      );

      setIsUpdatingOrder(false);
      setIsCancellationReasonsModalVisible(false);
    },
    [order, dispatch],
  );

  const refundOrderItem = useCallback(async () => {
    if (restaurant && selectedOrderItemIdToRefund && order) {
      setIsUpdatingOrder(true);
      await refundOrderItemAction(
        restaurant.id,
        order.locationId,
        order.id,
        selectedOrderItemIdToRefund,
      )(dispatch);

      const orderItem = order.orderItems.find(
        (item) => item.id === selectedOrderItemIdToRefund,
      ) as OrderItemFragment;

      logRefundOrderItemToAnalytics(
        restaurant.id,
        order.id,
        selectedOrderItemIdToRefund,
        orderItem.itemId,
        orderItem.itemName,
      );
      setIsRefundSpecificItemModalVisible(false);
      setIsUpdatingOrder(false);
      showToast("Order item refunded successfully.");
    }
  }, [restaurant, selectedOrderItemIdToRefund, order, dispatch]);

  const fetchOrderIfNeeded = useCallback(async () => {
    if (!order) {
      setIsLoading(true);
      await getSpecificOrderAction(restaurant.id, orderId)(dispatch);
    }

    if (!deals) {
      setIsLoading(true);
      await getDealsForRestaurantAction(restaurant.id)(dispatch);
    }

    setIsLoading(false);
  }, [orderId, dispatch]);

  useEffect(() => {
    fetchOrderIfNeeded();
  }, []);

  if (isLoading || !order) {
    return (
      <div className={styles.Order} data-data-testid="order-loading-container">
        <div className={styles.loadingContainer}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Order} data-data-testid="order-container">
      <div className={styles.headerRow}>
        <div className={styles.headerLeft}>
          <div className={styles.orderDetails}>
            <h2
              className={styles.pageTitle}
            >{`Order #${order.orderNumber}`}</h2>
            <h4 className={styles.pageSubtitle}>
              {formatISOStringToLongDateAndTime(order.createdAt)}
            </h4>
          </div>
          <StatusIndicator
            textTestId="order-screen-status-text"
            status={order.status}
          />
        </div>
        {shouldShowCancelButton || shouldShowUpdateButton ? (
          <div
            data-testid="order-options-button"
            className={styles.menuButton}
            onClick={() => setIsOptionsModalVisible(true)}
          >
            <FontAwesomeIcon
              className={styles.icon}
              icon={faEllipsisVertical}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      {isLargeDesktop ? (
        <div className={styles.contentContainer}>
          <div className={styles.desktopLeft}>
            <ItemsList orderId={orderId} />
            <Pricing orderId={orderId} />
          </div>
          <div className={styles.desktopRight}>
            <BasicDetails orderId={orderId} />
            <CustomerDetails orderId={orderId} />
            {order.additionalNotes !== "" && (
              <AdditionalNotes orderId={orderId} />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <BasicDetails orderId={orderId} />
          <CustomerDetails orderId={orderId} />
          {order.additionalNotes !== "" && (
            <AdditionalNotes orderId={orderId} />
          )}
          <ItemsList orderId={orderId} />
          <Pricing orderId={orderId} />
        </div>
      )}
      <Modal
        visible={isOptionsModalVisible}
        width="300"
        height={"250"}
        effect="fadeInUp"
        onClickAway={() => setIsOptionsModalVisible(false)}
      >
        <div
          className={styles.modal}
          data-testid={`order-options-modal-container`}
        >
          <h3 className={styles.modalTitle}>Options</h3>
          {isUpdatingOrder ? (
            <div className={styles.modalLoadingContainer}>
              <ReactLoading
                type="spin"
                color={palette.blue}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <>
              {shouldShowUpdateButton && (
                <Button
                  testId="order-screen-update-button-container"
                  textTestId="order-screen-update-button-text"
                  className={styles.modalButton}
                  secondary={true}
                  text={updateStatusText}
                  onClick={async () => {
                    await updateOrderStatus();
                    setIsOptionsModalVisible(false);
                  }}
                />
              )}
              {shouldShowCancelButton && (
                <Button
                  testId="order-screen-cancel-button"
                  className={styles.modalButton}
                  secondaryError={true}
                  text={"Cancel & Refund"}
                  onClick={() => {
                    setIsOptionsModalVisible(false);
                    setTimeout(() => setIsRefundModalVisible(true), 300);
                  }}
                />
              )}
              <Button
                className={styles.modalButton}
                text="Close"
                onClick={() => setIsOptionsModalVisible(false)}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        visible={isRefundModalVisible}
        width="300"
        height={"250"}
        effect="fadeInUp"
        onClickAway={() => setIsRefundModalVisible(false)}
      >
        <div
          className={styles.modal}
          data-testid={`order-refund-modal-container`}
        >
          <h3 className={styles.modalTitle}>Refund Order</h3>
          <Button
            testId="order-refund-entire-order-button"
            className={styles.modalButton}
            secondary={true}
            text={"Cancel & Refund Entire Order"}
            onClick={async () => {
              setIsRefundModalVisible(false);
              setTimeout(() => setIsCancellationReasonsModalVisible(true), 300);
            }}
          />
          <Button
            testId="order-refund-specific-item-button"
            className={styles.modalButton}
            secondary={true}
            text={"Refund Specific Order Item"}
            onClick={async () => {
              setIsRefundModalVisible(false);
              setTimeout(() => setIsRefundSpecificItemModalVisible(true), 300);
            }}
          />
          <Button
            className={styles.modalButton}
            text="Don't Refund"
            onClick={() => setIsRefundModalVisible(false)}
          />
        </div>
      </Modal>
      <Modal
        visible={isCancellationReasonsModalVisible}
        width="300"
        height={"400"}
        effect="fadeInUp"
        onClickAway={() => setIsCancellationReasonsModalVisible(false)}
      >
        <div
          className={styles.modal}
          data-testid="order-refund-entire-order-modal-container"
        >
          <h3 className={styles.modalTitle}>Cancel Order</h3>
          {isUpdatingOrder ? (
            <div className={styles.modalLoadingContainer}>
              <ReactLoading
                type="spin"
                color={palette.blue}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <>
              <Button
                className={styles.modalButton}
                data-testid="order-screen-cancel-reason-1"
                secondary={true}
                text="Out of Stock"
                onClick={async () => {
                  await cancelOrder("Item(s) are out of stock");
                }}
              />
              <Button
                className={styles.modalButton}
                data-testid="order-screen-cancel-reason-2"
                secondary={true}
                text="Not Accepting Online Orders Right Now"
                onClick={async () => {
                  await cancelOrder("Not accepting online orders at this time");
                }}
              />
              <Button
                className={styles.modalButton}
                data-testid="order-screen-cancel-reason-3"
                secondary={true}
                text="Restaurant is Closed"
                onClick={async () => {
                  await cancelOrder("Restaurant is closed");
                }}
              />
              <Button
                className={styles.modalButton}
                data-testid="order-screen-cancel-reason-4"
                secondary={true}
                text="Other"
                onClick={async () => {
                  await cancelOrder("");
                }}
              />
              <Button
                className={styles.modalButton}
                text="Don't Cancel"
                onClick={() => setIsCancellationReasonsModalVisible(false)}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        visible={isRefundSpecificItemModalVisible}
        width="300"
        height={"350"}
        effect="fadeInUp"
        onClickAway={() => setIsRefundSpecificItemModalVisible(false)}
      >
        <div
          className={styles.modal}
          data-testid={`order-refund-specific-item-modal-container`}
        >
          <h3 className={styles.refundOrderItemModalTitle}>
            Choose Item to Refund
          </h3>
          <div className={styles.refundOrderItems}>
            {order.orderItems
              .filter((item) => !item.isRefunded)
              .map((orderItem) => (
                <div
                  key={orderItem.id}
                  data-testid={`choose-item-to-refund-item-${orderItem.id}`}
                  className={styles.refundOrderItemContainer}
                  onClick={() => {
                    if (selectedOrderItemIdToRefund === orderItem.id) {
                      setSelectedOrderItemIdToRefund(undefined);
                    } else {
                      setSelectedOrderItemIdToRefund(orderItem.id);
                    }
                  }}
                >
                  <div className={styles.refundOrderItemDetails}>
                    <p className={styles.refundOrderItemName}>
                      <span
                        className={styles.refundOrderItemQuantity}
                      >{`${orderItem.quantity}x`}</span>
                      {` ${orderItem.itemName}`}
                    </p>
                    <p className={styles.refundOrderItemPrice}>
                      {`$${orderItem.priceAfterDealAfterOptions.toFixed(2)}`}
                    </p>
                  </div>
                  <SelectableCircle
                    className={styles.refundOrderItemCircle}
                    scale={0.75}
                    isSelected={selectedOrderItemIdToRefund === orderItem.id}
                    onClick={() => {
                      if (selectedOrderItemIdToRefund === orderItem.id) {
                        setSelectedOrderItemIdToRefund(undefined);
                      } else {
                        setSelectedOrderItemIdToRefund(orderItem.id);
                      }
                    }}
                  />
                </div>
              ))}
          </div>
          <div className={styles.refundOrderItemButtonsContainer}>
            {isUpdatingOrder ? (
              <div className={styles.refundOrderItemLoading}>
                <ReactLoading
                  type="spin"
                  color={palette.blue}
                  height={30}
                  width={30}
                />
              </div>
            ) : (
              <>
                <Button
                  className={styles.refundOrderItemButton}
                  textClassName={styles.refundOrderItemButtonText}
                  secondary={true}
                  text={"Don't Refund"}
                  onClick={async () => {
                    setIsRefundSpecificItemModalVisible(false);
                  }}
                />
                <Button
                  testId="order-refund-specific-item-confirm-button"
                  className={styles.refundOrderItemButton}
                  textClassName={styles.refundOrderItemButtonText}
                  text="Refund"
                  onClick={refundOrderItem}
                  disabled={!selectedOrderItemIdToRefund}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
