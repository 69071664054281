import { useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "src/pages/Order/Pricing/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";

interface ComponentProps {
  orderId: string;
}

export const Pricing = ({ orderId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      state.orders[restaurant.id][orderId],
  );

  const discountValue = useMemo(() => {
    if (order.restaurantWideDiscountId) {
      const {
        priceAfterRestaurantWideDiscount,
        pointsUsedInDollars,
        subtotal,
      } = order;

      const discountValue =
        subtotal - pointsUsedInDollars - priceAfterRestaurantWideDiscount;

      return discountValue;
    }
  }, [order]);

  const promoCodeValue = useMemo(() => {
    if (order.promoCodeId) {
      const { priceAfterPromoCode, priceAfterRestaurantWideDiscount } = order;

      const promoValue = priceAfterRestaurantWideDiscount - priceAfterPromoCode;

      return promoValue;
    }
  }, [order]);

  const orderTotal = useMemo(() => {
    let total = order.totalPriceCharged;

    total -= order.serviceFee;
    total -= order.paymentProcessingFee;

    if (order.delivery) {
      total -= order.delivery.deliveryFee;
      total -= order.delivery.driverTipInDollars;
      total -= order.delivery.paymentProcessingFee;
    }

    if (
      order.promoCode &&
      order.promoCode.isPlatterSponsored &&
      promoCodeValue
    ) {
      total += promoCodeValue;
    }

    return total;
  }, [order, promoCodeValue]);

  return (
    <div className={styles.Pricing}>
      <h3 className={styles.title}>Receipt</h3>
      <div className={styles.row}>
        <p className={styles.text}>Subtotal</p>
        <p
          className={styles.text}
          data-testid="order-screen-subtotal"
        >{`$${order.subtotal.toFixed(2)}`}</p>
      </div>
      {order.pointsUsedInDollars > 0 && (
        <div className={styles.row}>
          <p className={styles.text}>Points Used</p>
          <p
            className={styles.text}
            data-testid="order-screen-points-used"
          >{`-$${order.pointsUsedInDollars.toFixed(2)}`}</p>
        </div>
      )}
      {discountValue && (
        <div className={styles.row}>
          <p className={styles.text}>Discount</p>
          <p
            className={styles.text}
            data-testid="order-screen-discount"
          >{`-$${discountValue.toFixed(2)}`}</p>
        </div>
      )}
      {promoCodeValue && promoCodeValue > 0 ? (
        <div className={styles.row}>
          <p className={styles.text}>Promo Code</p>
          <p
            className={styles.text}
            data-testid="order-screen-promoe"
          >{`-$${promoCodeValue.toFixed(2)}`}</p>
        </div>
      ) : null}
      {order.promoCode &&
      order.promoCode.isPlatterSponsored &&
      promoCodeValue &&
      promoCodeValue > 0 ? (
        <div className={styles.row}>
          <p className={styles.text}>Promo Code Paid by Platter</p>
          <p className={styles.text}>{`$${promoCodeValue.toFixed(2)}`}</p>
        </div>
      ) : null}
      <div className={styles.row}>
        <p className={styles.text}>Taxes</p>
        <p
          className={styles.text}
          data-testid="order-screen-sales-tax"
        >{`$${order.salesTax.toFixed(2)}`}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.text}>Tips</p>
        <p
          className={styles.text}
          data-testid="order-screen-tips"
        >{`$${order.tipAmount.toFixed(2)}`}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.text}>Payment Processing Fee</p>
        <p
          className={styles.text}
          data-testid="order-screen-payment-processing-fee"
        >{`-$${order.paymentProcessingFee.toFixed(2)}`}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.boldText}>Total</p>
        <p
          className={styles.boldText}
          data-testid="order-screen-order-total"
        >{`$${orderTotal.toFixed(2)}`}</p>
      </div>
    </div>
  );
};
