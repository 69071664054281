import { useSelector } from "react-redux";
import styles from "src/pages/Order/AdditionalNotes/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";

interface ComponentProps {
  orderId: string;
}

export const AdditionalNotes = ({ orderId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      state.orders[restaurant.id][orderId],
  );

  return (
    <div className={styles.AdditionalNotes}>
      <h3 className={styles.title}>Notes From the Customer</h3>
      <p
        className={styles.additionalNotesText}
        data-testid="order-screen-additional-notes"
      >
        {order.additionalNotes}
      </p>
    </div>
  );
};
