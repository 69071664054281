import styles from "src/pages/Home/ExportModal/styles.module.scss";
import Modal from "react-awesome-modal";
import { useMediaQuery } from "react-responsive";
import { useCallback, useState } from "react";
import { EXPORT_TYPE, ExportFragment } from "src/common/types/Export";
import { SelectableCircle } from "src/components/SelectableCircle/SelectableCircle";
import { Button } from "src/components";
import palette from "src/common/styles/palette.module.scss";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { showToast } from "src/common/toast";
import {
  createItemizedOrdersExport,
  createOrdersSummaryExport,
} from "src/common/export";
import { logExportReportInAnalytics } from "src/common/analytics";
import { downloadFileFromRemote } from "src/common/download";
import { convertLocalTimeToUTC } from "src/common/timezone";

interface ExportModalProps {
  beginDate: Date;
  endDate: Date;
  isVisible: boolean;
  onClose: () => void;
}

export const ExportModal = ({
  beginDate,
  endDate,
  isVisible,
  onClose,
}: ExportModalProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 875px)" });

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const [isExporting, setIsExporting] = useState(false);
  const [typeSelected, setTypeSelected] = useState<EXPORT_TYPE>(
    EXPORT_TYPE.ORDERS_SUMMARY,
  );

  const handleExport = useCallback(async () => {
    if (restaurant) {
      setIsExporting(true);

      let exportedData: ExportFragment;

      const beginDateFormatted = beginDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const endDateFormatted = endDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      endDate = new Date(endDate.setHours(23, 59, 59, 999));

      const beginDateUTC = convertLocalTimeToUTC(beginDate);
      const endDateUTC = convertLocalTimeToUTC(endDate);

      const fileName = `${
        typeSelected === EXPORT_TYPE.ORDERS_SUMMARY
          ? "orders-summary"
          : "itemized-orders"
      }-${beginDateFormatted}-${endDateFormatted}.csv`;

      if (typeSelected === EXPORT_TYPE.ORDERS_SUMMARY) {
        exportedData = await createOrdersSummaryExport(
          restaurant.id,
          beginDateUTC,
          endDateUTC,
        );
      } else {
        exportedData = await createItemizedOrdersExport(
          restaurant.id,
          beginDateUTC,
          endDateUTC,
        );
      }

      await downloadFileFromRemote(exportedData.url, fileName);

      logExportReportInAnalytics(restaurant.id, typeSelected);

      setTypeSelected(EXPORT_TYPE.ORDERS_SUMMARY);
      setIsExporting(false);
      onClose();
      showToast("Exported report successfully");
    }
  }, [restaurant, beginDate, endDate, typeSelected, onClose]);

  return (
    <Modal
      visible={isVisible}
      width={isDesktop ? "750" : "300"}
      height={isDesktop ? "400" : "450"}
      effect="fadeInUp"
      onClickAway={onClose}
    >
      <div
        className={styles.ExportModal}
        data-testid="export-report-modal-container"
      >
        <h2 className={styles.title}>Export</h2>
        <div className={styles.type}>
          <h4 className={styles.typeTitle}>Summarized</h4>
          <div
            className={styles.typeSelection}
            onClick={() => {
              setTypeSelected(EXPORT_TYPE.ORDERS_SUMMARY);
            }}
          >
            <SelectableCircle
              testID="export-report-modal-summarized-button"
              className={styles.typeSelectionCircle}
              isSelected={typeSelected === EXPORT_TYPE.ORDERS_SUMMARY}
              onClick={() => {
                setTypeSelected(EXPORT_TYPE.ORDERS_SUMMARY);
              }}
            />
            <p className={styles.exportTypeDescription}>
              Get an exported summary of your orders to see information like the
              total tips collected and the total number of orders.
            </p>
          </div>
        </div>
        <div className={styles.type}>
          <h4 className={styles.typeTitle}>Itemized</h4>
          <div
            className={styles.typeSelection}
            onClick={() => {
              setTypeSelected(EXPORT_TYPE.ITEMIZED_ORDERS);
            }}
          >
            <SelectableCircle
              testID="export-report-modal-itemized-button"
              className={styles.typeSelectionCircle}
              isSelected={typeSelected === EXPORT_TYPE.ITEMIZED_ORDERS}
              onClick={() => {
                setTypeSelected(EXPORT_TYPE.ITEMIZED_ORDERS);
              }}
            />
            <p className={styles.exportTypeDescription}>
              Get an exported list of our orders to see individual order details
              like the customer name and the order total.
            </p>
          </div>
        </div>
        {isExporting ? (
          <div
            className={styles.exportingLoading}
            data-testid="export-modal-loading"
          >
            <ReactLoading
              type="spin"
              color={palette.blue}
              height={30}
              width={30}
            />
          </div>
        ) : (
          <Button
            testId="export-report-modal-export-button"
            text="Export"
            className={styles.exportButton}
            onClick={async () => {
              await handleExport();
            }}
          />
        )}
      </div>
    </Modal>
  );
};
