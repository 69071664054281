import styles from "src/pages/Home/MoneyTable/styles.module.scss";
import { RestaurantAnalyticsFragment } from "src/state/restaurantAnalytics/types";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { useMemo } from "react";
import classNames from "classnames";
import { formatNumber } from "src/common/number";

interface ComponentProps {
  analyticsData: RestaurantAnalyticsFragment | undefined;
}

export const MoneyTable = ({ analyticsData }: ComponentProps) => {
  const totalPayouts = useMemo(() => {
    const { moneyData } = analyticsData || {};

    if (moneyData) {
      return (
        moneyData.revenue +
        moneyData.salesTax +
        moneyData.tipAmount -
        moneyData.paymentProcessingFee
      );
    }

    return 0;
  }, [analyticsData]);

  return (
    <div
      className={classNames(styles.MoneyTable, {
        [styles.loadingContainer]: !analyticsData,
      })}
    >
      {analyticsData ? (
        <div className={styles.tableContainer}>
          <div className={styles.tableRow}>
            <p className={styles.tableText}>Revenue From Orders</p>
            <p
              className={styles.tableText}
              data-testid="home-money-table-revenue"
            >
              {formatNumber(analyticsData.moneyData.revenue)}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableText}>Sales Tax Collected</p>
            <p
              className={styles.tableText}
              data-testid="home-money-table-sales-tax"
            >
              {formatNumber(analyticsData.moneyData.salesTax)}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableText}>Tips Collected</p>
            <p className={styles.tableText} data-testid="home-money-table-tips">
              {formatNumber(analyticsData.moneyData.tipAmount)}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableText}>Payment Processing Fees</p>
            <p
              className={styles.tableText}
              data-testid="home-money-table-payment-processing-fees"
            >
              -{formatNumber(analyticsData.moneyData.paymentProcessingFee)}
            </p>
          </div>
          <div className={styles.tableRow}>
            <p className={styles.tableTextBig}>Total Payouts</p>
            <p
              className={styles.tableTextBig}
              data-testid="home-money-table-total-payouts"
            >
              {formatNumber(totalPayouts)}
            </p>
          </div>
        </div>
      ) : (
        <ReactLoading type="spin" color={palette.blue} height={40} width={40} />
      )}
    </div>
  );
};
