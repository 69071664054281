import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@mui/material";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCateringRoute,
  getFinancialsRoute,
  getHomeRoute,
  getLoginPath,
  getMenuRoute,
  getOrdersRoute,
  getSettingsRoute,
} from "src/Router/routes";
import { logSignOutToAnalytics } from "src/common/analytics";
import { signOutUser } from "src/common/auth";
import styles from "src/components/Header/styles.module.scss";
import { signOutUserAction } from "src/state/auth/actions";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const headerItems: {
    name: string;
    link: string;
    testId: string;
  }[] = useMemo(() => {
    const items = [
      {
        name: "Home",
        link: getHomeRoute(),
        testId: "header-home-tab",
      },
      {
        name: "Menu",
        link: getMenuRoute("categories"),
        testId: "header-menu-tab",
      },
      {
        name: "Orders",
        link: getOrdersRoute(),
        testId: "header-orders-tab",
      },
      {
        name: "Financials",
        link: getFinancialsRoute(),
        testId: "header-financials-tab",
      },
      {
        name: "Settings",
        link: getSettingsRoute(),
        testId: "header-settings-tab",
      },
    ];

    if (
      restaurant.restaurantSettings.isCateringSchedulingFormEnabled === true
    ) {
      items.splice(4, 0, {
        name: "Catering",
        link: getCateringRoute(),
        testId: "catering-tab",
      });
    }

    return items;
  }, []);

  const currentPathName = useMemo(() => location.pathname, [location.pathname]);

  const handleSignout = useCallback(async () => {
    await Promise.all([signOutUser(), signOutUserAction()(dispatch)]);

    setIsMobileDrawerOpen(false);
    navigate(getLoginPath());
    logSignOutToAnalytics(restaurant.id);
  }, [dispatch, navigate, restaurant]);

  return (
    <div className={styles.Header}>
      <div
        className={styles.mobileMenuIcon}
        onClick={() => {
          setIsMobileDrawerOpen(true);
        }}
      >
        <FontAwesomeIcon className={styles.bars} size={"2x"} icon={faBars} />
      </div>
      <div className={styles.platterLogoContainer}>
        <img
          src={require("../../assets/BlueLogo.png")}
          alt="platter-logo"
          className={styles.platterLogo}
        />
        <h3 className={styles.platterName}>Platter</h3>
      </div>
      <h3 className={styles.restaurantName}>{restaurant.restaurantName}</h3>
      <div className={styles.restaurantLogoContainer}>
        <img
          src={restaurant.logoUrl}
          alt="restaurant-logo"
          className={styles.restaurantLogo}
        />
      </div>
      <div className={styles.mobileSpacer} />
      <Drawer
        PaperProps={{
          sx: {
            width: "50%",
          },
        }}
        anchor="left"
        open={isMobileDrawerOpen}
        onClose={() => {
          setIsMobileDrawerOpen(false);
        }}
      >
        <div className={styles.drawerContainer} data-testid="mobile-drawer">
          <img
            src={restaurant.logoUrl}
            alt="restaurant-logo"
            className={styles.drawerLogo}
          />
          {headerItems.map((item) => (
            <Link
              className={classNames(styles.drawerItem, {
                [styles.drawerItemActive]: currentPathName === item.link,
              })}
              data-testid={item.testId}
              to={item.link}
              key={item.link}
              onClick={() => setIsMobileDrawerOpen(false)}
            >
              <h3>{item.name}</h3>
            </Link>
          ))}
          <div
            className={classNames(styles.drawerItem, styles.signOutDrawerItem)}
            onClick={handleSignout}
            data-testid="sign-out-drawer"
          >
            <h3>Sign Out</h3>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
