// Order reducer
import {
  ORDER_ACTION,
  GetAllOrdersForRestaurantAction,
  GetSpecificOrderAction,
  UpdateOrderStatusAction,
  RefundOrderItemAction,
} from "src/state/order/actions";
import { OrderState } from "src/state/order/types";

type OrderAction =
  | GetAllOrdersForRestaurantAction
  | UpdateOrderStatusAction
  | GetSpecificOrderAction
  | RefundOrderItemAction;

export const orderReducer = (state: OrderState = {}, action: OrderAction) => {
  switch (action.type) {
    case ORDER_ACTION.GET_ALL_ORDERS_FOR_RESTAURANT_ACTION: {
      const { response: orders, restaurantId } = action;

      const ordersObject = orders.reduce(
        (acc, order) => ({
          ...acc,
          [order.id]: order,
        }),
        {},
      );

      const next: OrderState = {
        [restaurantId]: ordersObject,
      };

      next[restaurantId] = ordersObject;

      return next;
    }
    case ORDER_ACTION.GET_SPECIFIC_ORDER_ACTION: {
      const { response: order, restaurantId } = action;

      const next: OrderState = {
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          [order.id]: order,
        },
      };

      return next;
    }
    case ORDER_ACTION.UPDATE_ORDER_STATUS_ACTION: {
      const { response: order, restaurantId } = action;

      const next: OrderState = {
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          [order.id]: order,
        },
      };

      return next;
    }
    case ORDER_ACTION.REFUND_ORDER_ITEM_ACTION: {
      const { response: order, restaurantId } = action;

      const next: OrderState = {
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          [order.id]: order,
        },
      };

      return next;
    }
    default: {
      return state;
    }
  }
};
