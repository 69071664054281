import { Dispatch } from "redux";
import { post } from "src/api/server";
import {
  CATERING_REQUEST_STATUS,
  CateringRequestFragment,
} from "src/state/catering/types";

export enum CATERING_ACTION {
  GET_ALL_CATERING_REQUESTS_FOR_RESTAURANT_ACTION = "GET_ALL_CATERING_REQUESTS_FOR_RESTAURANT_ACTION",
  GET_CATERING_REQUEST_WITH_MESSAGES_ACTION = "GET_SPECIFIC_CATERING_REQUEST_WITH_MESSAGES_ACTION",
  UPDATE_CATERING_REQUEST_ACTION = "UPDATE_CATERING_REQUEST_ACTION",
}

export interface GetAllCateringRequestsForRestaurantAction {
  type: CATERING_ACTION.GET_ALL_CATERING_REQUESTS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: CateringRequestFragment[];
}

export const getAllCateringRequestsForRestaurantAction = (
  restaurantId: string,
) => {
  return async (
    dispatch: Dispatch<GetAllCateringRequestsForRestaurantAction>,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/catering/list_catering_requests",
      params: {
        restaurantId,
      },
    })) as CateringRequestFragment[];

    const action = {
      type: CATERING_ACTION.GET_ALL_CATERING_REQUESTS_FOR_RESTAURANT_ACTION,
      restaurantId,
      response,
    } as GetAllCateringRequestsForRestaurantAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateCateringRequestStatusAction {
  type: CATERING_ACTION.UPDATE_CATERING_REQUEST_ACTION;
  restaurantId: string;
  response: CateringRequestFragment;
}

export const updateCateringRequestStatusAction = (
  restaurantId: string,
  cateringRequestId: string,
  status: CATERING_REQUEST_STATUS,
) => {
  return async (dispatch: Dispatch<UpdateCateringRequestStatusAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/catering/update_catering_request_status",
      params: {
        cateringRequestId: cateringRequestId,
        status,
      },
    })) as CateringRequestFragment;

    const action = {
      type: CATERING_ACTION.UPDATE_CATERING_REQUEST_ACTION,
      restaurantId,
      response,
    } as UpdateCateringRequestStatusAction;

    dispatch(action);

    return action;
  };
};

export interface GetCateringRequestWithMessagesAction {
  type: CATERING_ACTION.GET_CATERING_REQUEST_WITH_MESSAGES_ACTION;
  restaurantId: string;
  response: CateringRequestFragment;
}

export const getCateringRequestAction = (
  restaurantId: string,
  cateringRequestId: string,
) => {
  return async (dispatch: Dispatch<GetCateringRequestWithMessagesAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/catering/get_catering_request",
      params: {
        cateringRequestId,
      },
    })) as CateringRequestFragment;

    const action = {
      type: CATERING_ACTION.GET_CATERING_REQUEST_WITH_MESSAGES_ACTION,
      restaurantId,
      response,
    } as GetCateringRequestWithMessagesAction;

    dispatch(action);

    return action;
  };
};
