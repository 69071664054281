// Restaurant reducer
import {
  OPTION_ACTION,
  GetOptionsForRestaurantAction,
  UpdateOptionPublishedStatusInDatabaseAction,
} from "src/state/option/actions";
import { OptionState } from "src/state/option/types";

type OptionAction =
  | GetOptionsForRestaurantAction
  | UpdateOptionPublishedStatusInDatabaseAction;

export const optionReducer = (
  state: OptionState = {},
  action: OptionAction,
) => {
  switch (action.type) {
    case OPTION_ACTION.UPDATE_OPTION_PUBLISHED_STATUS_IN_DATABASE_ACTION: {
      const next: OptionState = { ...state };

      const { response: option } = action;

      const { restaurantId } = option;

      next[restaurantId] = {
        ...(next[restaurantId] || {}),
        [option.id]: option,
      };

      return next;
    }
    case OPTION_ACTION.GET_OPTIONS_FOR_RESTAURANT_ACTION: {
      const next: OptionState = { ...state };

      const { restaurantId, options } = action;

      next[restaurantId] = {
        ...(next[restaurantId] || {}),
      };

      options.forEach((option) => {
        next[restaurantId][option.id] = option;
      });

      return next;
    }
    default: {
      return state;
    }
  }
};
