import styles from "src/components/Button/styles.module.scss";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  testId?: string;
  secondary?: boolean;
  error?: boolean;
  secondaryError?: boolean;
  text: string;
  textTestId?: string;
  textClassName?: string;
}

export const Button = (props: ButtonProps) => {
  const {
    className,
    testId,
    secondary,
    secondaryError,
    text,
    error,
    textTestId,
    textClassName,
    ...rest
  } = props;

  return (
    <button
      data-testid={testId}
      className={classNames(
        styles.Button,
        {
          [styles.secondary]: secondary,
          [styles.error]: error,
          [styles.secondaryError]: secondaryError,
        },
        className,
      )}
      {...rest}
    >
      <h2
        data-testid={textTestId}
        className={classNames(
          styles.buttonText,
          {
            [styles.secondaryText]: secondary,
            [styles.errorText]: error,
            [styles.secondaryErrorText]: secondaryError,
          },
          textClassName,
        )}
      >
        {text}
      </h2>
    </button>
  );
};
