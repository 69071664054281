// Utililty functions for interacting with AWS cognito
import { CognitoIdentityServiceProvider, config as AwsConfig } from "aws-sdk";
import { getEnvVariable } from "src/config/getConfig";
import { AdminInitiateAuthRequest } from "aws-sdk/clients/cognitoidentityserviceprovider";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import {
  putItemIntoLocalStorage,
  removeItemFromLocalStorage,
} from "src/common/localStorage";

export const logInUserInAWSCognito = async (
  email: string,
  password: string,
): Promise<{
  userId: string;
  refreshToken: string;
}> => {
  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
  });

  const userPool = new CognitoUserPool({
    UserPoolId: getEnvVariable("AWS_COGNITO_USER_POOL_ID"),
    ClientId: getEnvVariable("AWS_COGNITO_CLIENT_ID"),
  });

  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const userId = cognitoUser.getUsername();
        const refreshToken = result.getRefreshToken().getToken();

        putItemIntoLocalStorage("USER_ID", userId);
        putItemIntoLocalStorage("REFRESH_TOKEN", refreshToken);

        resolve({
          userId,
          refreshToken,
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const getIdTokenFromAWSCognito = async (
  refreshToken: string,
): Promise<string> => {
  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
  });

  const userPoolId = getEnvVariable("AWS_COGNITO_ANONYMOUS_USER_POOL_ID");
  const clientId = getEnvVariable("AWS_COGNITO_ANONYMOUS_CLIENT_ID");

  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
  const params: AdminInitiateAuthRequest = {
    UserPoolId: userPoolId,
    ClientId: clientId,
    AuthFlow: "REFRESH_TOKEN_AUTH",
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };

  const result = await cognitoIdentityServiceProvider
    .adminInitiateAuth(params)
    .promise();

  const idToken = result.AuthenticationResult?.IdToken as string;

  return idToken;
};

export const signOutUser = async (): Promise<void> => {
  removeItemFromLocalStorage("USER_ID");
  removeItemFromLocalStorage("REFRESH_TOKEN");

  return;
};
