import classNames from "classnames";
import { useState } from "react";
import { PageTitle } from "src/components";
import styles from "src/pages/Financials/styles.module.scss";
import ReactDropdown from "react-dropdown";
import { MonthlyStatements } from "src/pages/Financials/MonthlyStatements/MonthlyStatements";
import { Payouts } from "src/pages/Financials/Payouts/Payouts";

type Tab = "monthly_statements" | "payouts";

export const Financials = () => {
  const [tabSelected, setTabSelected] = useState<Tab>("monthly_statements");

  return (
    <div className={styles.Financials} data-testid="financials-container">
      <PageTitle
        title="Financials"
        subtitle={`View & manage your Platter Finances.`}
      />
      <div className={styles.tabsContainer}>
        <h4
          className={classNames(styles.tabText, {
            [styles.tabTextSelected]: tabSelected === "monthly_statements",
          })}
          onClick={() => {
            setTabSelected("monthly_statements");
          }}
        >
          Monthly Statements
        </h4>
        <h4
          className={classNames(styles.tabText, {
            [styles.tabTextSelected]: tabSelected === "payouts",
          })}
          onClick={() => {
            setTabSelected("payouts");
          }}
        >
          Payouts
        </h4>
      </div>
      <ReactDropdown
        className={styles.dropdown}
        controlClassName={styles.dropdownControl}
        menuClassName={styles.dropdownMenu}
        value={tabSelected}
        onChange={(option) => {
          setTabSelected(option.value as Tab);
        }}
        options={[
          { value: "monthly_statements", label: "Monthly Statements" },
          { value: "payouts", label: "Payouts" },
        ]}
      />
      {tabSelected === "monthly_statements" && <MonthlyStatements />}
      {tabSelected === "payouts" && <Payouts />}
    </div>
  );
};
