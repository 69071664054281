import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  formatISOStringToHoursAndMinutes,
  formatISOStringToLongDateAndTime,
} from "src/common/date";
import { ORDER_TYPE } from "src/common/types/Order";
import styles from "src/pages/Order/BasicDetails/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";

interface ComponentProps {
  orderId: string;
}

export const BasicDetails = ({ orderId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      state.orders[restaurant.id][orderId],
  );

  const timeText = useMemo(() => {
    const finalText = {
      label: "",
      value: "",
    };

    const { orderType, delivery, pickupTime, createdAt } = order;

    if (orderType === ORDER_TYPE.DELIVERY) {
      finalText.label = "Scheduled Pickup At: ";
      finalText.value = formatISOStringToHoursAndMinutes(
        delivery?.estimatedPickupTime as string,
      );
    } else {
      const pickupTimeDate = new Date(pickupTime as string);
      const createdAtDate = new Date(createdAt);

      if (
        pickupTimeDate.getHours() === createdAtDate.getHours() &&
        pickupTimeDate.getMinutes() === createdAtDate.getMinutes()
      ) {
        finalText.label = "Ready By: ";
        finalText.value = "ASAP";
      } else {
        finalText.label = "Scheduled Pickup At: ";
        finalText.value = formatISOStringToHoursAndMinutes(
          pickupTime as string,
        );
      }
    }

    return finalText;
  }, [order]);

  return (
    <div className={styles.BasicDetails}>
      <h3 className={styles.title}>Details</h3>
      {order.orderType === ORDER_TYPE.DELIVERY ? (
        <div
          className={styles.trackingLinkContainer}
          data-testid="order-screen-delivery-details"
        >
          <p className={styles.deliveryOrder}>{`Delivery Order: `}</p>
          <div
            className={styles.clickableTrackingLink}
            onClick={() => {
              if (order.delivery && order.delivery.trackingUrl) {
                window.open(order.delivery.trackingUrl, "_blank");
              }
            }}
          >
            <p className={styles.trackingLinkText}>Tracking Link</p>
          </div>
        </div>
      ) : (
        <p
          className={styles.basicDetailTitle}
          data-testid="order-screen-pickup-details"
        >
          {`Order Type: `}
          <span className={styles.basicDetailValue}>Pickup</span>
        </p>
      )}
      <p className={styles.basicDetailTitle}>
        {`Placed At: `}
        <span className={styles.basicDetailValue}>
          {formatISOStringToLongDateAndTime(order.createdAt)}
        </span>
      </p>
      <p className={styles.basicDetailTitle}>
        {timeText.label}
        <span className={styles.basicDetailValue}>{timeText.value}</span>
      </p>
    </div>
  );
};
