import styles from "src/components/DateRangePicker/styles.module.scss";
import { DateRange } from "react-date-range";
import ReactDropdown from "react-dropdown";
import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import palette from "src/common/styles/palette.module.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import "react-dropdown/style.css";
import { logChangeAnalyticsTimeframeToAnalytics } from "src/common/analytics";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { RestaurantFragment } from "src/state/restaurant/types";
import { convertLocalTimeToUTC } from "src/common/timezone";

import {
  subMonths,
  subWeeks,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";
interface DateRangePickerProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
}

export const enum DatePickerType {
  TODAY = "TODAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  CUSTOM = "CUSTOM",
}

export const DateRangePicker = ({
  className,
  startDate,
  endDate,
  onDateRangeChange,
}: DateRangePickerProps) => {
  const dateRangeRef = useRef<HTMLDivElement>(null);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const todayDate = useMemo(() => new Date(), []);

  const oneWeekAgoDate = useMemo(() => {
    const startOfLastWeek = startOfWeek(subWeeks(todayDate, 1), {
      weekStartsOn: 0,
    });
    const endOfLastWeek = endOfWeek(subWeeks(todayDate, 1), {
      weekStartsOn: 0,
    });

    startOfLastWeek.setHours(0, 0, 0, 0);
    endOfLastWeek.setHours(23, 59, 59, 999);

    return {
      start: convertLocalTimeToUTC(startOfLastWeek),
      end: convertLocalTimeToUTC(endOfLastWeek),
    };
  }, [todayDate]);

  const oneMonthAgoDate = useMemo(() => {
    const startOfLastMonth = startOfMonth(subMonths(todayDate, 1));
    const endOfLastMonth = endOfMonth(subMonths(todayDate, 1));

    startOfLastMonth.setHours(0, 0, 0, 0);
    endOfLastMonth.setHours(23, 59, 59, 999);

    return {
      start: convertLocalTimeToUTC(startOfLastMonth),
      end: convertLocalTimeToUTC(endOfLastMonth),
    };
  }, [todayDate]);

  const datesSelectedText = useMemo(() => {
    const startDateText = startDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });

    const endDateText = endDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });

    return `${startDateText} - ${endDateText}`;
  }, [startDate, endDate]);

  const [datePickerType, setDatePickerType] = useState<DatePickerType>(
    DatePickerType.LAST_MONTH,
  );
  const [isRangePickerVisible, setIsRangePickerVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target as Node)
      ) {
        setIsRangePickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRangeRef]);

  return (
    <div
      ref={dateRangeRef}
      className={classNames(styles.DateRangePicker, className)}
    >
      <div className={styles.rowContainer}>
        <ReactDropdown
          className={styles.dropdown}
          controlClassName={styles.dropdownControl}
          menuClassName={styles.dropdownMenu}
          value={datePickerType}
          onChange={(option) => {
            if (option.value === DatePickerType.CUSTOM) {
              setTimeout(() => {
                setIsRangePickerVisible(true);
              }, 50);

              return;
            } else if (option.value === DatePickerType.TODAY) {
              onDateRangeChange(todayDate, todayDate);
            } else if (option.value === DatePickerType.LAST_WEEK) {
              onDateRangeChange(oneWeekAgoDate.start, oneWeekAgoDate.end);
            } else if (option.value === DatePickerType.LAST_MONTH) {
              onDateRangeChange(oneMonthAgoDate.start, oneMonthAgoDate.end);
            }

            setDatePickerType(option.value as DatePickerType);
            logChangeAnalyticsTimeframeToAnalytics(
              option.value as DatePickerType,
              restaurant.id,
            );
          }}
          options={[
            {
              label: "Today",
              value: DatePickerType.TODAY,
            },
            {
              label: "Last Week",
              value: DatePickerType.LAST_WEEK,
            },
            {
              label: "Last Month",
              value: DatePickerType.LAST_MONTH,
            },
            {
              label: "Custom",
              value: DatePickerType.CUSTOM,
            },
          ]}
        />
        <div
          className={styles.dateRange}
          onClick={() => {
            setIsRangePickerVisible(!isRangePickerVisible);
          }}
        >
          <FontAwesomeIcon
            className={styles.calendarIcon}
            icon={faCalendarDays}
          />
          <p className={styles.datesText}>{datesSelectedText}</p>
        </div>
      </div>
      {isRangePickerVisible && (
        <div className={styles.rangePickerContainer}>
          <DateRange
            className={styles.rangePicker}
            showSelectionPreview={false}
            ranges={[{ startDate, endDate, key: "selection" }]}
            onChange={(ranges) => {
              onDateRangeChange(
                ranges.selection.startDate as Date,
                ranges.selection.endDate as Date,
              );
              logChangeAnalyticsTimeframeToAnalytics(
                DatePickerType.CUSTOM,
                restaurant.id,
              );
            }}
            maxDate={todayDate}
            rangeColors={[palette.blue]}
          />
        </div>
      )}
    </div>
  );
};
