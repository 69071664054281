import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDropdown from "react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Button, PageTitle, SkeletonLoading } from "src/components";
import styles from "src/pages/Menu/styles.module.scss";
import palette from "src/common/styles/palette.module.scss";
import ReactLoading from "react-loading";
import {
  GetCategoriesForRestaurantAction,
  getCategoriesForRestaurantAction,
  updatePublishedStatusForMultipleCategoriesInDatabaseAction,
} from "src/state/category/actions";
import {
  GetDealsForRestaurantAction,
  getDealsForRestaurantAction,
  updateDealPublishStatusAction,
} from "src/state/deal/actions";
import { DEAL_TYPE } from "src/state/deal/types";
import {
  GetAllItemsForRestaurantFromDatabaseAction,
  getAllItemsForRestaurantFromDatabaseAction,
  updatePublishedStatusForMultipleItemsInDatabaseAction,
} from "src/state/item/actions";
import {
  GetOptionsForRestaurantAction,
  getOptionsForRestaurantAction,
  updateOptionPublishedStatusInDatabaseAction,
} from "src/state/option/actions";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import {
  logUpdateCategoriesPublishedStatusToAnalytics,
  logUpdateDealsPublishedStatusToAnalytics,
  logUpdateItemsPublishedStatusToAnalytics,
  logUpdateOptionsPublishedStatusToAnalytics,
} from "src/common/analytics";
import { useNavigate } from "react-router-dom";
import { getItemRoute } from "src/Router/routes";
import { useDebounce } from "src/common/useDebounce";
import { CreateItemModal } from "src/components/CreateItemModal/CreateItemModal";

type EntityType = "categories" | "items" | "options" | "deals";

export const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const categories = useSelector(
    (state: State) => state.categories[restaurant.id],
  );
  const items = useSelector((state: State) => state.items[restaurant.id]);
  const options = useSelector((state: State) => state.options[restaurant.id]);
  const deals = useSelector((state: State) => state.deals[restaurant.id]);

  const [isLoading, setIsLoading] = useState(true);
  const [entityTypeSelected, setEntityTypeSelected] =
    useState<EntityType>("categories");
  const [entityIdsSelected, setEntityIdsSelected] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isUpdatingPublishStatus, setIsUpdatingPublishStatus] = useState(false);
  const [isCreateItemModalVisible, setIsCreateItemModalVisible] =
    useState(false);

  const entities: { label: string; value: EntityType }[] = [
    { label: "Categories", value: "categories" },
    { label: "Items", value: "items" },
    { label: "Options", value: "options" },
    { label: "Deals", value: "deals" },
  ];

  const entitySelectedToEntities = {
    categories: categories,
    items: items,
    options: options,
    deals: deals,
  };

  const loadEntities = useCallback(async () => {
    const promises: Promise<
      | GetCategoriesForRestaurantAction
      | GetAllItemsForRestaurantFromDatabaseAction
      | GetOptionsForRestaurantAction
      | GetDealsForRestaurantAction
    >[] = [];

    if (!categories) {
      promises.push(getCategoriesForRestaurantAction(restaurant.id)(dispatch));
    }

    if (!items) {
      promises.push(
        getAllItemsForRestaurantFromDatabaseAction(restaurant.id)(dispatch),
      );
    }

    if (!options) {
      promises.push(getOptionsForRestaurantAction(restaurant.id)(dispatch));
    }

    if (!deals) {
      promises.push(getDealsForRestaurantAction(restaurant.id)(dispatch));
    }

    await Promise.all(promises);

    setIsLoading(false);
  }, [restaurant, categories, items, options, deals]);

  const nameColumnTitle = useMemo(() => {
    switch (entityTypeSelected) {
      case "categories":
        return "Category";
      case "items":
        return "Item";
      case "options":
        return "Option";
      case "deals":
        return "Deal";
    }
  }, [entityTypeSelected]);

  const infoColumnTitle = useMemo(() => {
    switch (entityTypeSelected) {
      case "categories":
        return "Number of Items";
      case "items":
        return "Category";
      case "options":
        return "Number of Items";
      case "deals":
        return "Type";
    }
  }, [entityTypeSelected]);

  const areAllEntitiesSelected = useMemo(() => {
    if (isLoading) {
      return false;
    }

    const entities = entitySelectedToEntities[entityTypeSelected];
    const entitiesArray = Object.values(entities);

    return entityIdsSelected.length === entitiesArray.length;
  }, [
    entityTypeSelected,
    entityIdsSelected,
    entitySelectedToEntities,
    isLoading,
  ]);

  const isEntitiesEmpty = useMemo(() => {
    if (isLoading) {
      return false;
    }

    const entities = entitySelectedToEntities[entityTypeSelected];
    return Object.values(entities).length === 0;
  }, [entityTypeSelected, entitySelectedToEntities, isLoading]);

  const entitiesToDisplay: {
    id: string;
    name: string;
    isPublished: boolean;
    info: string;
    image?: string;
  }[] = useMemo(() => {
    let entitiesArray: {
      id: string;
      name: string;
      isPublished: boolean;
      info: string;
      image?: string;
    }[] = [];

    if (isLoading) {
      return entitiesArray;
    }

    if (entityTypeSelected === "categories") {
      const categoriesArray = Object.values(categories);
      const itemsArray = Object.values(items);

      entitiesArray = categoriesArray.map((category) => ({
        id: category.id,
        name: category.name,
        isPublished: category.isPublished,
        info: itemsArray
          .filter((item) => item.categoryId === category.id)
          .length.toString(),
        image: category.imageURL,
      }));
    } else if (entityTypeSelected === "items") {
      const categoriesArray = Object.values(categories);
      const itemsArray = Object.values(items);

      entitiesArray = itemsArray.map((item) => ({
        id: item.id,
        name: item.name,
        isPublished: item.isPublished,
        info: categoriesArray.find(
          (category) => category.id === item.categoryId,
        )?.name as string,
        image: item.imageURL,
      }));
    } else if (entityTypeSelected === "options") {
      const optionsArray = Object.values(options);

      entitiesArray = optionsArray.map((option) => ({
        id: option.id,
        name: option.name,
        isPublished: option.isPublished,
        info: option.itemIds.length.toString(),
      }));
    } else if (entityTypeSelected === "deals") {
      const dealsArray = Object.values(deals);

      const dealTypeToString = {
        [DEAL_TYPE.BOGO]: "BOGO",
        [DEAL_TYPE.SET_PRICE]: "Set Amount",
        [DEAL_TYPE.AMOUNT_OFF]: "Amount Off",
        [DEAL_TYPE.COMBO]: "Combo",
      };

      entitiesArray = dealsArray.map((deal) => ({
        id: deal.id,
        name: deal.name,
        isPublished: deal.isPublished,
        info: dealTypeToString[deal.type] as string,
        image: deal.imageUrl,
      }));
    }

    if (searchQuery.length > 0) {
      entitiesArray = entitiesArray.filter((entity) =>
        entity.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    return entitiesArray;
  }, [
    isUpdatingPublishStatus,
    entityTypeSelected,
    isLoading,
    categories,
    items,
    options,
    deals,
    searchQuery,
  ]);

  const updatePublishedStatusForEntities = useCallback(
    async (newPublishedStatus: boolean) => {
      setIsUpdatingPublishStatus(true);

      if (entityTypeSelected === "categories") {
        await dispatch(
          updatePublishedStatusForMultipleCategoriesInDatabaseAction(
            restaurant.id,
            entityIdsSelected,
            newPublishedStatus,
          ),
        );
        logUpdateCategoriesPublishedStatusToAnalytics(
          restaurant.id,
          entityIdsSelected,
          newPublishedStatus,
        );
      } else if (entityTypeSelected === "items") {
        await dispatch(
          updatePublishedStatusForMultipleItemsInDatabaseAction(
            entityIdsSelected,
            restaurant.id,
            newPublishedStatus,
          ),
        );
        logUpdateItemsPublishedStatusToAnalytics(
          restaurant.id,
          entityIdsSelected,
          newPublishedStatus,
        );
      } else if (entityTypeSelected === "options") {
        await Promise.all(
          entityIdsSelected.map((optionId) =>
            dispatch(
              updateOptionPublishedStatusInDatabaseAction(
                optionId,
                newPublishedStatus,
              ),
            ),
          ),
        );
        logUpdateOptionsPublishedStatusToAnalytics(
          restaurant.id,
          entityIdsSelected,
          newPublishedStatus,
        );
      } else if (entityTypeSelected === "deals") {
        await Promise.all(
          entityIdsSelected.map((dealId) =>
            dispatch(
              updateDealPublishStatusAction(deals[dealId], newPublishedStatus),
            ),
          ),
        );
        logUpdateDealsPublishedStatusToAnalytics(
          restaurant.id,
          entityIdsSelected,
          newPublishedStatus,
        );
      }

      setEntityIdsSelected([]);
      setIsUpdatingPublishStatus(false);
    },
    [restaurant, entityIdsSelected, deals, entityTypeSelected, dispatch],
  );

  useEffect(() => {
    loadEntities();
  }, []);

  useEffect(() => {
    const entityType = new URLSearchParams(window.location.search).get(
      "entityType",
    ) as EntityType;

    const searchQuery = new URLSearchParams(window.location.search).get(
      "searchQuery",
    );

    if (entityType) {
      setEntityTypeSelected(entityType);
    }

    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
  }, []);

  useDebounce(
    async () => {
      if (searchQuery.length > 0) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("searchQuery", searchQuery);

        window.history.pushState(
          {},
          "",
          `${window.location.pathname}?${searchParams.toString()}`,
        );
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("searchQuery");

        window.history.pushState(
          {},
          "",
          `${window.location.pathname}?${searchParams.toString()}`,
        );
      }
    },
    [searchQuery],
    250,
  );

  if (isLoading) {
    return (
      <div
        className={classNames(styles.Menu, styles.loading)}
        data-testid="menu-loading-container"
      >
        <SkeletonLoading />
      </div>
    );
  }

  return (
    <div className={styles.Menu} data-testid="menu-container">
      <PageTitle
        title="Menu"
        subtitle={`Manage what your customers can order.`}
      />
      <div className={styles.topRow}>
        <div className={styles.entityTypeContainer}>
          {entities.map((entity) => (
            <h4
              key={entity.value}
              className={classNames(styles.entityText, {
                [styles.entityTextSelected]:
                  entityTypeSelected === entity.value,
              })}
              onClick={() => {
                setEntityTypeSelected(entity.value);
                setEntityIdsSelected([]);

                const searchParams = new URLSearchParams(
                  window.location.search,
                );
                searchParams.set("entityType", entity.value);

                window.history.pushState(
                  {},
                  "",
                  `${window.location.pathname}?${searchParams.toString()}`,
                );
              }}
              data-testid={`menu-${entity.value}-button`}
            >
              {entity.label}
            </h4>
          ))}
        </div>
        <ReactDropdown
          className={styles.dropdown}
          controlClassName={styles.dropdownControl}
          menuClassName={styles.dropdownMenu}
          value={entityTypeSelected}
          onChange={(option) => {
            setEntityTypeSelected(option.value as EntityType);
            setEntityIdsSelected([]);

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("entityType", option.value);

            window.history.pushState(
              {},
              "",
              `${window.location.pathname}?${searchParams.toString()}`,
            );
          }}
          options={entities}
        />
        {entityTypeSelected === "items" && (
          <Button
            onClick={() => setIsCreateItemModalVisible(true)}
            data-testid="create-item-button"
            text="Create Item"
            className={styles.createButton}
          />
        )}
        {isUpdatingPublishStatus ? (
          <ReactLoading
            data-testid="menu-loading-spinner"
            type="spin"
            color={palette.blue}
            height={30}
            width={30}
          />
        ) : entityIdsSelected.length > 0 ? (
          <div className={styles.publishButtonsContainer}>
            <button
              className={styles.publishButton}
              onClick={async () => {
                await updatePublishedStatusForEntities(true);
              }}
              data-testid="menu-publish-button"
            >
              <h4 className={styles.publishButtonText}>Publish</h4>
            </button>
            <button
              className={styles.unpublishButton}
              onClick={async () => {
                await updatePublishedStatusForEntities(false);
              }}
              data-testid="menu-unpublish-button"
            >
              <h4 className={styles.publishButtonText}>Unpublish</h4>
            </button>
          </div>
        ) : (
          <div />
        )}
      </div>
      {isEntitiesEmpty ? (
        <div
          className={styles.emptyStateContainer}
          data-testid={`menu-${entityTypeSelected}-empty-container`}
        >
          <h3
            className={styles.emptyStateText}
            data-testid={`menu-${entityTypeSelected}-empty-text`}
          >
            {`You haven't created any ${entityTypeSelected}.`}
          </h3>
        </div>
      ) : (
        <>
          <div className={styles.searchContainer}>
            <FontAwesomeIcon
              className={styles.searchIcon}
              size={"2x"}
              icon={faSearch}
            />
            <input
              type="text"
              placeholder="Search..."
              className={styles.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              data-testid="menu-search-input"
            />
          </div>
          <div className={styles.entitiesContainer}>
            <div className={styles.row}>
              <div className={styles.checkboxColumn}>
                <input
                  type="checkbox"
                  checked={areAllEntitiesSelected}
                  className={styles.checkbox}
                  onChange={() => {
                    if (areAllEntitiesSelected) {
                      setEntityIdsSelected([]);
                    } else {
                      const entityIds = entitiesToDisplay.map(
                        (entity) => entity.id,
                      );

                      setEntityIdsSelected(entityIds);
                    }
                  }}
                  data-testid="menu-select-all-checkbox"
                />
              </div>
              <div className={styles.nameColumn}>
                <h3>{nameColumnTitle}</h3>
              </div>
              <div className={styles.statusColumn}>
                <h3>Status</h3>
              </div>
              <div className={styles.infoColumn}>
                <h3>{infoColumnTitle}</h3>
              </div>
            </div>
            {entitiesToDisplay.map((entity) => {
              const testPrefix =
                entityTypeSelected === "categories"
                  ? "menu-category"
                  : entityTypeSelected === "items"
                    ? "menu-item"
                    : entityTypeSelected === "options"
                      ? "menu-option"
                      : "menu-deal";

              return (
                <div
                  key={entity.id}
                  className={styles.row}
                  data-testid={`${testPrefix}-${entity.id}-container`}
                  onClick={() => {
                    if (entityTypeSelected === "items") {
                      navigate(getItemRoute(entity.id));
                    }
                  }}
                >
                  <div className={styles.checkboxColumn}>
                    <input
                      type="checkbox"
                      checked={entityIdsSelected.includes(entity.id)}
                      className={styles.checkbox}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        if (entityIdsSelected.includes(entity.id)) {
                          setEntityIdsSelected((prev) =>
                            prev.filter((id) => id !== entity.id),
                          );
                        } else {
                          setEntityIdsSelected((prev) => [...prev, entity.id]);
                        }
                      }}
                      data-testid={`${testPrefix}-${entity.id}-checkbox`}
                    />
                  </div>
                  <div className={styles.nameColumn}>
                    {entity.image && (
                      <img
                        src={entity.image}
                        alt="Entity"
                        className={styles.entityImage}
                      />
                    )}
                    <h4
                      className={styles.rowText}
                      data-testid={`${testPrefix}-${entity.id}-name`}
                    >
                      {entity.name}
                    </h4>
                  </div>
                  <div className={styles.statusColumn}>
                    <div
                      className={classNames(
                        styles.statusPill,
                        entity.isPublished
                          ? styles.published
                          : styles.unpublished,
                      )}
                    >
                      <h4
                        className={styles.statusPillText}
                        data-testid={`${testPrefix}-${entity.id}-status`}
                      >
                        {entity.isPublished ? "Published" : "Unpublished"}
                      </h4>
                    </div>
                  </div>
                  <div className={styles.infoColumn}>
                    <h4
                      className={styles.rowText}
                      data-testid={`${testPrefix}-${entity.id}-info`}
                    >
                      {entity.info}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
          {isCreateItemModalVisible && (
            <CreateItemModal
              isVisible={isCreateItemModalVisible}
              restaurantId={restaurant.id}
              onClose={() => {
                setIsCreateItemModalVisible(false);
              }}
              onComplete={() => {
                setIsCreateItemModalVisible(false);
                loadEntities();
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
