export const getLoginPath = () => {
  return "/";
};

export const getHomeRoute = () => {
  return "/home";
};

export const getMenuRoute = (entityType?: string) => {
  if (entityType) {
    return `/menu/?entityType=${entityType}`;
  }

  return "/menu";
};

export const getOrdersRoute = () => {
  return "/orders";
};

export const getOrderRoute = (orderId: string) => {
  return `/order/${orderId}`;
};

export const getCateringRoute = () => {
  return "/catering";
};

export const getCateringRequestRoute = (cateringRequestId: string) => {
  return `/catering-request/${cateringRequestId}`;
};

export const getItemRoute = (itemId: string) => {
  return `/item/${itemId}`;
};

export const getHoursOfOperationRoute = () => {
  return "/hours-of-operation";
};

export const getDesignRoute = () => {
  return "/design";
};

export const getSocialMediaLinks = () => {
  return "/social-media-links";
};

export const getFinancialsRoute = () => {
  return "/financials";
};

export const getSettingsRoute = () => {
  return "/settings";
};

export const getRestaurantContactUsRoute = () => {
  return "/restaurant-contact-us";
};

export const getWildcardRoute = () => {
  return "*";
};
