// Actions associated with category
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { getCategoryImageURLFromCategoryId } from "src/common/s3";
import { CategoryFragment } from "src/state/category/types";
import {
  ITEM_ACTION,
  UpdatePublishedStatusForMultipleItemsInDatabaseAction,
} from "src/state/item/actions";
import { State } from "src/state/state";
import {
  DEAL_ACTION,
  UpdateDealPublishStatusAction,
} from "src/state/deal/actions";
import { DealFragment } from "src/state/deal/types";

export enum CATEGORY_ACTION {
  UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION = "UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION",
  GET_CATEGORIES_FOR_RESTAURANT_ACTION = "GET_CATEGORIES_FOR_RESTAURANT_ACTION",
}

const dispatchUpdateDealsUnpublishAction = (
  deals: DealFragment[],
  dispatch: Dispatch<UpdateDealPublishStatusAction>,
) => {
  for (const deal of deals) {
    const action = {
      type: DEAL_ACTION.UPDATE_DEAL_PUBLISHED_STATUS_ACTION,
      restaurantId: deal.restaurantId,
      response: {
        ...deal,
        isPublished: false,
      },
    } as UpdateDealPublishStatusAction;

    dispatch(action);
  }
};

export interface UpdatePublishedStatusForMultipleCategoriesInDatabaseAction {
  type: CATEGORY_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION;
  restaurantId: string;
  response: CategoryFragment[];
}

export const updatePublishedStatusForMultipleCategoriesInDatabaseAction = (
  restaurantId: string,
  categoryIds: string[],
  isPublished: boolean,
) => {
  return async (
    dispatch: Dispatch<
      | UpdatePublishedStatusForMultipleCategoriesInDatabaseAction
      | UpdatePublishedStatusForMultipleItemsInDatabaseAction
      | UpdateDealPublishStatusAction
    >,
    getState: () => State,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/category/update_published_status",
      params: {
        ids: categoryIds,
        isPublished,
      },
    })) as CategoryFragment[];

    const reduxState = getState();

    if (!isPublished) {
      for (const category of response) {
        const dealsForThisCategory = Object.values(
          reduxState.deals[category.restaurantId],
        ).filter((deal) => deal.entityIds.includes(category.id));

        dispatchUpdateDealsUnpublishAction(dealsForThisCategory, dispatch);
      }
    }

    for (const categoryUpdated of response) {
      const itemsInThisCategory = Object.values(
        reduxState.items[categoryUpdated.restaurantId],
      ).filter((item) => item.categoryId === categoryUpdated.id);

      for (const item of itemsInThisCategory) {
        const publishItemAction = {
          type: ITEM_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION,
          response: {
            restaurantId: categoryUpdated.restaurantId,
            itemIds: [item.id],
            isPublished,
          },
        } as UpdatePublishedStatusForMultipleItemsInDatabaseAction;

        dispatch(publishItemAction);

        if (!isPublished) {
          const dealsForThisItem = Object.values(
            reduxState.deals[categoryUpdated.restaurantId],
          ).filter((deal) => deal.entityIds.includes(item.id));

          dispatchUpdateDealsUnpublishAction(dealsForThisItem, dispatch);
        }
      }
    }

    const responseWithImage = response.map((eachCategory) => ({
      ...eachCategory,
      imageURL: eachCategory.hasImage
        ? getCategoryImageURLFromCategoryId(eachCategory.id)
        : undefined,
    }));

    const action = {
      type: CATEGORY_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION,
      response: responseWithImage,
      restaurantId,
    } as UpdatePublishedStatusForMultipleCategoriesInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface GetCategoriesForRestaurantAction {
  type: CATEGORY_ACTION.GET_CATEGORIES_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: CategoryFragment[];
}

export const getCategoriesForRestaurantAction = (restaurantId: string) => {
  return async (dispatch: Dispatch<GetCategoriesForRestaurantAction>) => {
    const categories = (await post({
      service: "thanos",
      endpoint: "/category/list",
      params: {
        restaurantId,
      },
    })) as CategoryFragment[];

    const action = {
      type: CATEGORY_ACTION.GET_CATEGORIES_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: categories.map((category) => ({
        ...category,
        imageURL: category.hasImage
          ? getCategoryImageURLFromCategoryId(category.id)
          : undefined,
      })),
    } as GetCategoriesForRestaurantAction;

    dispatch(action);

    return action;
  };
};
