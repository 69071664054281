import { post } from "src/api/server";
import {
  CateringInvoiceFragment,
  CateringMessageFragment,
  LineItem,
} from "src/state/catering/types";

export const getSalesTaxRateForCateringRequest = async (
  cateringRequestId: string,
) => {
  const response = (await post({
    service: "thanos",
    endpoint: "/catering/get_sales_tax_rate_for_catering_request",
    params: {
      cateringRequestId,
    },
  })) as { salesTaxRate: number };
  return response.salesTaxRate;
};

export const createCateringInvoiceInDatabase = async (
  cateringRequestId: string,
  lineItems: LineItem[],
  requiredTip: number,
) => {
  const response = (await post({
    service: "thanos",
    endpoint: "/catering/create_catering_invoice",
    params: {
      cateringRequestId,
      lineItems,
      requiredTip,
    },
  })) as CateringInvoiceFragment;

  return response;
};

export const sendMessageFromRestaurantToCustomer = async (
  cateringRequestId: string,
  restaurantId: string,
  message: string,
) => {
  const response = await post({
    service: "thanos",
    endpoint: "/catering/send_message_from_restaurant_to_customer",
    params: {
      cateringRequestId,
      restaurantId,
      message,
    },
  });

  return response as CateringMessageFragment;
};
