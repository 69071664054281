import styles from "src/pages/RestaurantContactUs/styles.module.scss";
import { TextInput, Button } from "src/components";
import { useCallback, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { SelectableCircle } from "src/components/SelectableCircle/SelectableCircle";
import { post } from "src/api/server";
import { showToast } from "src/common/toast";
import {
  logRestaurantContactUsSubmittedToAnalytics,
  logRestaurantContactUsViewedToAnalytics,
} from "src/common/analytics";

export const RestaurantContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAccessSelected, setIsAccessSelected] = useState(false);
  const [isTakeDownSelected, setIsTakeDownSelected] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);

  useEffect(() => {
    logRestaurantContactUsViewedToAnalytics();
  }, []);

  const onSubmit = useCallback(async () => {
    if (!restaurantName) {
      setErrorBox("Please enter the restaurant's name.");
      return;
    }

    if (!emailAddress) {
      setErrorBox("Please enter your email address.");
      return;
    }

    if (!phoneNumber) {
      setErrorBox("Please enter your phone number.");
      return;
    }

    if (!isAccessSelected && !isTakeDownSelected && !isOtherSelected) {
      setErrorBox("Please select a request type.");
      return;
    }

    setIsLoading(true);

    await post({
      service: "thanos",
      endpoint: "/restaurant/send_restaurant_contact_us_email",
      params: {
        restaurantName,
        emailAddress,
        phoneNumber,
        requestType: isAccessSelected
          ? "access"
          : isTakeDownSelected
            ? "takeDown"
            : "other",
        additionalNotes,
      },
    });
    logRestaurantContactUsSubmittedToAnalytics(
      restaurantName,
      emailAddress,
      phoneNumber,
      isAccessSelected ? "access" : isTakeDownSelected ? "takeDown" : "other",
      additionalNotes,
    );

    setIsLoading(false);
    setEmailAddress("");
    setPhoneNumber("");
    setIsAccessSelected(false);
    setIsTakeDownSelected(false);
    setIsOtherSelected(false);
    setAdditionalNotes("");
    showToast(
      "Your request has been submitted. We will be in touch within 24 hours.",
      palette.white,
      palette.blue,
    );
  }, [
    restaurantName,
    emailAddress,
    phoneNumber,
    isAccessSelected,
    isTakeDownSelected,
    isOtherSelected,
    additionalNotes,
  ]);

  return (
    <div className={styles.RestaurantContactUs}>
      <div className={styles.contactUsContainer}>
        <div className={styles.brandContainer}>
          <img
            src={require("../../assets/BlueLogo.png")}
            alt="logo"
            className={styles.logo}
          />
          <h3 className={styles.brandText}>Platter</h3>
        </div>
        <h2 className={styles.title}>Restaurant Website Request</h2>
        <h4 className={styles.subtitle}>
          Platter is a service created by customers for customers. We gather
          item images, descriptions, and make restaurants look good online so
          that customers can have a better ordering experience. We're here for
          you if you have any questions. This contact form goes straight to our
          inboxes & we'll answer within 24 hours. You can also email us at
          admin@joinplatter.com.{" "}
          <a
            target={"_blank"}
            href="https://joinplatter.com/what-is-platter"
            className={styles.learnMore}
          >
            Learn More
          </a>
        </h4>
        <TextInput
          className={styles.emailInput}
          label="Restaurant Name"
          value={restaurantName}
          onChangeText={(newText) => {
            setErrorBox(undefined);
            setRestaurantName(newText);
          }}
          placeholder="Enter the restaurant's name..."
        />
        <TextInput
          className={styles.emailInput}
          label="Email Address"
          type="email"
          autoComplete="email"
          value={emailAddress}
          onChangeText={(newText) => {
            setErrorBox(undefined);
            setEmailAddress(newText);
          }}
          placeholder="Enter your email address..."
        />
        <TextInput
          className={styles.phoneInput}
          label="Owner's Phone Number"
          type="tel"
          autoComplete="tel"
          value={phoneNumber}
          onChangeText={(newText) => {
            setErrorBox(undefined);
            setPhoneNumber(newText);
          }}
          placeholder="Enter the owner's phone number..."
        />
        <div
          className={styles.selectableRow}
          onClick={() => {
            if (isAccessSelected) {
              setIsAccessSelected(false);
            } else {
              setIsAccessSelected(true);
              setIsTakeDownSelected(false);
              setIsOtherSelected(false);
            }
          }}
        >
          <SelectableCircle
            isSelected={isAccessSelected}
            onClick={() => {
              if (isAccessSelected) {
                setIsAccessSelected(false);
              } else {
                setIsAccessSelected(true);
                setIsTakeDownSelected(false);
                setIsOtherSelected(false);
              }
            }}
          />
          <p className={styles.selectableText}>
            Requesting access to my restaurant's website.
          </p>
        </div>
        <div
          className={styles.selectableRow}
          onClick={() => {
            if (isTakeDownSelected) {
              setIsTakeDownSelected(false);
            } else {
              setIsAccessSelected(false);
              setIsTakeDownSelected(true);
              setIsOtherSelected(false);
            }
          }}
        >
          <SelectableCircle
            isSelected={isTakeDownSelected}
            onClick={() => {
              if (isTakeDownSelected) {
                setIsTakeDownSelected(false);
              } else {
                setIsAccessSelected(false);
                setIsTakeDownSelected(true);
                setIsOtherSelected(false);
              }
            }}
          />
          <p className={styles.selectableText}>
            Requesting to take down my restaurant's website.
          </p>
        </div>
        <div
          className={styles.selectableRow}
          onClick={() => {
            if (isOtherSelected) {
              setIsOtherSelected(false);
            } else {
              setIsAccessSelected(false);
              setIsTakeDownSelected(false);
              setIsOtherSelected(true);
            }
          }}
        >
          <SelectableCircle
            isSelected={isOtherSelected}
            onClick={() => {
              if (isOtherSelected) {
                setIsOtherSelected(false);
              } else {
                setIsAccessSelected(false);
                setIsTakeDownSelected(false);
                setIsOtherSelected(true);
              }
            }}
          />
          <p className={styles.selectableText}>Other</p>
        </div>
        <TextInput
          className={styles.additionalInput}
          label="Additional Notes"
          value={additionalNotes}
          onChangeText={(newText) => {
            setErrorBox(undefined);
            setAdditionalNotes(newText);
          }}
          placeholder="Add any additional notes here..."
        />
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <ReactLoading
              type="spin"
              color={palette.blue}
              height={40}
              width={40}
            />
          </div>
        ) : (
          <Button
            className={styles.submitButton}
            disabled={isLoading}
            text="Submit"
            onClick={onSubmit}
          />
        )}
        {errorBox && (
          <div className={styles.errorBox}>
            <FontAwesomeIcon
              className={styles.errorIcon}
              icon={faTriangleExclamation}
            />
            <p data-testid="error-box-text" className={styles.errorBoxText}>
              {errorBox}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
