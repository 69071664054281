// Actions associated with restaurants
import { Dispatch } from "redux";
import { post } from "src/api/server";
import {
  HoursOfOperationFragment,
  HoursOfOperationMode,
} from "src/common/types/HoursOfOperation";
import { LocationFragment } from "src/common/types/Location";
import {
  RestaurantDesignFragment,
  RestaurantFragment,
  SocialMediaLinksFragment,
} from "src/state/restaurant/types";

export enum RESTAURANT_ACTION {
  SET_RESTAURANT_FROM_DATABASE_ACTION = "SET_RESTAURANT_FROM_DATABASE_ACTION",
  UPDATE_RESTAURANT_IN_DATABASE_ACTION = "UPDATE_RESTAURANT_IN_DATABASE_ACTION",
  UPDATE_HOURS_OF_OPERATION_MODE_IN_DATABASE_ACTION = "UPDATE_HOURS_OF_OPERATION_MODE_IN_DATABASE_ACTION",
  UPDATE_RESTAURANT_OPEN_STATUS_ACTION = "UPDATE_RESTAURANT_OPEN_STATUS_ACTION",
  UPDATE_LOCATION_HOURS_IN_DATABASE_ACTION = "UPDATE_LOCATION_HOURS_IN_DATABASE_ACTION",
  UPDATE_RESTAURANT_DESIGN_SETTINGS_IN_DATABASE_ACTION = "UPDATE_RESTAURANT_DESIGN_SETTINGS_IN_DATABASE_ACTION",
  UPDATE_RESTAURANT_SOCIAL_MEDIA_LINKS_IN_DATABASE_ACTION = "UPDATE_RESTAURANT_SOCIAL_MEDIA_LINKS_IN_DATABASE_ACTION",
}

export interface SetRestaurantFromDatabaseAction {
  type: RESTAURANT_ACTION.SET_RESTAURANT_FROM_DATABASE_ACTION;
  response: RestaurantFragment;
}

export const setRestaurantFromDatabaseAction = (
  restaurant: RestaurantFragment,
) => {
  return async (dispatch: Dispatch<SetRestaurantFromDatabaseAction>) => {
    const action = {
      type: RESTAURANT_ACTION.SET_RESTAURANT_FROM_DATABASE_ACTION,
      response: {
        ...restaurant,
        // Resets the image url to force a reload
        logoUrl: `${restaurant.logoUrl}?${Date.now()}`,
        coverPhotoUrl: `${restaurant.coverPhotoUrl}?${Date.now()}`,
      },
    } as SetRestaurantFromDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateLocationHoursInDatabaseAction {
  type: RESTAURANT_ACTION.UPDATE_LOCATION_HOURS_IN_DATABASE_ACTION;
  response: LocationFragment;
}

export const updateLocationHoursInDatabaseAction = (
  locationId: string,
  hoursOfOperation: HoursOfOperationFragment,
) => {
  return async (dispatch: Dispatch<UpdateLocationHoursInDatabaseAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/restaurant/update_location_hours",
      params: {
        locationId,
        hoursOfOperation,
      },
    })) as LocationFragment;

    const action = {
      type: RESTAURANT_ACTION.UPDATE_LOCATION_HOURS_IN_DATABASE_ACTION,
      response,
    } as UpdateLocationHoursInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateHoursOfOperationModeInDatabaseAction {
  type: RESTAURANT_ACTION.UPDATE_HOURS_OF_OPERATION_MODE_IN_DATABASE_ACTION;
  response: LocationFragment;
}

export const updateHoursOfOperationModeInDatabaseAction = (
  locationId: string,
  modeSelected: HoursOfOperationMode,
  closedUntilX?: string,
) => {
  return async (
    dispatch: Dispatch<UpdateHoursOfOperationModeInDatabaseAction>,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/restaurant/update_hours_of_operation_mode",
      params: {
        locationId,
        modeSelected,
        closedUntilX,
      },
    })) as LocationFragment;

    const action = {
      type: RESTAURANT_ACTION.UPDATE_HOURS_OF_OPERATION_MODE_IN_DATABASE_ACTION,
      response,
    } as UpdateHoursOfOperationModeInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateRestaurantDesignSettingsInDatabaseAction {
  type: RESTAURANT_ACTION.UPDATE_RESTAURANT_DESIGN_SETTINGS_IN_DATABASE_ACTION;
  restaurantId: string;
  response: RestaurantDesignFragment;
}

export const updateRestaurantDesignSettingsInDatabaseAction = (
  restaurantId: string,
  restaurantDesignId: string,
  announcementBar: string,
) => {
  return async (
    dispatch: Dispatch<UpdateRestaurantDesignSettingsInDatabaseAction>,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/restaurant_design/update",
      params: {
        id: restaurantDesignId,
        announcementBar,
      },
    })) as RestaurantDesignFragment;

    const action = {
      type: RESTAURANT_ACTION.UPDATE_RESTAURANT_DESIGN_SETTINGS_IN_DATABASE_ACTION,
      restaurantId,
      response,
    } as UpdateRestaurantDesignSettingsInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateRestaurantSocialMediaLinksInDatabaseAction {
  type: RESTAURANT_ACTION.UPDATE_RESTAURANT_SOCIAL_MEDIA_LINKS_IN_DATABASE_ACTION;
  restaurantId: string;
  response: SocialMediaLinksFragment;
}

export const updateRestaurantSocialMediaLinksInDatabaseAction = (
  restaurantId: string,
  socialMediaLinksId: string,
  facebook: string | null,
  instagram: string | null,
  yelp: string | null,
  doordash: string | null,
  ubereats: string | null,
  grubhub: string | null,
  postmates: string | null,
) => {
  return async (
    dispatch: Dispatch<UpdateRestaurantSocialMediaLinksInDatabaseAction>,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/restaurant/update_social_media_links",
      params: {
        socialMediaLinksId,
        facebook,
        instagram,
        yelp,
        doordash,
        ubereats,
        grubhub,
        postmates,
      },
    })) as SocialMediaLinksFragment;

    const action = {
      type: RESTAURANT_ACTION.UPDATE_RESTAURANT_SOCIAL_MEDIA_LINKS_IN_DATABASE_ACTION,
      restaurantId,
      response,
    } as UpdateRestaurantSocialMediaLinksInDatabaseAction;

    dispatch(action);

    return action;
  };
};
