import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import styles from "src/pages/Settings/SettingsItem/styles.module.scss";

export interface SettingsItemProps {
  testId: string;
  icon: IconDefinition;
  title: string;
  subtitle: string;
  route: string;
}

export const SettingsItem = ({
  testId,
  icon,
  title,
  subtitle,
  route,
}: SettingsItemProps) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(route)}
      className={styles.SettingsItem}
      data-testid={testId}
    >
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      <div>
        <h4 className={styles.title}>{title}</h4>
        <h5 className={styles.subtitle}>{subtitle}</h5>
      </div>
    </div>
  );
};
