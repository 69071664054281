import {
  CATERING_ACTION,
  GetAllCateringRequestsForRestaurantAction,
  GetCateringRequestWithMessagesAction,
  UpdateCateringRequestStatusAction,
} from "src/state/catering/actions";
import { CateringState } from "src/state/catering/types";

type CateringAction =
  | GetAllCateringRequestsForRestaurantAction
  | GetCateringRequestWithMessagesAction
  | UpdateCateringRequestStatusAction;

export const cateringReducer = (
  state: CateringState = {},
  action: CateringAction,
) => {
  switch (action.type) {
    case CATERING_ACTION.GET_ALL_CATERING_REQUESTS_FOR_RESTAURANT_ACTION: {
      const { response: cateringRequests, restaurantId } = action;

      const cateringRequestsObject = cateringRequests.reduce(
        (acc, cateringRequest) => ({
          ...acc,
          [cateringRequest.id]: cateringRequest,
        }),
        {},
      );

      const next: CateringState = {
        ...state,
        [restaurantId]: cateringRequestsObject,
      };

      next[restaurantId] = cateringRequestsObject;

      return next;
    }
    default: {
      return state;
    }
    case CATERING_ACTION.UPDATE_CATERING_REQUEST_ACTION: {
      const { response: cateringRequest, restaurantId } = action;

      const next: CateringState = {
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          [cateringRequest.id]: cateringRequest,
        },
      };

      return next;
    }
    case CATERING_ACTION.GET_CATERING_REQUEST_WITH_MESSAGES_ACTION: {
      const { response: cateringRequest, restaurantId } = action;

      const next: CateringState = {
        [restaurantId]: {
          ...(state[restaurantId] || {}),
          [cateringRequest.id]: cateringRequest,
        },
      };

      return next;
    }
  }
};
