import { useMemo } from "react";
import styles from "src/pages/Home/ProfitChart/styles.module.scss";
import { RestaurantAnalyticsFragment } from "src/state/restaurantAnalytics/types";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { useMediaQuery } from "react-responsive";
import { formatNumber } from "src/common/number";

interface ComponentProps {
  isTodayMode: boolean;
  analyticsData: RestaurantAnalyticsFragment | undefined;
}

export const ProfitChart = ({ isTodayMode, analyticsData }: ComponentProps) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const groupProfitDataByDay = (
    data: {
      restaurantProfit: number;
      createdAt: string;
    }[],
  ): {
    Profit: number;
    createdAt: Date;
  }[] => {
    const groupedObject = data.reduce((acc, analytics) => {
      const date = new Date(analytics.createdAt);
      const dateString = date.toISOString().split("T")[0];

      if (!acc[dateString]) {
        acc[dateString] = {
          Profit: 0,
          createdAt: date,
        };
      }

      acc[dateString].Profit += analytics.restaurantProfit;

      return acc;
    }, {});

    return Object.values(groupedObject);
  };

  const profitData = useMemo(() => {
    if (!analyticsData) {
      return [];
    }

    if (isTodayMode) {
      return analyticsData.profitDataPoints.map((analytics) => ({
        Profit: analytics.restaurantProfit,
        createdAt: new Date(analytics.createdAt),
      }));
    }

    return groupProfitDataByDay(analyticsData.profitDataPoints);
  }, [analyticsData]);

  const chartMargin = useMemo(() => {
    if (isDesktop) {
      return { top: 5, right: 30, left: 20, bottom: 5 };
    }

    return { top: 5, right: 5, left: -10, bottom: 5 };
  }, [isDesktop]);

  return (
    <div className={styles.ProfitChart}>
      <h4 className={styles.title}>Profit Over Time</h4>
      {analyticsData ? (
        <div
          data-testid="home-money-chart"
          style={{ width: "100%", height: "100%" }}
        >
          <ResponsiveContainer width="100%" height={"100%"}>
            <AreaChart
              width={730}
              height={250}
              data={
                profitData.length > 0
                  ? profitData
                  : [{ Profit: 0, createdAt: new Date() }]
              }
              margin={chartMargin}
            >
              <XAxis
                dataKey="createdAt"
                type="category"
                tickFormatter={(value: Date) => {
                  if (isTodayMode) {
                    return value.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    });
                  } else {
                    return value.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                  }
                }}
                tickMargin={5}
                minTickGap={15}
              />
              <YAxis
                tickFormatter={(value: number) => {
                  return `$${value.toFixed(0)}`;
                }}
              />
              <Tooltip
                formatter={(value: number) => {
                  return `${formatNumber(value)}`;
                }}
                labelFormatter={(value: Date) => {
                  if (isTodayMode) {
                    return value.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    });
                  } else {
                    return value.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                  }
                }}
                separator={": "}
              />
              <Area
                dataKey="Profit"
                stroke={palette.blue}
                fill={palette.blue}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className={styles.loadingContainer}>
          <ReactLoading
            type="spin"
            color={palette.blue}
            height={40}
            width={40}
          />
        </div>
      )}
    </div>
  );
};
