export interface CateringState {
  [restaurantId: string]: {
    [cateringRequestId: string]: CateringRequestFragment;
  };
}

export interface CateringRequestFragment {
  id: string;
  restaurantId: string;
  cateringNumber: number;
  customerName: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: string;
  estimatedNumberOfGuests: number;
  estimatedBudget: number;
  eventDate: string;
  eventLocation: string;
  zipCode: string;
  notes: string;
  status: CATERING_REQUEST_STATUS;
  cateringMessages: CateringMessageFragment[];
  cateringInvoices: CateringInvoiceFragment[];
  createdAt: string;
}

export interface CateringMessageFragment {
  id: string;
  cateringRequestId: string;
  message?: string;
  attachmentUrl?: string;
  isRead: boolean;
  sender: CATERING_MESSAGE_SENDER;
  createdAt: string;
}

export enum CATERING_MESSAGE_SENDER {
  CUSTOMER = "CUSTOMER",
  RESTAURANT = "RESTAURANT",
}

export enum CATERING_REQUEST_STATUS {
  OPEN = "OPEN",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
}

export const CATERING_REQUEST_STATUS_DISPLAY = {
  [CATERING_REQUEST_STATUS.OPEN]: "Open",
  [CATERING_REQUEST_STATUS.COMPLETED]: "Completed",
  [CATERING_REQUEST_STATUS.DECLINED]: "Declined",
};

export interface CateringInvoiceFragment {
  id: string;
  cateringRequestId: string;
  cateringReceiptId?: string;
  subtotalInDollars: number;
  tipInDollars: number;
  lineItems: LineItem[];
  isPaid: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface LineItem {
  title: string;
  amountInDollars: number;
}
