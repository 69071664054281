// Actions associated with options
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { OptionFragment } from "src/state/option/types";

export enum OPTION_ACTION {
  GET_OPTIONS_FOR_RESTAURANT_ACTION = "GET_OPTIONS_FOR_RESTAURANT_ACTION",
  UPDATE_OPTION_PUBLISHED_STATUS_IN_DATABASE_ACTION = "UPDATE_OPTION_PUBLISHED_STATUS_IN_DATABASE_ACTION",
  UPDATE_OPTION_VALUE_PUBLISHED_STATUS_IN_DATABASE_ACTION = "UPDATE_OPTION_VALUE_PUBLISHED_STATUS_IN_DATABASE_ACTION",
}

export interface GetOptionsForRestaurantAction {
  type: OPTION_ACTION.GET_OPTIONS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  options: OptionFragment[];
}

export const getOptionsForRestaurantAction = (restaurantId: string) => {
  return async (dispatch: Dispatch<GetOptionsForRestaurantAction>) => {
    const options = (await post({
      service: "thanos",
      endpoint: "/option/list",
      params: {
        restaurantId,
      },
    })) as OptionFragment[];

    const action = {
      type: OPTION_ACTION.GET_OPTIONS_FOR_RESTAURANT_ACTION,
      restaurantId,
      options,
    } as GetOptionsForRestaurantAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateOptionPublishedStatusInDatabaseAction {
  type: OPTION_ACTION.UPDATE_OPTION_PUBLISHED_STATUS_IN_DATABASE_ACTION;
  response: OptionFragment;
}

export const updateOptionPublishedStatusInDatabaseAction = (
  optionId: string,
  isPublished: boolean,
) => {
  return async (
    dispatch: Dispatch<UpdateOptionPublishedStatusInDatabaseAction>,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/option/update_option_published_status",
      params: {
        id: optionId,
        isPublished,
      },
    })) as OptionFragment;

    const action = {
      type: OPTION_ACTION.UPDATE_OPTION_PUBLISHED_STATUS_IN_DATABASE_ACTION,
      response,
    } as UpdateOptionPublishedStatusInDatabaseAction;

    dispatch(action);

    return action;
  };
};
