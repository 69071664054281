// Actions associated with order
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { ORDER_STATUS, OrderFragment } from "src/common/types/Order";

export enum ORDER_ACTION {
  GET_ALL_ORDERS_FOR_RESTAURANT_ACTION = "GET_ALL_ORDERS_FOR_RESTAURANT_ACTION",
  GET_SPECIFIC_ORDER_ACTION = "GET_SPECIFIC_ORDER_ACTION",
  UPDATE_ORDER_STATUS_ACTION = "UPDATE_ORDER_STATUS_ACTION",
  REFUND_ORDER_ITEM_ACTION = "REFUND_ORDER_ITEM_ACTION",
}

export interface GetAllOrdersForRestaurantAction {
  type: ORDER_ACTION.GET_ALL_ORDERS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: OrderFragment[];
}

export const getAllOrdersForRestaurantAction = (
  restaurantId: string,
  limit: number,
  offset: number,
) => {
  return async (dispatch: Dispatch<GetAllOrdersForRestaurantAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/order/list",
      params: {
        restaurantId,
        limit,
        offset,
      },
    })) as OrderFragment[];

    const orderWithSortedItems = response.map((order) => {
      return {
        ...order,
        orderItems: order.orderItems.sort((a, b) => {
          return a.itemName.localeCompare(b.itemName);
        }),
      };
    });

    const action = {
      type: ORDER_ACTION.GET_ALL_ORDERS_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: orderWithSortedItems,
    } as GetAllOrdersForRestaurantAction;

    dispatch(action);

    return action;
  };
};

export interface GetSpecificOrderAction {
  type: ORDER_ACTION.GET_SPECIFIC_ORDER_ACTION;
  restaurantId: string;
  response: OrderFragment;
}

export const getSpecificOrderAction = (
  restaurantId: string,
  orderId: string,
) => {
  return async (dispatch: Dispatch<GetSpecificOrderAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/order/get",
      params: {
        orderId,
        getCustomer: true,
      },
    })) as OrderFragment;

    const action = {
      type: ORDER_ACTION.GET_SPECIFIC_ORDER_ACTION,
      restaurantId,
      response,
    } as GetSpecificOrderAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateOrderStatusAction {
  type: ORDER_ACTION.UPDATE_ORDER_STATUS_ACTION;
  restaurantId: string;
  response: OrderFragment;
  cancellationReason?: string;
}

export const updateOrderStatusAction = (
  restaurantId: string,
  orderId: string,
  status: ORDER_STATUS,
  cancellationReason?: string,
) => {
  return async (dispatch: Dispatch<UpdateOrderStatusAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/order/update_status",
      params: {
        orderId,
        status,
        cancellationReason,
      },
    })) as OrderFragment;

    const action = {
      type: ORDER_ACTION.UPDATE_ORDER_STATUS_ACTION,
      restaurantId,
      response,
      cancellationReason,
    } as UpdateOrderStatusAction;

    dispatch(action);

    return action;
  };
};

export interface RefundOrderItemAction {
  type: ORDER_ACTION.REFUND_ORDER_ITEM_ACTION;
  restaurantId: string;
  response: OrderFragment;
}

export const refundOrderItemAction = (
  restaurantId: string,
  locationId: string,
  orderId: string,
  orderItemId: string,
) => {
  return async (dispatch: Dispatch<RefundOrderItemAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/order/refund_order_item",
      params: {
        restaurantId,
        locationId,
        orderId,
        orderItemId,
      },
    })) as OrderFragment;

    const action = {
      type: ORDER_ACTION.REFUND_ORDER_ITEM_ACTION,
      restaurantId,
      response,
    } as RefundOrderItemAction;

    dispatch(action);

    return action;
  };
};
