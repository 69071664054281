// Actions associated with items
import { Dispatch } from "redux";
import { post } from "src/api/server";
import {
  deleteItemImageFromS3,
  getItemImageURLFromItemId,
  uploadItemImageBase64ToS3,
} from "src/common/s3";
import { ItemFragment } from "src/state/item/types";
import { State } from "src/state/state";
import {
  DEAL_ACTION,
  DeleteDealFromDatabaseAction,
  UpdateDealPublishStatusAction,
} from "src/state/deal/actions";

export enum ITEM_ACTION {
  UPDATE_ITEM_IN_DATABASE_ACTION = "UPDATE_ITEM_IN_DATABASE_ACTION",
  UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION = "UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION",
  GET_ALL_ITEMS_FOR_RESTAUARNT_FROM_DATABASE_ACTION = "GET_ALL_ITEMS_FOR_RESTAUARNT_FROM_DATABASE_ACTION",
  DELETE_ITEM_FROM_DATABASE_ACTION = "DELETE_ITEM_FROM_DATABASE_ACTION",
  CREATE_ITEM_IN_DATABASE_ACTION = "CREATE_ITEM_IN_DATABASE_ACTION",
}

export interface CreateItemInDatabaseAction {
  type: ITEM_ACTION.CREATE_ITEM_IN_DATABASE_ACTION;
  response: ItemFragment;
}

export interface UpdateItemInDatabaseAction {
  type: ITEM_ACTION.UPDATE_ITEM_IN_DATABASE_ACTION;
  response: ItemFragment;
}

export const createItemInDatabaseAction = (
  restaurantId: string,
  name: string,
  description: string,
  isCateringItem: boolean,
  price: number | undefined,
  categoryId: string,
  itemPhotoAsBase64: string | undefined,
) => {
  return async (dispatch: Dispatch<CreateItemInDatabaseAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/item/create",
      params: {
        restaurantId,
        name,
        description,
        isCateringItem,
        price,
        categoryId,
        isPublished: true,
        hasImage: itemPhotoAsBase64 ? true : false,
      },
    })) as ItemFragment;
    if (itemPhotoAsBase64) {
      await uploadItemImageBase64ToS3(itemPhotoAsBase64, response.id);
    }

    const action = {
      type: ITEM_ACTION.CREATE_ITEM_IN_DATABASE_ACTION,
      response: {
        ...response,
        imageURL: itemPhotoAsBase64
          ? getItemImageURLFromItemId(response.id)
          : undefined,
      },
    } as CreateItemInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export const updateItemInDatabaseAction = (
  itemId: string,
  name: string,
  description: string,
  hadPreviousImage: boolean,
  isCateringItem: boolean,
  price: number | undefined,
  itemPhotoAsBase64: string | undefined,
) => {
  return async (dispatch: Dispatch<UpdateItemInDatabaseAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/item/update",
      params: {
        id: itemId,
        name,
        description,
        isCateringItem,
        price,
        hasImage: itemPhotoAsBase64 ? true : false,
      },
    })) as ItemFragment;

    if (itemPhotoAsBase64) {
      await uploadItemImageBase64ToS3(itemPhotoAsBase64, response.id);
    } else if (hadPreviousImage) {
      await deleteItemImageFromS3(itemId);
    }

    const action = {
      type: ITEM_ACTION.UPDATE_ITEM_IN_DATABASE_ACTION,
      response: {
        ...response,
        imageURL: itemPhotoAsBase64
          ? getItemImageURLFromItemId(response.id)
          : undefined,
      },
    } as UpdateItemInDatabaseAction;

    dispatch(action);

    return action;
  };
};
export interface UpdatePublishedStatusForMultipleItemsInDatabaseAction {
  type: ITEM_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION;
  response: {
    restaurantId: string;
    itemIds: string[];
    isPublished: boolean;
  };
}

export const updatePublishedStatusForMultipleItemsInDatabaseAction = (
  itemIds: string[],
  restaurantId: string,
  isPublished: boolean,
) => {
  return async (
    dispatch: Dispatch<
      | UpdatePublishedStatusForMultipleItemsInDatabaseAction
      | UpdateDealPublishStatusAction
    >,
    getState: () => State,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/item/update_published_status",
      params: {
        ids: itemIds,
        isPublished,
      },
    })) as ItemFragment[];

    const currentReduxState = getState();

    if (!isPublished) {
      for (const item of response) {
        const dealsForThisItem = Object.values(
          currentReduxState.deals[item.restaurantId],
        ).filter((eachDeal) => eachDeal.entityIds.includes(item.id));

        for (const eachDeal of dealsForThisItem) {
          const action = {
            type: DEAL_ACTION.UPDATE_DEAL_PUBLISHED_STATUS_ACTION,
            restaurantId: item.restaurantId,
            response: {
              ...eachDeal,
              isPublished: false,
            },
          } as UpdateDealPublishStatusAction;

          dispatch(action);
        }
      }
    }

    const action = {
      type: ITEM_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION,
      response: {
        restaurantId: restaurantId,
        itemIds: response.map((item) => item.id),
        isPublished: isPublished,
      },
    } as UpdatePublishedStatusForMultipleItemsInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface GetAllItemsForRestaurantFromDatabaseAction {
  type: ITEM_ACTION.GET_ALL_ITEMS_FOR_RESTAUARNT_FROM_DATABASE_ACTION;
  restaurantId: string;
  response: ItemFragment[];
}

export const getAllItemsForRestaurantFromDatabaseAction = (
  restaurantId: string,
) => {
  return async (
    dispatch: Dispatch<GetAllItemsForRestaurantFromDatabaseAction>,
  ) => {
    const items = (await post({
      service: "thanos",
      endpoint: "/item/list",
      params: {
        restaurantId,
      },
    })) as ItemFragment[];

    const action = {
      type: ITEM_ACTION.GET_ALL_ITEMS_FOR_RESTAUARNT_FROM_DATABASE_ACTION,
      restaurantId,
      response: items.map((item) => ({
        ...item,
        imageURL: item.hasImage
          ? getItemImageURLFromItemId(item.id)
          : undefined,
      })),
    } as GetAllItemsForRestaurantFromDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface DeleteItemFromDatabaseAction {
  type: ITEM_ACTION.DELETE_ITEM_FROM_DATABASE_ACTION;
  restaurantId: string;
  itemId: string;
}

export const deleteItemFromDatabaseAction = (
  restaurantId: string,
  itemId: string,
) => {
  return async (
    dispatch: Dispatch<
      DeleteItemFromDatabaseAction | DeleteDealFromDatabaseAction
    >,
    getState: () => State,
  ) => {
    await post({
      service: "thanos",
      endpoint: "/item/delete",
      params: {
        id: itemId,
      },
    });

    const currentReduxState = getState();

    const dealsForThisItem = Object.values(
      currentReduxState.deals[restaurantId],
    ).filter((eachDeal) => eachDeal.entityIds.includes(itemId));

    for (const eachDeal of dealsForThisItem) {
      const action = {
        type: DEAL_ACTION.DELETE_DEAL_FROM_DATABASE_ACTION,
        restaurantId,
        response: {
          dealId: eachDeal.id,
        },
      } as DeleteDealFromDatabaseAction;

      dispatch(action);
    }

    const action = {
      type: ITEM_ACTION.DELETE_ITEM_FROM_DATABASE_ACTION,
      restaurantId,
      itemId,
    } as DeleteItemFromDatabaseAction;

    dispatch(action);

    return action;
  };
};
