// Types for deal state state
import { DealScheduleFragment } from "src/common/types/DealSchedule";

export enum DEAL_TYPE {
  AMOUNT_OFF = "AMOUNT_OFF",
  BOGO = "BOGO",
  SET_PRICE = "SET_PRICE",
  COMBO = "COMBO",
}

export enum DEAL_VALUE_TYPE {
  PERCENT = "PERCENT",
  DOLLAR = "DOLLAR",
}

export enum DEAL_ENTITY_TYPE {
  ITEM = "ITEM",
  CATEGORY = "CATEGORY",
}
export interface DealFragment {
  id: string;
  restaurantId: string;
  name: string;
  description: string;
  type: DEAL_TYPE;
  imageUrl: string;
  entityIds: string[];
  isPublished: boolean;
  entityTypes: DEAL_ENTITY_TYPE[];
  dealValueType: DEAL_VALUE_TYPE;
  dealValue: number;
  isDeleted: boolean;
  dealSchedule: DealScheduleFragment;
}

export interface DealState {
  [restaurantId: string]: {
    [dealId: string]: DealFragment;
  };
}
