import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, PageTitle } from "src/components";
import styles from "src/pages/Home/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { DateRangePicker } from "src/components/DateRangePicker/DateRangePicker";
import { getRestaurantAnalyticsForDateRangeAction } from "src/state/restaurantAnalytics/actions";
import { ProfitChart } from "src/pages/Home/ProfitChart/ProfitChart";
import { MoneyTable } from "src/pages/Home/MoneyTable/MoneyTable";
import { StatCard } from "src/pages/Home/StatCard/StatCard";
import { formatNumber } from "src/common/number";
import { useMediaQuery } from "react-responsive";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportModal } from "src/pages/Home/ExportModal/ExportModal";

export const Home = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 875px)" });
  const dispatch = useDispatch();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const restaurantAnalytics = useSelector(
    (state: State) => state.restaurantAnalytics,
  );

  const todayDate = useMemo(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return now;
  }, []);

  const oneMonthAgoDate = useMemo(() => {
    const date = new Date();
    date.setMonth(todayDate.getMonth() - 1);
    date.setHours(0, 0, 0, 0);

    return date;
  }, [todayDate]);

  const loadNewAnalytics = useCallback(
    async (startDate: Date, endDate: Date) => {
      const pstStartDate = new Date(startDate);
      const pstEndDate = new Date(endDate);

      pstStartDate.setHours(7, 0, 0, 0);
      pstEndDate.setDate(endDate.getDate() + 1);
      pstEndDate.setHours(6, 59, 59);

      const key = `${pstStartDate}-${pstEndDate}`;

      if (!restaurantAnalytics[key]) {
        await getRestaurantAnalyticsForDateRangeAction(
          restaurant.id,
          pstStartDate.toISOString(),
          pstEndDate.toISOString(),
        )(dispatch);
      }
    },
    [restaurant, restaurantAnalytics, dispatch],
  );

  const [startDate, setStartDate] = useState(oneMonthAgoDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const selectedRestaurantAnalytics = useMemo(() => {
    const pstStartDate = new Date(startDate);
    const pstEndDate = new Date(endDate);

    pstStartDate.setHours(7, 0, 0, 0);
    pstEndDate.setDate(endDate.getDate() + 1);
    pstEndDate.setHours(6, 59, 59);

    return restaurantAnalytics[
      `${pstStartDate.toISOString()}-${pstEndDate.toISOString()}`
    ];
  }, [restaurantAnalytics, startDate, endDate]);

  const statCards = useMemo(() => {
    return [
      {
        title: "Total Orders",
        stat: `${selectedRestaurantAnalytics?.numOrders || 0}`,
        statTestId: "home-total-orders",
      },
      {
        title: "Total Delivery Orders",
        stat: `${selectedRestaurantAnalytics?.numDeliveryOrders || 0}`,
        statTestId: "home-total-delivery-orders",
      },
      {
        title: "Total Pickup Orders",
        stat: `${selectedRestaurantAnalytics?.numPickupOrders || 0}`,
        statTestId: "home-total-pickup-orders",
      },
      {
        title: "Average Order Size",
        stat: `${selectedRestaurantAnalytics ? formatNumber(selectedRestaurantAnalytics.avgOrderSize) : 0}`,
        statTestId: "home-average-order-size",
      },
      {
        title: "Total Website Visits",
        stat: `${selectedRestaurantAnalytics?.totalWebsiteVisits || 0}`,
        statTestId: "home-total-website-visits",
      },
      {
        title: "Customers Who Placed Order",
        stat: `${selectedRestaurantAnalytics?.numCustomersWhoPlacedOrder || 0}`,
        statTestId: "home-customers-who-placed-order",
      },
    ];
  }, [selectedRestaurantAnalytics]);

  useEffect(() => {
    loadNewAnalytics(startDate, endDate);
  }, []);

  return (
    <div className={styles.Home} data-testid="home-container">
      <PageTitle
        title="Home"
        subtitle={`Here's how ${restaurant.restaurantName} is doing.`}
      />
      <div className={styles.dateRangeExportRow}>
        <DateRangePicker
          className={styles.dateRangePicker}
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={async (startDate, endDate) => {
            setStartDate(startDate);
            setEndDate(endDate);

            await loadNewAnalytics(startDate, endDate);
          }}
        />
        {isDesktop ? (
          <Button
            testId="home-export-button"
            text="Export"
            onClick={() => {
              setIsExportModalVisible(true);
            }}
            className={styles.exportButton}
          />
        ) : (
          <div
            className={styles.exportCircle}
            onClick={() => {
              setIsExportModalVisible(true);
            }}
          >
            <FontAwesomeIcon
              className={styles.exportIcon}
              icon={faArrowUpFromBracket}
            />
          </div>
        )}
      </div>
      <ProfitChart
        isTodayMode={startDate.toDateString() === endDate.toDateString()}
        analyticsData={selectedRestaurantAnalytics}
      />
      <MoneyTable analyticsData={selectedRestaurantAnalytics} />
      <div className={styles.statCardsContainer}>
        {statCards.map((statCard, index) => (
          <StatCard
            key={index}
            className={styles.statCard}
            isLoading={!selectedRestaurantAnalytics}
            title={statCard.title}
            stat={statCard.stat}
            statTestId={statCard.statTestId}
          />
        ))}
      </div>
      <ExportModal
        beginDate={startDate}
        endDate={endDate}
        isVisible={isExportModalVisible}
        onClose={() => {
          setIsExportModalVisible(false);
        }}
      />
    </div>
  );
};
