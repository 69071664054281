// Restaurant reducer
import {
  RESTAURANT_ACTION,
  SetRestaurantFromDatabaseAction,
  UpdateHoursOfOperationModeInDatabaseAction,
  UpdateLocationHoursInDatabaseAction,
  UpdateRestaurantDesignSettingsInDatabaseAction,
  UpdateRestaurantSocialMediaLinksInDatabaseAction,
} from "src/state/restaurant/actions";
import { RestaurantState } from "src/state/restaurant/types";

type RestaurantAction =
  | SetRestaurantFromDatabaseAction
  | UpdateHoursOfOperationModeInDatabaseAction
  | UpdateLocationHoursInDatabaseAction
  | UpdateRestaurantDesignSettingsInDatabaseAction
  | UpdateRestaurantSocialMediaLinksInDatabaseAction;

export const restaurantReducer = (
  state: RestaurantState = {},
  action: RestaurantAction,
) => {
  switch (action.type) {
    case RESTAURANT_ACTION.SET_RESTAURANT_FROM_DATABASE_ACTION: {
      const next: RestaurantState = { ...state };

      const { response: restaurant } = action;

      next.currentRestaurant = restaurant;

      return next;
    }
    case RESTAURANT_ACTION.UPDATE_LOCATION_HOURS_IN_DATABASE_ACTION: {
      const next: RestaurantState = { ...state };

      const { response: location } = action;

      if (!next.currentRestaurant) {
        return state;
      }

      const currentLocation = next.currentRestaurant.locations.find(
        (l) => l.id === location.id,
      );

      if (!currentLocation) {
        return state;
      }

      const locationIndex =
        next.currentRestaurant.locations.indexOf(currentLocation);

      next.currentRestaurant.locations[locationIndex] = location;

      return next;
    }
    case RESTAURANT_ACTION.UPDATE_HOURS_OF_OPERATION_MODE_IN_DATABASE_ACTION: {
      const next: RestaurantState = { ...state };

      const { response: location } = action;

      if (!next.currentRestaurant) {
        return state;
      }

      const currentLocation = next.currentRestaurant.locations.find(
        (l) => l.id === location.id,
      );

      if (!currentLocation) {
        return state;
      }

      const locationIndex =
        next.currentRestaurant.locations.indexOf(currentLocation);

      next.currentRestaurant.locations[locationIndex] = location;

      return next;
    }
    case RESTAURANT_ACTION.UPDATE_RESTAURANT_DESIGN_SETTINGS_IN_DATABASE_ACTION: {
      const next: RestaurantState = { ...state };

      if (!next.currentRestaurant) {
        return state;
      }

      const { response: restaurantDesign } = action;

      next.currentRestaurant.restaurantDesign = restaurantDesign;

      return next;
    }
    case RESTAURANT_ACTION.UPDATE_RESTAURANT_SOCIAL_MEDIA_LINKS_IN_DATABASE_ACTION: {
      const next: RestaurantState = { ...state };

      if (!next.currentRestaurant) {
        return state;
      }

      const { response: socialMediaLinks } = action;

      next.currentRestaurant.socialMediaLinks = socialMediaLinks;

      return next;
    }
    default: {
      return state;
    }
  }
};
