// Category reducer
import {
  CATEGORY_ACTION,
  GetCategoriesForRestaurantAction,
  UpdatePublishedStatusForMultipleCategoriesInDatabaseAction,
} from "src/state/category/actions";
import { CategoryState } from "src/state/category/types";

type CategoryAction =
  | UpdatePublishedStatusForMultipleCategoriesInDatabaseAction
  | GetCategoriesForRestaurantAction;

export const categoryReducer = (
  state: CategoryState = {},
  action: CategoryAction,
) => {
  switch (action.type) {
    case CATEGORY_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION: {
      const { response: categories, restaurantId } = action;

      const next: CategoryState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      categories.forEach((category) => {
        next[restaurantId][category.id] = category;
      });

      return next;
    }
    case CATEGORY_ACTION.GET_CATEGORIES_FOR_RESTAURANT_ACTION: {
      const { response: categories, restaurantId } = action;

      const next: CategoryState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      categories.forEach((category) => {
        next[restaurantId][category.id] = category;
      });

      return next;
    }
    default: {
      return state;
    }
  }
};
