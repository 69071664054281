// Types for exports

export interface ExportFragment {
  id: string;
  restaurantId: string;
  type: EXPORT_TYPE;
  url: string;
}

export enum EXPORT_TYPE {
  ORDERS_SUMMARY = "ORDERS_SUMMARY",
  ITEMIZED_ORDERS = "ITEMIZED_ORDERS",
  MONTHLY_STATEMENT = "MONTHLY_STATEMENT",
}
