import { faEnvelope, faComment } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "src/common/phone";
import { GuestCustomerFragment } from "src/common/types/Order";
import styles from "src/pages/Order/CustomerDetails/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";

interface ComponentProps {
  orderId: string;
}

export const CustomerDetails = ({ orderId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const order = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      state.orders[restaurant.id][orderId],
  );

  const customerName = useMemo(() => {
    let customerName = "";

    if (order.customer) {
      customerName = `${order.customer.firstName} ${order.customer.lastName}`;
    } else if (order.guestCustomer) {
      customerName = order.guestCustomer.name;
    }

    return customerName;
  }, [order]);

  const formattedPhoneNumber = useMemo(() => {
    if (order.customer) {
      return formatPhoneNumber(order.customer.phone);
    } else {
      const guestCustomer = order.guestCustomer as GuestCustomerFragment;

      return formatPhoneNumber(guestCustomer.phone);
    }
  }, [order]);

  return (
    <div className={styles.CustomerDetails}>
      <h3 className={styles.title}>Customer</h3>
      <p
        className={styles.customerName}
        data-testid="order-screen-customer-name"
      >
        {customerName}
      </p>
      {order.customer && (
        <p
          className={styles.customerSubInfo}
          data-testid="order-screen-customer-email"
        >
          {order.customer.email}
        </p>
      )}
      <p
        className={styles.customerSubInfo}
        data-testid="order-screen-customer-phone"
      >
        {formattedPhoneNumber}
      </p>
      <div className={styles.actionsContainer}>
        {order.customer && (
          <div
            data-testid="order-screen-email-customer-button"
            className={styles.actionContainer}
            onClick={() => {
              if (order.customer) {
                window.open(`mailto:${order.customer.email}`, "_blank");
              }
            }}
          >
            <FontAwesomeIcon className={styles.actionIcon} icon={faEnvelope} />
          </div>
        )}
        <div
          data-testid="order-screen-call-customer-button"
          className={classNames(styles.actionContainer, {
            [styles.noCustomer]: !order.customer,
          })}
          onClick={() => {
            window.open(`tel:${formattedPhoneNumber}`, "_blank");
          }}
        >
          <FontAwesomeIcon className={styles.actionIcon} icon={faPhone} />
        </div>
        <div
          data-testid="order-screen-sms-customer-button"
          className={classNames(styles.actionContainer, {
            [styles.noCustomer]: !order.customer,
          })}
          onClick={() => {
            window.open(`sms:${formattedPhoneNumber}`, "_blank");
          }}
        >
          <FontAwesomeIcon className={styles.actionIcon} icon={faComment} />
        </div>
      </div>
    </div>
  );
};
