// Types related to hours of operation
import { DayOfTheWeekHours } from "src/common/types/DayOfTheWeekHours";

// Determines current mode of hours of operation
export enum HoursOfOperationMode {
  AUTOMATIC = "AUTOMATIC",
  MANUALLY_CLOSED = "MANUALLY_CLOSED",
  CLOSED_UNTIL_X = "CLOSED_UNTIL_X",
}

// Type used in HoursOfOperation to represent a day of the week's hours

// This type is used to represent the HoursOfOperation entity without created, etc.
export type HoursOfOperationFragment = {
  sunday: DayOfTheWeekHours[] | null;
  monday: DayOfTheWeekHours[] | null;
  tuesday: DayOfTheWeekHours[] | null;
  wednesday: DayOfTheWeekHours[] | null;
  thursday: DayOfTheWeekHours[] | null;
  friday: DayOfTheWeekHours[] | null;
  saturday: DayOfTheWeekHours[] | null;
};

export type HoursOfOperationFragmentWithMode = HoursOfOperationFragment & {
  modeSelected: HoursOfOperationMode;
  closedUntilX?: string;
};
