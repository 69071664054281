import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextInput } from "src/components";
import { PhotoChooser } from "src/components/PhotoChooser/PhotoChooser";
import styles from "src/components/CreateItemModal/styles.module.scss";
import { State } from "src/state/state";
import { createItemInDatabaseAction } from "src/state/item/actions";
import { showToast } from "src/common/toast";
import Modal from "react-awesome-modal";
import ReactDropdown from "react-dropdown";
import { Checkbox } from "@mui/material";
import { getCurrentEnvironment } from "src/config/getConfig";

interface CreateItemModalProps {
  isVisible: boolean;
  restaurantId: string;
  onClose: () => void;
  onComplete: () => void;
}

export const CreateItemModal = ({
  isVisible,
  restaurantId,
  onClose,
  onComplete,
}: CreateItemModalProps) => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: State) => state.categories[restaurantId],
  );

  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [categoryIdSelected, setCategoryIdSelected] = useState<
    string | undefined
  >();
  const [isCateringItem, setIsCateringItem] = useState(false);
  const [photoSelected, setPhotoSelected] = useState<string | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const resetForm = () => {
    setItemName("");
    setItemDescription("");
    setItemPrice("");
    if (getCurrentEnvironment() === "test") {
      setCategoryIdSelected(Object.values(categories)[0].id);
    } else {
      setCategoryIdSelected(undefined);
    }
    setIsCateringItem(false);
    setPhotoSelected(undefined);
    setError(undefined);
  };

  const validateInputs = useCallback((): boolean => {
    if (!itemName.trim()) {
      setError("Please enter an item name.");
      return false;
    }
    if (!itemDescription.trim()) {
      setError("Please enter an item description.");
      return false;
    }
    if (!categoryIdSelected) {
      setError("Please select a category.");
      return false;
    }
    if (
      !isCateringItem &&
      (!itemPrice.trim() || isNaN(Number(itemPrice)) || Number(itemPrice) <= 0)
    ) {
      setError("Please enter a valid price.");
      return false;
    }
    return true;
  }, [
    itemName,
    itemDescription,
    categoryIdSelected,
    itemPrice,
    isCateringItem,
  ]);

  const handleSubmit = async () => {
    if (!validateInputs()) return;
    setIsLoading(true);

    await createItemInDatabaseAction(
      restaurantId,
      itemName,
      itemDescription,
      isCateringItem,
      itemPrice ? Number(itemPrice) : undefined,
      categoryIdSelected ?? "",
      photoSelected,
    )(dispatch);

    showToast("Item created successfully.");
    setIsLoading(false);
    onComplete();
    onClose();
  };

  return (
    <Modal
      visible={isVisible}
      width="500"
      height="500"
      effect="fadeInUp"
      onClickAway={onClose}
    >
      <div className={styles.createItemModal} data-testid="create-item-modal">
        <h2 className={styles.modalHeader}>Create Item</h2>

        <div className={styles.scrollableContent}>
          <div className={styles.photoChooserContainer}>
            <PhotoChooser
              photo={photoSelected}
              onPhotoChange={(photo) => setPhotoSelected(photo)}
            />
          </div>
          <div className={styles.formGroup}>
            <TextInput
              label="Name"
              value={itemName}
              onChangeText={setItemName}
              placeholder="Enter item name..."
              className={styles.textInput}
              data-testid="create-item-name-input"
            />
          </div>
          <div className={styles.formGroup}>
            <TextInput
              label="Description"
              value={itemDescription}
              onChangeText={setItemDescription}
              placeholder="Enter item description..."
              multiline
              className={styles.textInput}
              data-testid="create-item-description-input"
            />
          </div>
          <div
            className={styles.formGroup}
            data-testid={"create-item-category-dropdown-container"}
          >
            <span className={styles.label}>Category</span>
            <ReactDropdown
              value={categoryIdSelected}
              options={Object.values(categories).map((category) => ({
                label: category.name,
                value: category.id,
              }))}
              onChange={(option) =>
                setCategoryIdSelected(option.value as string)
              }
              className={styles.dropdown}
            />
          </div>
          <div className={styles.formGroup}>
            <TextInput
              label="Price"
              value={itemPrice}
              onChangeText={setItemPrice}
              placeholder="Enter price..."
              className={styles.priceInput}
              textPrefix="$"
              data-testid="create-item-price-input"
            />
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              checked={isCateringItem}
              onChange={() => setIsCateringItem(!isCateringItem)}
              data-testid="create-item-catering-checkbox"
            />
            <span className={styles.checkboxLabel}>Catering Item</span>
          </div>
          {error && <div className={styles.errorMessage}>{error}</div>}
          <div className={styles.buttonsContainer}>
            <Button
              text="Cancel"
              onClick={onClose}
              error={true}
              data-testid="create-item-cancel-button"
            />
            <Button
              text={isLoading ? "Creating..." : "Save Item"}
              onClick={handleSubmit}
              disabled={isLoading}
              data-testid="create-item-save-button"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
