import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBank,
  faBookOpen,
  faGear,
  faHouse,
  faReceipt,
  faRightFromBracket,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getCateringRoute,
  getFinancialsRoute,
  getHomeRoute,
  getLoginPath,
  getMenuRoute,
  getOrdersRoute,
  getSettingsRoute,
} from "src/Router/routes";
import { logSignOutToAnalytics } from "src/common/analytics";
import { signOutUser } from "src/common/auth";
import styles from "src/components/SideMenu/styles.module.scss";
import { signOutUserAction } from "src/state/auth/actions";
import { State } from "src/state/state";
import { RestaurantFragment } from "src/state/restaurant/types";

export const SideMenu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const SideMenuItem = ({
    title,
    icon,
    route,
    testId,
  }: {
    title: string;
    icon: IconDefinition;
    route: string;
    testId: string;
  }) => (
    <Link
      className={classNames(styles.SideMenuItem, {
        [styles.SideMenuItemActive]: route.includes(pathname),
      })}
      to={route}
      data-testid={testId}
    >
      <FontAwesomeIcon
        className={classNames(styles.icon, {
          [styles.iconActive]: route.includes(pathname),
        })}
        icon={icon}
      />
      <h4
        className={classNames(styles.title, {
          [styles.titleActive]: route.includes(pathname),
        })}
      >
        {title}
      </h4>
    </Link>
  );

  const handleSignout = useCallback(async () => {
    await Promise.all([signOutUser(), signOutUserAction()(dispatch)]);

    navigate(getLoginPath());
    logSignOutToAnalytics(restaurant.id);
  }, [dispatch, navigate, restaurant]);

  return (
    <div className={styles.SideMenu}>
      <SideMenuItem
        title="Home"
        icon={faHouse}
        route={getHomeRoute()}
        testId="home-tab"
      />
      <SideMenuItem
        title="Menu"
        icon={faBookOpen}
        route={getMenuRoute("categories")}
        testId="menu-tab"
      />
      <SideMenuItem
        title="Orders"
        icon={faReceipt}
        route={getOrdersRoute()}
        testId="orders-tab"
      />
      <SideMenuItem
        title="Financials"
        icon={faBank}
        route={getFinancialsRoute()}
        testId="financials-tab"
      />
      {restaurant.restaurantSettings.isCateringSchedulingFormEnabled ===
        true && (
        <SideMenuItem
          title="Catering"
          icon={faUtensils}
          route={getCateringRoute()}
          testId="catering-tab"
        />
      )}
      <SideMenuItem
        title="Settings"
        icon={faGear}
        route={getSettingsRoute()}
        testId="settings-tab"
      />
      <div
        className={classNames(styles.SideMenuItem, styles.signOut)}
        data-testid="side-menu-sign-out"
        onClick={handleSignout}
      >
        <FontAwesomeIcon className={styles.icon} icon={faRightFromBracket} />
        <h4 className={styles.title}>Sign Out</h4>
      </div>
    </div>
  );
};
