import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle, SkeletonLoading } from "src/components";
import styles from "src/pages/Catering/styles.module.scss";
import { State } from "src/state/state";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { RestaurantFragment } from "src/state/restaurant/types";
import { getAllCateringRequestsForRestaurantAction } from "src/state/catering/actions";
import {
  formatISOStringToLongDate,
  formatISOStringToShortDateAndTime,
} from "src/common/date";
import { formatNumber } from "src/common/number";
import {
  CATERING_REQUEST_STATUS,
  CATERING_REQUEST_STATUS_DISPLAY,
  CateringRequestFragment,
} from "src/state/catering/types";
import { useNavigate } from "react-router-dom";
import { getCateringRequestRoute } from "src/Router/routes";
import {
  logViewCateringRequestToAnalytics,
  logViewCateringTabToAnalytics,
} from "src/common/analytics";

type EntityType = "open_requests" | "closed_requests";

export const Catering = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const cateringRequests = useSelector(
    (state: State) =>
      restaurant &&
      state.catering[restaurant.id] &&
      Object.values(state.catering[restaurant.id]),
  );

  const [isLoading, setIsLoading] = useState(true);
  const [entityTypeSelected, setEntityTypeSelected] =
    useState<EntityType>("open_requests");
  const [searchQuery, setSearchQuery] = useState("");

  const entities: { label: string; value: EntityType }[] = [
    { label: "Open Requests", value: "open_requests" },
    { label: "Closed Requests", value: "closed_requests" },
  ];

  const REQUEST_TYPE_STATUS_MAP = {
    open_requests: [CATERING_REQUEST_STATUS.OPEN],
    closed_requests: [
      CATERING_REQUEST_STATUS.COMPLETED,
      CATERING_REQUEST_STATUS.DECLINED,
    ],
  };

  const fetchCateringRequests = useCallback(async () => {
    setIsLoading(true);

    await getAllCateringRequestsForRestaurantAction(restaurant.id)(dispatch);

    setIsLoading(false);
  }, [restaurant, cateringRequests, dispatch]);

  const requestsToDisplay: CateringRequestFragment[] = useMemo(() => {
    if (!cateringRequests || !Array.isArray(cateringRequests)) {
      return [];
    }

    const query = searchQuery.toLowerCase();

    return cateringRequests.filter((request) => {
      const validStatuses = REQUEST_TYPE_STATUS_MAP[entityTypeSelected];
      if (!validStatuses.includes(request.status)) {
        return false;
      }

      return (
        request.customerName.toLowerCase().includes(query) ||
        request.companyName.toLowerCase().includes(query) ||
        request.cateringNumber.toString().includes(query) ||
        formatISOStringToLongDate(request.eventDate)
          .toLowerCase()
          .includes(query)
      );
    });
  }, [searchQuery, entityTypeSelected, cateringRequests]);

  useEffect(() => {
    fetchCateringRequests();
    logViewCateringTabToAnalytics(restaurant.id);
  }, []);

  if (isLoading) {
    return (
      <div
        className={classNames(styles.Catering, styles.loading)}
        data-testid="catering-loading-container"
      >
        <PageTitle
          title="Catering Requests"
          subtitle="View & manage your catering requests."
        />
        <div className={styles.topRow}>
          <div className={styles.entityTypeContainer}>
            {entities.map((entity) => (
              <h4
                key={entity.value}
                className={classNames(styles.entityText, {
                  [styles.entityTextSelected]:
                    entityTypeSelected === entity.value,
                })}
                onClick={() => {
                  setEntityTypeSelected(entity.value);
                }}
                data-testid={`catering-${entity.value}-button`}
              >
                {entity.label}
              </h4>
            ))}
          </div>
        </div>
        <div className={styles.loadingContainer}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Catering} data-testid="catering-requests-container">
      <PageTitle
        title="Catering Requests"
        subtitle="View & manage your catering requests."
      />
      <div className={styles.topRow}>
        <div className={styles.entityTypeContainer}>
          {entities.map((entity) => (
            <h4
              key={entity.value}
              className={classNames(styles.entityText, {
                [styles.entityTextSelected]:
                  entityTypeSelected === entity.value,
              })}
              onClick={() => {
                setEntityTypeSelected(entity.value);
              }}
              data-testid={`catering-${entity.value}-button`}
            >
              {entity.label}
            </h4>
          ))}
        </div>
      </div>

      <div className={styles.searchContainer}>
        <FontAwesomeIcon
          className={styles.searchIcon}
          size={"2x"}
          icon={faSearch}
        />
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchInput}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          data-testid="catering-search-input"
        />
      </div>

      <div className={classNames(styles.headerRow, styles.row)}>
        <div className={styles.requestNumberColumn}>
          <h3 className={styles.headerText}>Request</h3>
        </div>
        <div className={styles.dateColumn}>
          <h3 className={styles.headerText}>Event Date</h3>
        </div>
        <div className={styles.nameColumn}>
          <h3 className={styles.headerText}>Name</h3>
        </div>
        <div className={styles.companyColumn}>
          <h3 className={styles.headerText}>Company</h3>
        </div>
        <div className={styles.budgetColumn}>
          <h3 className={styles.headerText}>Budget</h3>
        </div>
        <div className={styles.statusColumn}>
          <h3 className={styles.headerText}>Status</h3>
        </div>
      </div>
      {requestsToDisplay.length > 0 ? (
        requestsToDisplay.map((request) => (
          <div
            className={styles.row}
            data-testid={`catering-request-${request.id}-container`}
            key={request.id}
            onClick={() => {
              navigate(getCateringRequestRoute(request.id));
              logViewCateringRequestToAnalytics(restaurant.id, request.id);
            }}
          >
            <div className={styles.requestNumberColumn}>
              {isDesktop ? (
                <h3
                  className={styles.rowText}
                  data-testid={`catering-request-${request.id}-request-number`}
                >
                  #{request.cateringNumber}
                </h3>
              ) : (
                <div>
                  <h3
                    className={styles.rowText}
                  >{`#${request.cateringNumber} from ${request.customerName}`}</h3>
                  <h3 className={styles.rowSubText}>
                    {formatISOStringToShortDateAndTime(request.eventDate)}
                  </h3>
                </div>
              )}
            </div>
            <div className={styles.dateColumn}>
              <h3 className={styles.rowText}>
                {formatISOStringToShortDateAndTime(request.eventDate)}
              </h3>
            </div>
            <div className={styles.nameColumn}>
              <h3
                className={styles.rowText}
                data-testid={`catering-request-${request.id}-customer-name`}
              >
                {request.customerName}
              </h3>
            </div>
            <div className={styles.companyColumn}>
              <h3
                className={styles.rowText}
                data-testid={`catering-request-${request.id}-company-name`}
              >
                {request.companyName !== "" ? request.companyName : "-"}
              </h3>
            </div>
            <div className={styles.budgetColumn}>
              <h3
                className={styles.rowText}
                data-testid={`catering-request-${request.id}-budget`}
              >
                {formatNumber(request.estimatedBudget)}
              </h3>
            </div>
            <div className={styles.statusColumn}>
              <h3
                className={styles.rowText}
                data-testid={`catering-request-${request.id}-status-text`}
              >
                {CATERING_REQUEST_STATUS_DISPLAY[request.status]}
              </h3>
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noRequestsMessage}>
          <h3 className={styles.noRequestsText}>No requests to display</h3>
        </div>
      )}
    </div>
  );
};
