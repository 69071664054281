// Deal reducer
import {
  DEAL_ACTION,
  UpdateDealPublishStatusAction,
  GetDealsForRestaurantAction,
  DeleteDealFromDatabaseAction,
} from "src/state/deal/actions";
import { DealState } from "src/state/deal/types";

type DealAction =
  | UpdateDealPublishStatusAction
  | GetDealsForRestaurantAction
  | DeleteDealFromDatabaseAction;

export const dealReducer = (state: DealState = {}, action: DealAction) => {
  switch (action.type) {
    case DEAL_ACTION.UPDATE_DEAL_PUBLISHED_STATUS_ACTION: {
      const { response } = action;

      const next: DealState = {
        ...state,
        [response.restaurantId]: { ...(state[response.restaurantId] || {}) },
      };

      next[response.restaurantId][response.id].isPublished =
        response.isPublished;

      return next;
    }
    case DEAL_ACTION.GET_DEALS_FOR_RESTAURANT_ACTION: {
      const { response: deals, restaurantId } = action;

      const next: DealState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      deals.forEach((deal) => {
        next[restaurantId][deal.id] = deal;
      });

      return next;
    }
    case DEAL_ACTION.DELETE_DEAL_FROM_DATABASE_ACTION: {
      const { response, restaurantId } = action;

      const next: DealState = {
        ...state,
        [restaurantId]: { ...(state[restaurantId] || {}) },
      };

      delete next[restaurantId][response.dealId];

      return next;
    }
    default: {
      return state;
    }
  }
};
