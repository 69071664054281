import ReactDOM from "react-dom/client";
import "src/index.css";
import { App } from "src/App";
import reportWebVitals from "src/reportWebVitals";
import * as Sentry from "@sentry/react";
import { getCurrentEnvironment } from "src/config/getConfig";

if (getCurrentEnvironment() === "prod") {
  Sentry.init({
    dsn: "https://dae9a6e3b3c9ad0facaca36209877492@o4504812880789504.ingest.us.sentry.io/4507224483495936",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
