import { useSelector } from "react-redux";
import { CateringRequestFragment } from "src/state/catering/types";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import styles from "src/pages/CateringRequest/CustomerDetails/styles.module.scss";
import { formatPhoneNumber } from "src/common/phone";

interface ComponentProps {
  cateringRequestId: string;
}

export const CustomerDetails = ({ cateringRequestId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const cateringRequest = useSelector(
    (state: State) =>
      restaurant &&
      state.catering[restaurant.id] &&
      state.catering[restaurant.id][cateringRequestId],
  ) as CateringRequestFragment;

  return (
    <div className={styles.CustomerDetails}>
      <h3 className={styles.title}>Customer</h3>

      <p className={styles.customerDetailTitle}>
        {`Name: `}
        <span
          className={styles.customerDetailValue}
          data-testid="customer-details-name"
        >
          {cateringRequest.customerName}
        </span>
      </p>

      {cateringRequest.companyName && (
        <p className={styles.customerDetailTitle}>
          {`Company: `}
          <span
            className={styles.customerDetailValue}
            data-testid="customer-details-company"
          >
            {cateringRequest.companyName}
          </span>
        </p>
      )}

      <p className={styles.customerDetailTitle}>
        {`Phone: `}
        <span
          className={styles.customerDetailValue}
          data-testid="customer-details-phone"
        >
          {formatPhoneNumber(cateringRequest.phoneNumber)}
        </span>
      </p>

      <p className={styles.customerDetailTitle}>
        {`Email: `}
        <a
          href={`mailto:${cateringRequest.emailAddress}`}
          className={styles.emailLink}
          data-testid="customer-details-email"
        >
          {cateringRequest.emailAddress}
        </a>
      </p>
    </div>
  );
};
