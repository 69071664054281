import classNames from "classnames";
import styles from "src/components/PageTitle/styles.module.scss";

interface ComponentProps {
  className?: string;
  title: string;
  subtitle?: string;
  testId?: string;
}

export const PageTitle = ({
  className,
  title,
  subtitle,
  testId,
}: ComponentProps) => (
  <div className={classNames(styles.PageTitle, className)}>
    <h2
      className={styles.title}
      {...(testId && { "data-testid": `${testId}-title` })}
    >
      {title}
    </h2>
    {subtitle && (
      <h3
        className={styles.subtitle}
        {...(testId && { "data-testid": `${testId}-subtitle` })}
      >
        {subtitle}
      </h3>
    )}
  </div>
);
