import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatTimeToDate, formatTimeToLongDateTime } from "src/common/date";
import { formatNumber } from "src/common/number";
import {
  getUnpaidBalanceForRestaurant,
  listPayoutsForRestaurant,
  PayoutsResponse,
} from "src/common/payouts";
import { Button, SkeletonLoading } from "src/components";
import styles from "src/pages/Financials/Payouts/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import palette from "src/common/styles/palette.module.scss";

export const Payouts = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [payouts, setPayouts] = useState<PayoutsResponse>();
  const [unpaidBalance, setUnpaidBalance] = useState<number>(0);
  const [isPreviousPageButtonDisabled, setIsPreviousPageButtonDisabled] =
    useState(true);
  const [isNextPageButtonDisabled, setIsNextPageButtonDisabled] =
    useState(false);

  const fetchPayoutsForRestaurant = useCallback(async () => {
    if (!restaurant.stripeConnectedAccountId) {
      setIsLoading(false);
      return;
    }

    const payoutsResponse = await listPayoutsForRestaurant(
      restaurant.id,
      undefined,
      undefined,
    );
    setPayouts(payoutsResponse);

    const unpaidBalanceResponse = await getUnpaidBalanceForRestaurant(
      restaurant.id,
    );
    setUnpaidBalance(unpaidBalanceResponse);

    setIsLoading(false);
  }, [restaurant]);

  const goToPreviousPage = useCallback(async () => {
    if (payouts) {
      setIsLoading(true);

      const newStartingAfter = undefined;
      const newEndingBefore = payouts.data[0].id;

      const repsonse = await listPayoutsForRestaurant(
        restaurant.id,
        newStartingAfter,
        newEndingBefore,
      );

      if (repsonse.data.length === 0) {
        setIsPreviousPageButtonDisabled(true);
        setIsLoading(false);
      } else {
        setPayouts(repsonse);
        setIsNextPageButtonDisabled(false);
        setIsLoading(false);
      }
    }
  }, [restaurant, payouts]);

  const goToNextPage = useCallback(async () => {
    if (payouts) {
      setIsLoading(true);

      const newStartingAfter = payouts.data[payouts.data.length - 1].id;
      const newEndingBefore = undefined;

      const response = await listPayoutsForRestaurant(
        restaurant.id,
        newStartingAfter,
        newEndingBefore,
      );

      if (response.data.length === 0) {
        setIsNextPageButtonDisabled(true);
        setIsLoading(false);
      } else {
        setPayouts(response);
        setIsPreviousPageButtonDisabled(false);
        setIsLoading(false);
      }
    }
  }, [restaurant, payouts]);

  const statusValueToLabel = {
    paid: "Paid",
    pending: "Pending",
    in_transit: "In Transit",
    canceled: "Canceled",
    failed: "Failed",
  };

  const statusValueToColor = {
    paid: palette["light-green"],
    pending: palette["blue"],
    in_transit: palette["blue"],
    canceled: palette["red"],
    failed: palette["red"],
  };

  useEffect(() => {
    fetchPayoutsForRestaurant();
  }, []);

  if (!restaurant.stripeConnectedAccountId) {
    return (
      <div className={styles.Payouts}>
        <h2 className={styles.connectYourStripeMessage}>
          <a
            href="https://onboard.joinplatter.com"
            className={styles.connectLink}
            target="_blank"
          >
            {`Connect your Stripe account`}
          </a>
          {` to view payouts`}
        </h2>
      </div>
    );
  }

  if (isLoading || !payouts) {
    return (
      <div className={styles.loadingContainer}>
        <SkeletonLoading />
      </div>
    );
  }

  if (payouts.data.length === 0) {
    return (
      <div className={styles.Payouts}>
        <h2 className={styles.connectYourStripeMessage}>
          No Payouts to Display
        </h2>
      </div>
    );
  }

  return (
    <div className={styles.Payouts}>
      <p className={styles.futurePayouts}>
        {`Future Payouts`}
        <p
          className={styles.futurePayoutsDollarAmount}
        >{`${formatNumber(unpaidBalance / 100)} USD`}</p>
      </p>
      <div className={classNames(styles.headerRow, styles.row)}>
        <div className={styles.amountColumn}>
          <h4 className={styles.headerText}>Amount</h4>
        </div>
        <div className={styles.statusColumn}>
          <h4 className={styles.headerText}>Status</h4>
        </div>
        <div className={styles.initiatedColumn}>
          <h4 className={styles.headerText}>Initiated</h4>
        </div>
        <div className={styles.arrivalColumn}>
          <h4 className={styles.headerText}>Est. Arrival</h4>
        </div>
      </div>
      {payouts.data.map((payout) => (
        <div className={styles.row} key={payout.id}>
          <div className={styles.amountColumn}>
            <h3 className={styles.rowText}>{`${formatNumber(
              payout.amount / 100,
            )}`}</h3>
          </div>
          <div className={styles.statusColumn}>
            <div
              className={styles.statusIndicator}
              style={{
                backgroundColor:
                  payout.amount < 0
                    ? palette["light-gray"]
                    : statusValueToColor[payout.status],
              }}
            >
              <h3 className={classNames(styles.rowText, styles.statusText)}>
                {payout.amount < 0
                  ? "Withdrawn"
                  : statusValueToLabel[payout.status]}
              </h3>
            </div>
          </div>
          <div className={styles.initiatedColumn}>
            <h3 className={styles.rowText}>
              {formatTimeToLongDateTime(payout.created * 1000)}
            </h3>
          </div>
          <div className={styles.arrivalColumn}>
            <h3 className={styles.rowText}>
              {formatTimeToDate((payout.arrival_date + 86400) * 1000)}
            </h3>
          </div>
        </div>
      ))}
      <div className={styles.buttonsContainer}>
        <Button
          text="Previous"
          disabled={isPreviousPageButtonDisabled}
          secondary={true}
          className={styles.pageChangeButton}
          onClick={goToPreviousPage}
        />
        <Button
          text="Next"
          disabled={isNextPageButtonDisabled}
          secondary={true}
          className={styles.pageChangeButton}
          onClick={goToNextPage}
        />
      </div>
    </div>
  );
};
