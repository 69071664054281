// Restaurant analytics reducer
import {
  RESTAURANT_ANALYTICS_ACTION,
  GetRestaurantAnalyticsForDateRangeAction,
} from "src/state/restaurantAnalytics/actions";
import { RestaurantAnalyticsState } from "src/state/restaurantAnalytics/types";

type RestaurantAnalyticsAction = GetRestaurantAnalyticsForDateRangeAction;

export const restaurantAnalyticsReducer = (
  state: RestaurantAnalyticsState = {},
  action: RestaurantAnalyticsAction,
) => {
  switch (action.type) {
    case RESTAURANT_ANALYTICS_ACTION.GET_RESTAURANT_ANALYTICS_FOR_DATE_RANGE_ACTION: {
      const next: RestaurantAnalyticsState = { ...state };

      const { response: restaurantAnalytics, startDate, endDate } = action;

      next[`${startDate}-${endDate}`] = restaurantAnalytics;

      return next;
    }
    default: {
      return state;
    }
  }
};
