import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  PageTitle,
  SkeletonLoading,
  StatusIndicator,
} from "src/components";
import styles from "src/pages/Orders/styles.module.scss";
import { getDealsForRestaurantAction } from "src/state/deal/actions";
import { getAllOrdersForRestaurantAction } from "src/state/order/actions";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { useMediaQuery } from "react-responsive";
import {
  formatISOStringToShortDateAndTime,
  formatISOStringToLongDateAndTime,
} from "src/common/date";
import { formatNumber } from "src/common/number";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOrderRoute } from "src/Router/routes";

export const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const orders = useSelector(
    (state: State) =>
      restaurant &&
      state.orders[restaurant.id] &&
      Object.values(state.orders[restaurant.id]),
  );
  const deals = useSelector((state: State) => state.deals[restaurant.id]);

  const LIMIT = 10;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = useCallback(
    async (limit: number, newPage: number) => {
      setSearchParams({ page: `${newPage}` });
      setIsLoading(true);

      const offset = (newPage - 1) * limit;

      if (!deals) {
        await Promise.all([
          getAllOrdersForRestaurantAction(
            restaurant.id,
            limit,
            offset,
          )(dispatch),
          getDealsForRestaurantAction(restaurant.id)(dispatch),
        ]);
      } else {
        await getAllOrdersForRestaurantAction(
          restaurant.id,
          limit,
          offset,
        )(dispatch);
      }

      setIsLoading(false);
    },
    [restaurant, orders, deals, dispatch],
  );

  useEffect(() => {
    const currentPage = searchParams.get("page");

    if (!currentPage) {
      setSearchParams({ page: "1" });
      fetchOrders(LIMIT, 1);
    } else {
      fetchOrders(LIMIT, parseInt(currentPage));
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.Orders} data-testid="orders-loading-container">
        <PageTitle
          className={styles.loadingPageTitle}
          title="Orders"
          subtitle={`View & manage orders from customers.`}
        />
        <div className={styles.loadingContainer}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Orders} data-testid="orders-container">
      <PageTitle
        title="Orders"
        subtitle={`View & manage orders from customers.`}
      />
      <div className={classNames(styles.headerRow, styles.row)}>
        <div className={styles.orderNumberColumn}>
          <h3 className={styles.headerText}>Order</h3>
        </div>
        <div className={styles.dateColumn}>
          <h3 className={styles.headerText}>Date</h3>
        </div>
        <div className={styles.customerColumn}>
          <h3 className={styles.headerText}>Customer</h3>
        </div>
        <div className={styles.totalColumn}>
          <h3 className={styles.headerText}>Total</h3>
        </div>
        <div className={styles.statusColumn}>
          <h3 className={styles.headerText}>Status</h3>
        </div>
      </div>
      {orders.map((order) => {
        let customerName = "";

        if (order.customer) {
          customerName = `${order.customer.firstName} ${order.customer.lastName}`;
        } else if (order.guestCustomer) {
          customerName = order.guestCustomer.name;
        }

        let orderTotal = order.totalPriceCharged;
        orderTotal -= order.serviceFee;
        orderTotal -= order.paymentProcessingFee;

        if (order.delivery) {
          orderTotal -= order.delivery.deliveryFee;
          orderTotal -= order.delivery.driverTipInDollars;
          orderTotal -= order.delivery.paymentProcessingFee;
        }

        return (
          <div
            className={styles.row}
            data-testid={`orders-tab-order-${order.id}-container`}
            key={order.id}
            onClick={() => {
              navigate(getOrderRoute(order.id));
            }}
          >
            <div className={styles.orderNumberColumn}>
              {isDesktop ? (
                <h3
                  className={styles.rowText}
                  data-testid={`orders-tab-order-${order.id}-order-number`}
                >{`#${order.orderNumber}`}</h3>
              ) : (
                <div>
                  <h3
                    className={styles.rowText}
                  >{`#${order.orderNumber} from ${customerName}`}</h3>
                  <h3 className={styles.rowSubtext}>
                    {formatISOStringToShortDateAndTime(order.createdAt)}
                  </h3>
                </div>
              )}
            </div>
            <div className={styles.dateColumn}>
              <h3 className={styles.rowText}>
                {formatISOStringToLongDateAndTime(order.createdAt)}
              </h3>
            </div>
            <div className={styles.customerColumn}>
              <h3
                className={styles.rowText}
                data-testid={`orders-tab-order-${order.id}-customer-text`}
              >
                {customerName}
              </h3>
            </div>
            <div className={styles.totalColumn}>
              <h3
                className={styles.rowText}
                data-testid={`orders-tab-order-${order.id}-order-total`}
              >
                {formatNumber(orderTotal)}
              </h3>
            </div>
            <div className={styles.statusColumn}>
              <StatusIndicator
                className={styles.statusIndicator}
                status={order.status}
                textTestId={`orders-tab-order-${order.id}-status-text`}
              />
            </div>
          </div>
        );
      })}
      <div className={styles.buttonsContainer}>
        <Button
          testId="orders-tab-previous-button"
          text="Previous"
          disabled={searchParams.get("page") === "1"}
          secondary={true}
          className={styles.pageChangeButton}
          onClick={async () => {
            const currentPage = searchParams.get("page") || "1";
            const newPage = parseInt(currentPage) - 1;
            await fetchOrders(LIMIT, newPage);
          }}
        />
        <Button
          testId="orders-tab-next-button"
          text="Next"
          disabled={orders.length < LIMIT}
          secondary={true}
          className={styles.pageChangeButton}
          onClick={async () => {
            const currentPage = searchParams.get("page") || "1";
            const newPage = parseInt(currentPage) + 1;
            await fetchOrders(LIMIT, newPage);
          }}
        />
      </div>
    </div>
  );
};
