import { useCallback, useState } from "react";
import { TextInput, Button } from "src/components";
import styles from "src/pages/Login/styles.module.scss";
import {
  logLogInFailureToAnalytics,
  logLogInInitiatedToAnalytics,
  logLogInSuccessToAnalytics,
} from "src/common/analytics";
import { isValidEmailAddress } from "src/common/validation";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { loginUserInAWSAction } from "src/state/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { captureManualSentryException } from "src/common/sentry";
import { initializeState } from "src/state/initializeState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getHomeRoute } from "src/Router/routes";
import * as Sentry from "@sentry/react";
import { State } from "src/state/state";

interface SignInFieldsErrors {
  emailAddress: string | undefined;
  password: string | undefined;
}

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionId = useSelector(
    (state: State) => state.session.sessionId as string,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState<SignInFieldsErrors>({
    emailAddress: undefined,
    password: undefined,
  });
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);

  const areFieldsValid = useCallback(() => {
    const newErrorMessages = {
      ...errorMessages,
    };

    if (emailAddress.trim() === "") {
      newErrorMessages.emailAddress = "Please enter an email address.";
      logLogInFailureToAnalytics("FIELDS_MISSING");
    }

    if (
      emailAddress.trim() !== "" &&
      !isValidEmailAddress(emailAddress.trim())
    ) {
      newErrorMessages.emailAddress = "Please enter a valid email address.";
      logLogInFailureToAnalytics("INVALID_EMAIL");
    }

    if (password.trim() === "") {
      newErrorMessages.password = "Please enter a password.";
      logLogInFailureToAnalytics("FIELDS_MISSING");
    }

    for (const key in newErrorMessages) {
      if (newErrorMessages[key] !== undefined) {
        setErrorMessages(newErrorMessages);
        return false;
      }
    }

    return true;
  }, [emailAddress, password, errorMessages]);

  const handleLogin = useCallback(async () => {
    logLogInInitiatedToAnalytics();

    if (areFieldsValid()) {
      setIsLoading(true);

      try {
        setIsLoading(true);

        // Login user and fetch restaurant information from db
        const user = await loginUserInAWSAction(
          emailAddress.trim(),
          password.trim(),
        )(dispatch);

        const restaurantId = user.response.userId;

        const { restaurant } = await initializeState(restaurantId, dispatch);

        Sentry.setContext("user_info", {
          restaurantId: restaurant.id,
          restaurantName: restaurant.restaurantName,
          sessionId,
        });

        navigate(getHomeRoute());

        setIsLoading(false);

        logLogInSuccessToAnalytics(restaurantId);
      } catch (e) {
        setIsLoading(false);
        const errorMessage = typeof e === "string" ? e : (e as Error).message;

        if (errorMessage.includes("Incorrect username or password")) {
          setErrorBox("Incorrect email or password.");
          logLogInFailureToAnalytics("INCORRECT_EMAIL_OR_PASSWORD");
        } else {
          setErrorBox("Something went wrong. Please try again.");
          logLogInFailureToAnalytics("UNKNOWN_ERROR", errorMessage);
          captureManualSentryException(e as Error);
        }
      }
    }
  }, [areFieldsValid, emailAddress, password, sessionId, dispatch, navigate]);

  return (
    <div className={styles.Login} data-testid="login-container">
      <div className={styles.loginContainer}>
        <div className={styles.brandContainer}>
          <img
            src={require("../../assets/BlueLogo.png")}
            alt="logo"
            className={styles.logo}
          />
          <h3 className={styles.brandText}>Platter</h3>
        </div>
        <h2 className={styles.loginText}>Log In</h2>
        <h4 className={styles.loginSubtext}>Continue to your restaurant</h4>
        <TextInput
          className={styles.input}
          testIdPrefix="login-email-address"
          label="Email Address"
          type="email"
          autoComplete="email"
          value={emailAddress}
          onChangeText={(newText) => {
            setEmailAddress(newText);
            setErrorMessages({
              ...errorMessages,
              emailAddress: undefined,
            });
          }}
          placeholder="Enter your email address..."
          errorMessage={errorMessages.emailAddress}
        />
        <TextInput
          className={styles.input}
          testIdPrefix="login-password"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChangeText={(newText) => {
            setPassword(newText);
            setErrorMessages({
              ...errorMessages,
              password: undefined,
            });
          }}
          placeholder="Enter your password..."
          errorMessage={errorMessages.password}
        />
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <ReactLoading
              type="spin"
              color={palette.blue}
              height={40}
              width={40}
            />
          </div>
        ) : (
          <Button
            testId="login-button"
            className={styles.loginButton}
            text="Log In"
            onClick={() => {
              handleLogin();
            }}
          />
        )}
        {errorBox && (
          <div className={styles.errorBox}>
            <FontAwesomeIcon
              className={styles.errorIcon}
              icon={faTriangleExclamation}
            />
            <p data-testid="error-box-text" className={styles.errorBoxText}>
              {errorBox}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
