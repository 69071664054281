export const downloadFileFromRemote = async (url: string, filename: string) => {
  const response = await fetch(url);
  const blob = await response.blob();

  const fileURL = window.URL.createObjectURL(blob);

  const aLink = document.createElement("a");
  aLink.href = fileURL;
  aLink.download = filename;
  aLink.click();
};
