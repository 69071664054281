import { Provider } from "react-redux";
import { store } from "src/state/store";
import { Router } from "src/Router/Router";

export const App = () => {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};
