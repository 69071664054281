import { useDispatch, useSelector } from "react-redux";
import styles from "src/pages/CateringRequest/styles.module.scss";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { Button, PageTitle, SkeletonLoading } from "src/components";
import {
  CATERING_REQUEST_STATUS,
  CATERING_REQUEST_STATUS_DISPLAY,
} from "src/state/catering/types";
import { useCallback, useEffect, useState } from "react";
import { formatISOStringToShortDateAndTime } from "src/common/date";
import { captureManualSentryException } from "src/common/sentry";
import { AdditionalNotes } from "src/pages/CateringRequest/AdditionalNotes/AdditionalNotes";
import { RequestDetails } from "src/pages/CateringRequest/RequestDetails/RequestDetails";
import { CustomerDetails } from "src/pages/CateringRequest/CustomerDetails/CustomerDetails";
import { MessagePanel } from "src/pages/CateringRequest/MessagePanel/MessagePanel";
import {
  getCateringRequestAction,
  updateCateringRequestStatusAction,
} from "src/state/catering/actions";
import { showToast } from "src/common/toast";
import { CreateInvoiceModal } from "src/pages/CateringRequest/CreateInvoiceModal/CreateInvoiceModal";
import { logClickCreateInvoiceModalToAnalytics } from "src/common/analytics";

type CateringRequestUrlParams = "cateringRequestId";

export const CateringRequest = () => {
  const dispatch = useDispatch();
  const isLargeDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const { cateringRequestId } = useParams<CateringRequestUrlParams>() as {
    cateringRequestId: string;
  };
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const cateringRequest = useSelector(
    (state: State) =>
      restaurant &&
      state.catering[restaurant.id] &&
      state.catering[restaurant.id][cateringRequestId],
  );

  const fetchCateringRequest = useCallback(async () => {
    setIsLoading(true);
    await getCateringRequestAction(restaurant.id, cateringRequestId)(dispatch);
    setIsLoading(false);
  }, [cateringRequestId, restaurant.id, dispatch]);

  const handleDeclineRequest = useCallback(async () => {
    setIsLoadingAfterDecline(true);
    await updateCateringRequestStatusAction(
      restaurant.id,
      cateringRequestId,
      CATERING_REQUEST_STATUS.DECLINED,
    )(dispatch);
    setIsLoadingAfterDecline(false);
    showToast("Request declined successfully");
  }, [restaurant.id, cateringRequestId, dispatch]);

  const [isLoading, setIsLoading] = useState(cateringRequest ? false : true);
  const [isLoadingAfterDecline, setIsLoadingAfterDecline] = useState(false);
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);

  useEffect(() => {
    fetchCateringRequest();
  }, [fetchCateringRequest]);

  if (isLoading) {
    return (
      <div
        className={styles.CateringRequest}
        data-data-testid="catering-request-loading-container"
      >
        <div className={styles.loadingContainer}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  if (!restaurant || !cateringRequest) {
    captureManualSentryException(
      new Error(
        "Restaurant or Catering Request null in Catering Request screen",
      ),
    );
    return null;
  }

  return (
    <div
      className={styles.CateringRequest}
      data-testid="catering-request-container"
    >
      <PageTitle
        title={`${cateringRequest.customerName}`}
        subtitle={`Request #${cateringRequest.cateringNumber} | ${formatISOStringToShortDateAndTime(cateringRequest.eventDate)} | ${CATERING_REQUEST_STATUS_DISPLAY[cateringRequest.status]}`}
        testId="catering-request-header"
      />
      {isLargeDesktop ? (
        <div className={styles.contentContainer}>
          <div className={styles.desktopLeft}>
            <MessagePanel cateringRequestId={cateringRequestId} />
          </div>
          <div className={styles.desktopRight}>
            <div className={styles.actionButtons}>
              <Button
                testId="decline-request-button"
                text="Decline"
                error
                className={styles.declineButton}
                onClick={handleDeclineRequest}
                disabled={
                  isLoadingAfterDecline ||
                  cateringRequest.status === CATERING_REQUEST_STATUS.DECLINED
                }
              />
              <Button
                testId="create-invoice-button"
                text="Create Invoice"
                className={styles.acceptButton}
                onClick={() => {
                  logClickCreateInvoiceModalToAnalytics(
                    restaurant.id,
                    cateringRequestId,
                  );
                  setIsCreateInvoiceModalOpen(true);
                }}
                disabled={
                  isLoadingAfterDecline ||
                  cateringRequest.status === CATERING_REQUEST_STATUS.DECLINED
                }
              />
            </div>
            <RequestDetails cateringRequestId={cateringRequestId} />
            {cateringRequest.notes !== "" && (
              <AdditionalNotes cateringRequestId={cateringRequestId} />
            )}
            <CustomerDetails cateringRequestId={cateringRequestId} />
          </div>
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.actionButtons}>
            <Button
              testId="decline-request-button"
              text="Decline"
              error
              className={styles.declineButton}
              onClick={handleDeclineRequest}
              disabled={
                isLoadingAfterDecline ||
                cateringRequest.status === CATERING_REQUEST_STATUS.DECLINED
              }
            />
            <Button
              testId="create-invoice-button"
              text="Create Invoice"
              className={styles.acceptButton}
              onClick={() => setIsCreateInvoiceModalOpen(true)}
              disabled={
                isLoadingAfterDecline ||
                cateringRequest.status === CATERING_REQUEST_STATUS.DECLINED
              }
            />
          </div>
          <RequestDetails cateringRequestId={cateringRequestId} />
          {cateringRequest.notes !== "" && (
            <AdditionalNotes cateringRequestId={cateringRequestId} />
          )}
          <CustomerDetails cateringRequestId={cateringRequestId} />
          <MessagePanel cateringRequestId={cateringRequestId} />
        </div>
      )}
      <CreateInvoiceModal
        cateringRequestId={cateringRequestId}
        isVisible={isCreateInvoiceModalOpen}
        onClose={() => {
          setIsCreateInvoiceModalOpen(false);
        }}
      />
    </div>
  );
};
