import { useDispatch, useSelector } from "react-redux";
import {
  CATERING_MESSAGE_SENDER,
  CateringMessageFragment,
  CateringRequestFragment,
} from "src/state/catering/types";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import styles from "src/pages/CateringRequest/MessagePanel/styles.module.scss";
import { captureManualSentryException } from "src/common/sentry";
import { formatNumber } from "src/common/number";
import { formatISOStringToShortDateAndTime } from "src/common/date";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "src/components";
import classNames from "classnames";
import { sendMessageFromRestaurantToCustomer } from "src/common/catering";
import { getCateringRequestAction } from "src/state/catering/actions";
import { getCurrentEnvironment } from "src/config/getConfig";

interface ComponentProps {
  cateringRequestId: string;
}

export const MessagePanel = ({ cateringRequestId }: ComponentProps) => {
  const dispatch = useDispatch();
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const cateringRequest = useSelector(
    (state: State) =>
      restaurant &&
      state.catering[restaurant.id] &&
      state.catering[restaurant.id][cateringRequestId],
  ) as CateringRequestFragment;

  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  if (!restaurant || !cateringRequest) {
    captureManualSentryException(
      new Error(
        "Restaurant or Catering Request null in Catering Request screen",
      ),
    );
    return null;
  }

  const [cateringMessages, setCateringMessages] = useState<
    CateringMessageFragment[]
  >(cateringRequest?.cateringMessages || []);

  const sortedMessages = useMemo(() => {
    return cateringMessages.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 1,
    );
  }, [cateringMessages]);

  const scrollToBottom = useCallback(() => {
    const bottomOfMessages = document.getElementById("message-scroll-bottom");
    if (bottomOfMessages && getCurrentEnvironment() !== "test") {
      bottomOfMessages.scrollIntoView({ behavior: "smooth" });
    }
  }, [sortedMessages]);

  const fetchSpecificCateringRequest = useCallback(async () => {
    const updatedCateringRequest = await getCateringRequestAction(
      restaurant.id,
      cateringRequestId,
    )(dispatch);

    setCateringMessages(updatedCateringRequest.response.cateringMessages);
  }, [cateringRequestId, dispatch, restaurant.id]);

  useEffect(() => {
    scrollToBottom();
  }, [cateringRequest, sortedMessages]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchSpecificCateringRequest();
    }, 120000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchSpecificCateringRequest]);

  return (
    <div className={styles.MessagePanel}>
      <div className={styles.messageView}>
        <div className={`${styles.message} ${styles.customerMessage}`}>
          <p data-testid="initial-catering-message">
            {`Estimated Number of Guests: ${cateringRequest.estimatedNumberOfGuests}`}
            <br />
            {`Event Date: ${formatISOStringToShortDateAndTime(cateringRequest.eventDate)}`}
            <br />
            {`Event Location: ${cateringRequest.eventLocation}`}
            <br />
            {`Budget: ${formatNumber(cateringRequest.estimatedBudget)}`}
            <br />
            {cateringRequest.notes && `Other notes: ${cateringRequest.notes}`}
            <br />
          </p>
          <p className={styles.timestamp}>
            {formatISOStringToShortDateAndTime(cateringRequest.createdAt)}
          </p>
        </div>
        {sortedMessages.map((message) => (
          <div
            key={message.id}
            className={classNames(styles.message, {
              [styles.customerMessage]:
                message.sender === CATERING_MESSAGE_SENDER.CUSTOMER,
              [styles.restaurantMessage]:
                message.sender === CATERING_MESSAGE_SENDER.RESTAURANT,
            })}
          >
            {message.message ? (
              <p data-testid={`catering-message-${message.id}`}>
                {message.message}
              </p>
            ) : (
              message.attachmentUrl && (
                <div>
                  <a
                    href={message.attachmentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Attachment
                  </a>
                </div>
              )
            )}
            <p
              className={styles.timestamp}
              data-testid={`catering-message-${message.id}-timestamp`}
            >
              {formatISOStringToShortDateAndTime(message.createdAt)}
            </p>
          </div>
        ))}
        <div id="message-scroll-bottom" />
      </div>
      <div className={styles.messageInputContainer}>
        <textarea
          data-testid="message-input"
          className={styles.messageInput}
          value={message}
          onChange={(newText) => setMessage(newText.target.value)}
          placeholder="Type your message..."
          rows={1}
        />
        <div className={styles.messageButtons}>
          {/* Intentionally commented out for now. 
          
          <div
            className={styles.attachmentButton}
            onClick={() => document.getElementById("fileInput")?.click()}
          >
            <FontAwesomeIcon icon={faPaperclip} />
            <input
              type="file"
              id="fileInput"
              accept="image/*,.pdf"
              style={{ display: "none" }}
            />
          </div> */}
          <Button
            testId="send-message-button"
            className={styles.sendButton}
            text="Send"
            disabled={isSending}
            onClick={async () => {
              if (message.trim()) {
                setIsSending(true);
                const newMessage = await sendMessageFromRestaurantToCustomer(
                  cateringRequestId,
                  restaurant.id,
                  message,
                );
                if (newMessage) {
                  setCateringMessages((prevMessages) => [
                    ...prevMessages,
                    newMessage,
                  ]);
                }
                setMessage("");
                setIsSending(false);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
