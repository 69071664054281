// Export functions
import { post } from "src/api/server";
import { ExportFragment } from "src/common/types/Export";

export const createItemizedOrdersExport = async (
  restaurantId: string,
  beginDate?: Date,
  endDate?: Date,
): Promise<ExportFragment> => {
  const response = (await post({
    service: "thanos",
    endpoint: "/export/itemized_orders",
    params: {
      restaurantId,
      beginDate,
      endDate,
    },
  })) as ExportFragment;

  return response;
};

export const createOrdersSummaryExport = async (
  restaurantId: string,
  beginDate?: Date,
  endDate?: Date,
): Promise<ExportFragment> => {
  const response = (await post({
    service: "thanos",
    endpoint: "/export/orders_summary",
    params: {
      restaurantId,
      beginDate,
      endDate,
    },
  })) as ExportFragment;

  return response;
};

export const createMonthlyStatementExport = async (
  restaurantId: string,
  month: number,
  year: number,
): Promise<ExportFragment> => {
  const response = (await post({
    service: "thanos",
    endpoint: "/export/monthly_statement",
    params: {
      restaurantId,
      month,
      year,
    },
  })) as ExportFragment;

  return response;
};
