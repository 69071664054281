import { useDispatch, useSelector } from "react-redux";
import { BasicModal, Button, PageTitle } from "src/components";
import styles from "src/pages/HoursOfOperation/styles.module.scss";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import { useCallback, useMemo, useState } from "react";
import {
  HoursOfOperationFragment,
  HoursOfOperationFragmentWithMode,
  HoursOfOperationMode,
} from "src/common/types/HoursOfOperation";
import { DayOfTheWeekHours } from "src/common/types/DayOfTheWeekHours";
import { LocationFragment } from "src/common/types/Location";
import {
  areAllCloseTimesAfterOpenTimes,
  areAllHoursNonOverlapping,
  daysOfWeek,
  format24HourTimeToISOString,
  formatISOStringTo24HourTime,
  getNextOpenTime,
  isNowWithinOpenHoursOfOperation,
  NINE_AM_AS_ISO_STRING,
  NINE_PM_AS_ISO_STRING,
} from "src/common/date";
import {
  updateHoursOfOperationModeInDatabaseAction,
  updateLocationHoursInDatabaseAction,
} from "src/state/restaurant/actions";
import {
  logUpdateHoursOfOperationInAnalytics,
  logUpdateHoursOfOperationModeInAnalytics,
} from "src/common/analytics";
import { getAddressStringFromLocationEntity } from "src/common/address";
import _ from "lodash";
import classNames from "classnames";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import Switch from "react-switch";
import { showToast } from "src/common/toast";

export const HoursOfOperation = () => {
  const dispatch = useDispatch();

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );

  const [locationIdSelected, setLocationIdSelected] = useState<string>(
    restaurant.locations[0].id,
  );
  const [editedHoursOfOperation, setEditedHoursOfOperation] =
    useState<HoursOfOperationFragmentWithMode>(
      restaurant.locations.find(
        (location) => location.id === locationIdSelected,
      )?.hoursOfOperation as HoursOfOperationFragmentWithMode,
    );
  const [isUpdatingHoursOfOperationMode, setIsUpdatingHoursOfOperationMode] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const savedHoursOfOperationSelected = useMemo(() => {
    const locationSelected = restaurant.locations.find(
      (location) => location.id === locationIdSelected,
    ) as LocationFragment;

    return locationSelected.hoursOfOperation;
  }, [
    restaurant,
    locationIdSelected,
    restaurant.locations,
    isSaving,
    isUpdatingHoursOfOperationMode,
  ]);

  const isLocationClosedUntilX = useMemo(() => {
    return (
      savedHoursOfOperationSelected.modeSelected ===
        HoursOfOperationMode.CLOSED_UNTIL_X &&
      savedHoursOfOperationSelected.closedUntilX
    );
  }, [savedHoursOfOperationSelected]);

  const dateClosedUntilFormatted = useMemo(() => {
    if (!savedHoursOfOperationSelected.closedUntilX) {
      return "";
    }

    const dateClosedUntil = new Date(
      savedHoursOfOperationSelected.closedUntilX,
    );

    return dateClosedUntil.toLocaleTimeString([], {
      weekday: "long",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }, [savedHoursOfOperationSelected]);

  const isLocationInsideHoursOfOperation = useMemo(() => {
    return isNowWithinOpenHoursOfOperation(savedHoursOfOperationSelected);
  }, [savedHoursOfOperationSelected]);

  const isThereChangesToHoursOfOperation = useMemo(() => {
    const clonedSaved = _.cloneDeep(
      savedHoursOfOperationSelected,
    ) as HoursOfOperationFragmentWithMode & {
      createdAt?: string;
      updatedAt?: string;
    };
    const clonedEdited = _.cloneDeep(
      editedHoursOfOperation,
    ) as HoursOfOperationFragmentWithMode & {
      createdAt?: string;
      updatedAt?: string;
    };

    delete clonedEdited.closedUntilX;
    delete clonedSaved.closedUntilX;
    delete clonedSaved.createdAt;
    delete clonedSaved.updatedAt;
    delete clonedEdited.createdAt;
    delete clonedEdited.updatedAt;

    return JSON.stringify(clonedSaved) !== JSON.stringify(clonedEdited);
  }, [savedHoursOfOperationSelected, editedHoursOfOperation, isSaving]);

  const handleUpdateHoursOfOperationMode = useCallback(async () => {
    setIsUpdatingHoursOfOperationMode(true);

    const newModeSelected =
      savedHoursOfOperationSelected.modeSelected ===
      HoursOfOperationMode.AUTOMATIC
        ? HoursOfOperationMode.CLOSED_UNTIL_X
        : HoursOfOperationMode.AUTOMATIC;

    if (newModeSelected === HoursOfOperationMode.CLOSED_UNTIL_X) {
      const nextOpenTime = getNextOpenTime(savedHoursOfOperationSelected);

      await updateHoursOfOperationModeInDatabaseAction(
        locationIdSelected,
        newModeSelected,
        nextOpenTime,
      )(dispatch);

      showToast("Restaurant marked as closed until tomorrow.");

      setEditedHoursOfOperation({
        ...savedHoursOfOperationSelected,
        modeSelected: newModeSelected,
        closedUntilX: nextOpenTime,
      });
    } else {
      await updateHoursOfOperationModeInDatabaseAction(
        locationIdSelected,
        newModeSelected,
      )(dispatch);

      showToast("Restaurant is now using hours of operation.");

      setEditedHoursOfOperation({
        ...savedHoursOfOperationSelected,
        modeSelected: newModeSelected,
      });
    }

    logUpdateHoursOfOperationModeInAnalytics(
      restaurant.id,
      locationIdSelected,
      newModeSelected,
    );

    setIsUpdatingHoursOfOperationMode(false);
  }, [savedHoursOfOperationSelected, locationIdSelected, restaurant, dispatch]);

  const validateChangesToRestaurantDetails = useCallback((): boolean => {
    if (!areAllCloseTimesAfterOpenTimes(editedHoursOfOperation)) {
      setError("Please ensure that all close times are after open times.");
      return false;
    }

    if (!areAllHoursNonOverlapping(editedHoursOfOperation)) {
      setError("Your hours of operation cannot overlap.");
      return false;
    }

    return true;
  }, [editedHoursOfOperation]);

  const handleSaveHoursOfOperation = useCallback(async () => {
    if (!isSaving && validateChangesToRestaurantDetails()) {
      setIsSaving(true);

      await updateLocationHoursInDatabaseAction(
        locationIdSelected,
        editedHoursOfOperation,
      )(dispatch);

      logUpdateHoursOfOperationInAnalytics(restaurant.id, locationIdSelected);

      setIsSaving(false);
      showToast("Hours of operation saved successfully.");
    }
  }, [
    editedHoursOfOperation,
    isSaving,
    dispatch,
    restaurant,
    locationIdSelected,
  ]);

  return (
    <div className={styles.SettingContainer}>
      <div
        className={styles.HoursOfOperation}
        data-testid="hours-of-operation-container"
      >
        <PageTitle
          title="Hours of Operation"
          subtitle={`Manage what times your customers can order from your locations`}
        />
        <select
          className={styles.selectDropdown}
          data-testid="hours-of-operation-location-dropdown"
          value={locationIdSelected}
          onChange={(e) => {
            const { value } = e.target;

            const newLocation = restaurant.locations.find(
              (location) => location.id === value,
            ) as LocationFragment;

            setEditedHoursOfOperation(newLocation.hoursOfOperation);
            setLocationIdSelected(value);
          }}
        >
          {restaurant.locations.map((location) => (
            <option
              key={location.id}
              value={location.id}
              data-testid={`hours-of-operation-location-dropdown-item-${location.id}`}
            >
              {getAddressStringFromLocationEntity(location)}
            </option>
          ))}
        </select>
        <div className={styles.indicatorRow}>
          <div
            className={classNames(styles.indicator, {
              [styles.redIndicator]:
                isLocationClosedUntilX || !isLocationInsideHoursOfOperation,
            })}
          />
          <h3
            className={classNames(styles.indicatorText, {
              [styles.redIndicatorText]:
                isLocationClosedUntilX || !isLocationInsideHoursOfOperation,
            })}
          >
            {isLocationClosedUntilX
              ? `Closed until ${dateClosedUntilFormatted}`
              : isLocationInsideHoursOfOperation
                ? "Open"
                : "Outside Hours of Operation"}
          </h3>
        </div>
        {isUpdatingHoursOfOperationMode ? (
          <ReactLoading
            type="spin"
            color={palette.blue}
            height={30}
            width={30}
            className={styles.updatingModeLoadingIndicator}
          />
        ) : (
          <Button
            testId={
              savedHoursOfOperationSelected.modeSelected ===
              HoursOfOperationMode.AUTOMATIC
                ? "hours-of-operation-close-until-next-working-day-button"
                : "hours-of-operation-use-hours-of-operation-button"
            }
            className={styles.updateHoursModeButton}
            text={
              savedHoursOfOperationSelected.modeSelected ===
              HoursOfOperationMode.AUTOMATIC
                ? "Close Until Next Working Day"
                : "Use Hours of Operation"
            }
            onClick={handleUpdateHoursOfOperationMode}
            error={
              savedHoursOfOperationSelected.modeSelected ===
              HoursOfOperationMode.AUTOMATIC
            }
          />
        )}
        {daysOfWeek.map((day) => {
          const hoursOfOperationForDay =
            editedHoursOfOperation[day as keyof HoursOfOperationFragment];

          return (
            <div className={styles.dayContainer} key={day}>
              <p
                className={styles.dayName}
              >{`${day.charAt(0).toUpperCase()}${day.substring(1)}`}</p>
              <div className={styles.dayRow}>
                <div className={styles.switchRow}>
                  <Switch
                    data-testid={`hours-of-operation-${day}-switch`}
                    onChange={(value) => {
                      setEditedHoursOfOperation({
                        ...editedHoursOfOperation,
                        [day]: value
                          ? [
                              {
                                open: NINE_AM_AS_ISO_STRING,
                                close: NINE_PM_AS_ISO_STRING,
                              },
                            ]
                          : null,
                      });
                    }}
                    checked={!!hoursOfOperationForDay}
                    onColor={palette.blue}
                    offColor={palette.lightGray}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    className={styles.switch}
                  />
                  <p className={styles.switchText}>
                    {hoursOfOperationForDay ? "Open" : "Closed"}
                  </p>
                </div>
                {hoursOfOperationForDay && (
                  <div className={styles.timesContainer}>
                    {hoursOfOperationForDay.map((eachTime, i) => (
                      <div className={styles.timesRow} key={i}>
                        <div
                          className={classNames(styles.eachTime, {
                            [styles.eachTimeWithRemoveButton]:
                              hoursOfOperationForDay.length > 1,
                          })}
                        >
                          <p className={styles.opensClosesAtText}>Opens At</p>
                          <input
                            data-testid={`hours-of-operation-${day}-open-time-input-${
                              i + 1
                            }`}
                            className={styles.timeTextContainer}
                            type="time"
                            value={formatISOStringTo24HourTime(eachTime.open)}
                            onChange={(e) => {
                              const new24HourTime = e.target.value;
                              const isoString =
                                format24HourTimeToISOString(new24HourTime);

                              const newHoursOfOperation = _.cloneDeep(
                                editedHoursOfOperation,
                              );

                              (newHoursOfOperation[day] as DayOfTheWeekHours[])[
                                i
                              ] = {
                                ...(
                                  newHoursOfOperation[
                                    day
                                  ] as DayOfTheWeekHours[]
                                )[i],
                                open: isoString,
                              };

                              setEditedHoursOfOperation(newHoursOfOperation);
                            }}
                          />
                        </div>
                        <div
                          className={classNames(styles.eachTime, {
                            [styles.eachTimeWithRemoveButton]:
                              hoursOfOperationForDay.length > 1,
                          })}
                        >
                          <p className={styles.opensClosesAtText}>Closes At</p>
                          <input
                            data-testid={`hours-of-operation-${day}-close-time-input-${
                              i + 1
                            }`}
                            className={styles.timeTextContainer}
                            type="time"
                            value={formatISOStringTo24HourTime(eachTime.close)}
                            onChange={(e) => {
                              const new24HourTime = e.target.value;
                              const isoString =
                                format24HourTimeToISOString(new24HourTime);

                              const newHoursOfOperation = _.cloneDeep(
                                editedHoursOfOperation,
                              );

                              (newHoursOfOperation[day] as DayOfTheWeekHours[])[
                                i
                              ] = {
                                ...(
                                  newHoursOfOperation[
                                    day
                                  ] as DayOfTheWeekHours[]
                                )[i],
                                close: isoString,
                              };

                              setEditedHoursOfOperation(newHoursOfOperation);
                            }}
                          />
                        </div>
                        {hoursOfOperationForDay.length > 1 && (
                          <div
                            className={styles.removeTimeButton}
                            data-testid={`hours-of-operation-${day}-remove-time-button-${
                              i + 1
                            }`}
                            onClick={() => {
                              setEditedHoursOfOperation({
                                ...editedHoursOfOperation,
                                [day]: hoursOfOperationForDay.filter(
                                  (_, index) => index !== i,
                                ),
                              });
                            }}
                          >
                            <p className={styles.removeTimeText}>-</p>
                          </div>
                        )}
                      </div>
                    ))}
                    <div
                      className={styles.addTimeButton}
                      data-testid={`hours-of-operation-${day}-add-time-button`}
                      onClick={() => {
                        setEditedHoursOfOperation({
                          ...editedHoursOfOperation,
                          [day]: [
                            ...hoursOfOperationForDay,
                            {
                              open: NINE_AM_AS_ISO_STRING,
                              close: NINE_PM_AS_ISO_STRING,
                            },
                          ],
                        });
                      }}
                    >
                      <p className={styles.addTimeText}>+</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isThereChangesToHoursOfOperation && (
        <div
          data-testid="hours-of-operation-save-button"
          className={styles.bottomSaveButton}
          onClick={handleSaveHoursOfOperation}
        >
          {isSaving ? (
            <ReactLoading
              data-testid={"hours-of-operation-loading-indicator"}
              type="spin"
              color={palette.white}
              height={30}
              width={30}
            />
          ) : (
            <p className={styles.saveText}>Save Changes</p>
          )}
        </div>
      )}
      <BasicModal
        testId="hours-of-operation-error-modal"
        isModalVisible={error !== undefined}
        title="Error"
        message={error}
        onClickOutside={() => setError(undefined)}
        onConfirm={() => setError(undefined)}
        confirmText="Ok"
      />
    </div>
  );
};
