import { useSelector } from "react-redux";
import { CateringRequestFragment } from "src/state/catering/types";
import { RestaurantFragment } from "src/state/restaurant/types";
import { State } from "src/state/state";
import styles from "src/pages/CateringRequest/RequestDetails/styles.module.scss";
import { formatISOStringToShortDateAndTime } from "src/common/date";
import { formatNumber } from "src/common/number";

interface ComponentProps {
  cateringRequestId: string;
}

export const RequestDetails = ({ cateringRequestId }: ComponentProps) => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant as RestaurantFragment,
  );
  const cateringRequest = useSelector(
    (state: State) =>
      restaurant &&
      state.catering[restaurant.id] &&
      state.catering[restaurant.id][cateringRequestId],
  ) as CateringRequestFragment;

  return (
    <div className={styles.RequestDetails}>
      <h3 className={styles.title}>Request Details</h3>

      <p className={styles.requestDetailTitle}>
        {`Number of People: `}
        <span
          className={styles.requestDetailValue}
          data-testid="request-details-number-of-people"
        >
          {cateringRequest.estimatedNumberOfGuests}
        </span>
      </p>

      <p className={styles.requestDetailTitle}>
        {`Budget: `}
        <span
          className={styles.requestDetailValue}
          data-testid="request-details-budget"
        >
          {`${formatNumber(cateringRequest.estimatedBudget)}`}
        </span>
      </p>

      <p className={styles.requestDetailTitle}>
        {`Event Date: `}
        <span
          className={styles.requestDetailValue}
          data-testid="request-details-event-date"
        >
          {formatISOStringToShortDateAndTime(cateringRequest.eventDate)}
        </span>
      </p>

      <p className={styles.requestDetailTitle}>
        {`Event Location: `}
        <span
          className={styles.requestDetailValue}
          data-testid="request-details-event-location"
        >
          {cateringRequest.eventLocation}
        </span>
      </p>
    </div>
  );
};
