import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useRef, useState } from "react";
import styles from "src/components/PhotoChooser/styles.module.scss";

interface ComponentProps {
  photo: string | undefined;
  onPhotoChange: (photo: string | undefined) => void;
}

export const PhotoChooser = ({ photo, onPhotoChange }: ComponentProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (file: File | null) => {
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            onPhotoChange(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    handleFileChange(file);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    handleFileChange(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      className={classNames(styles.PhotoChooser, {
        [styles.isDragging]: isDragging,
      })}
    >
      <div>
        {photo ? (
          <img
            className={styles.photosSelectorImage}
            src={photo}
            onClick={triggerFileInput}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          />
        ) : (
          <div
            className={styles.noImageContainer}
            onClick={triggerFileInput}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <FontAwesomeIcon
              className={styles.icon}
              icon={faCamera}
              size="2x"
            />
          </div>
        )}
        <p className={styles.editPhotoText} onClick={triggerFileInput}>
          Edit Photo
        </p>
      </div>
      {photo && (
        <p
          className={styles.removePhotoText}
          onClick={() => onPhotoChange(undefined)}
        >
          Remove Photo
        </p>
      )}
      <input
        type="file"
        ref={fileInputRef}
        accept="image/jpeg, image/png"
        style={{ display: "none" }}
        onChange={handleInputChange}
      />
    </div>
  );
};
