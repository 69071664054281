import { OptionFragment } from "src/state/option/types";
import { OptionValueFragment } from "src/common/types/OptionValue";
import { PromoCode } from "src/common/types/PromoCode";

export enum ORDER_STATUS {
  NEW_ORDER = "NEW_ORDER",
  IN_PROGRESS = "IN_PROGRESS",
  EN_ROUTE_TO_CUSTOMER = "EN_ROUTE_TO_CUSTOMER",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export enum ORDER_TYPE {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
}

export interface CustomerFragment {
  id: string;
  restaurantId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  points: number;
}

export interface GuestCustomerFragment {
  id: string;
  restaurantId: string;
  name: string;
  phone: string;
}

export interface DeliveryFragment {
  restaurantId: string;
  orderId: string;
  deliveryFee: number;
  paymentProcessingFee: number;
  customerAddress: string;
  deliveryInstructions: string;
  contactlessDelivery: boolean;
  driverTipInDollars: number;
  quoteAccepted: boolean;
  estimatedPickupTime: string;
  actualPickupTime?: string;
  estimatedDeliveryTime: string;
  actualDeliveryTime?: string;
  trackingUrl?: string;
  dropoffImageUrl: string;
  supportReference: string;
}

export interface OrderItemOptionFragment {
  id: string;
  orderItemId: string;
  optionId: string;
  optionValueIds: string[];
  option: OptionFragment;
  optionValues: OptionValueFragment[];
}

export interface OrderItemFragment {
  id: string;
  orderItemOptions: OrderItemOptionFragment[];
  orderId: string;
  itemName: string;
  itemId: string;
  dealAppliedId?: string | undefined;
  quantity: number;
  priceBeforeDealBeforeOptions: number;
  priceAfterDealBeforeOptions: number;
  priceAfterDealAfterOptions: number;
  isRefunded: boolean;
}

export interface OrderFragment {
  id: string;
  orderItems: OrderItemFragment[];
  customer: CustomerFragment | null;
  guestCustomer: GuestCustomerFragment | null;
  customerId: string | null;
  guestCustomerId: string | null;
  delivery: DeliveryFragment | null;
  deliveryId: string | null;
  orderImageUrl: string;
  orderNumber: number;
  restaurantId: string;
  restaurantWideDiscountId: string | undefined;
  subtotal: number;
  pointsUsedInDollars: number;
  priceAfterRestaurantWideDiscount: number;
  priceAfterPromoCode: number;
  salesTax: number;
  totalPriceCharged: number;
  paymentProcessingFee: number;
  status: ORDER_STATUS;
  orderType: ORDER_TYPE;
  pickupTime: string | undefined;
  createdAt: string;
  additionalNotes: string;
  tipAmount: number;
  serviceFee: number;
  cancellationReason: string;
  locationId: string;
  promoCode: PromoCode | undefined;
  promoCodeId: string | undefined;
}
