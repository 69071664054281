// The initializing actions dispatched to Redux
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { RestaurantFragment } from "src/state/restaurant/types";
import { setRestaurantFromDatabaseAction } from "src/state/restaurant/actions";

export const initializeState = async (
  restaurantId: string,
  dispatch: Dispatch,
) => {
  const response = (await post({
    service: "thanos",
    endpoint: "/initialize/restaurant_website",
    params: {
      restaurantId,
    },
  })) as {
    restaurant: RestaurantFragment;
  };

  await Promise.all([
    setRestaurantFromDatabaseAction(response.restaurant)(dispatch),
  ]);

  return response;
};
