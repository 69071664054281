import classNames from "classnames";
import styles from "src/pages/Home/StatCard/styles.module.scss";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";

interface ComponentProps {
  className?: string;
  isLoading: boolean;
  title: string;
  stat: string;
  statTestId: string;
}

export const StatCard = ({
  className,
  isLoading,
  title,
  stat,
  statTestId,
}: ComponentProps) => {
  return (
    <div
      className={classNames(
        styles.StatCard,
        {
          [styles.loadingContainer]: isLoading,
        },
        className,
      )}
    >
      {isLoading ? (
        <ReactLoading type="spin" color={palette.blue} height={40} width={40} />
      ) : (
        <>
          <p className={styles.title}>{title}</p>
          <h4 className={styles.stat} data-testid={statTestId}>
            {stat}
          </h4>
        </>
      )}
    </div>
  );
};
