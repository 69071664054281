import { useDispatch, useSelector } from "react-redux";
import { PageTitle, TextInput } from "src/components";
import styles from "src/pages/SocialMediaLinks/styles.module.scss";
import {
  RestaurantFragment,
  SocialMediaLinksFragment,
} from "src/state/restaurant/types";
import { State } from "src/state/state";
import { useCallback, useMemo, useState } from "react";
import { updateRestaurantSocialMediaLinksInDatabaseAction } from "src/state/restaurant/actions";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { showToast } from "src/common/toast";
import { logUpdateSocialMediaLinksInAnalytics } from "src/common/analytics";
import facebookLogo from "src/assets/facebook.webp";
import instagramLogo from "src/assets/instagram.webp";
import yelpLogo from "src/assets/yelp.webp";
import doordashLogo from "src/assets/doordash.webp";
import ubereatsLogo from "src/assets/ubereats.webp";
import grubhubLogo from "src/assets/grubhub.webp";
import postmatesLogo from "src/assets/postmates.webp";

export const SocialMediaLinks = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  ) as RestaurantFragment;

  const socialMediaLinks = useSelector(
    (state: State) => state.restaurants.currentRestaurant?.socialMediaLinks,
  ) as SocialMediaLinksFragment;

  const [isLoading, setIsLoading] = useState(false);
  const [facebook, setFacebook] = useState(socialMediaLinks.facebook);
  const [instagram, setInstagram] = useState(socialMediaLinks.instagram);
  const [yelp, setYelp] = useState(socialMediaLinks.yelp);
  const [doordash, setDoordash] = useState(socialMediaLinks.doordash);
  const [ubereats, setUbereats] = useState(socialMediaLinks.ubereats);
  const [grubhub, setGrubhub] = useState(socialMediaLinks.grubhub);
  const [postmates, setPostmates] = useState(socialMediaLinks.postmates);

  const dispatch = useDispatch();

  const isThereChangesToSocialMedias = useMemo(() => {
    if (
      socialMediaLinks.facebook !== facebook ||
      socialMediaLinks.instagram !== instagram ||
      socialMediaLinks.yelp !== yelp ||
      socialMediaLinks.doordash !== doordash ||
      socialMediaLinks.ubereats !== ubereats ||
      socialMediaLinks.grubhub !== grubhub ||
      socialMediaLinks.postmates !== postmates
    ) {
      return true;
    }

    return false;
  }, [
    socialMediaLinks,
    facebook,
    instagram,
    yelp,
    doordash,
    ubereats,
    grubhub,
    postmates,
  ]);

  const saveChanges = useCallback(async () => {
    setIsLoading(true);

    await updateRestaurantSocialMediaLinksInDatabaseAction(
      restaurant.id,
      restaurant.socialMediaLinks.id,
      facebook === "" ? null : facebook,
      instagram === "" ? null : instagram,
      yelp === "" ? null : yelp,
      doordash === "" ? null : doordash,
      ubereats === "" ? null : ubereats,
      grubhub === "" ? null : grubhub,
      postmates === "" ? null : postmates,
    )(dispatch);

    logUpdateSocialMediaLinksInAnalytics(
      restaurant.id,
      facebook,
      instagram,
      yelp,
      doordash,
      ubereats,
      grubhub,
      postmates,
    );

    showToast("Social media links saved successfully");

    setIsLoading(false);
  }, [
    facebook,
    instagram,
    yelp,
    doordash,
    ubereats,
    grubhub,
    postmates,
    restaurant,
    dispatch,
  ]);

  const socialMediaTextInputs = [
    {
      label: "Facebook",
      value: facebook,
      onChangeText: setFacebook,
      placeholder: "Enter your Facebook link here...",
      testId: "social-media-facebook-input",
      image: facebookLogo,
    },
    {
      label: "Instagram",
      value: instagram,
      onChangeText: setInstagram,
      placeholder: "Enter your Instagram link here...",
      testId: "social-media-instagram-input",
      image: instagramLogo,
    },
    {
      label: "Yelp",
      value: yelp,
      onChangeText: setYelp,
      placeholder: "Enter your Yelp link here...",
      testId: "social-media-yelp-input",
      image: yelpLogo,
    },
    {
      label: "DoorDash",
      value: doordash,
      onChangeText: setDoordash,
      placeholder: "Enter your DoorDash link here...",
      testId: "social-media-doordash-input",
      image: doordashLogo,
    },
    {
      label: "UberEats",
      value: ubereats,
      onChangeText: setUbereats,
      placeholder: "Enter your UberEats link here...",
      testId: "social-media-ubereats-input",
      image: ubereatsLogo,
    },
    {
      label: "GrubHub",
      value: grubhub,
      onChangeText: setGrubhub,
      placeholder: "Enter your GrubHub link here...",
      testId: "social-media-grubhub-input",
      image: grubhubLogo,
    },
    {
      label: "Postmates",
      value: postmates,
      onChangeText: setPostmates,
      placeholder: "Enter your Postmates link here...",
      testId: "social-media-postmates-input",
      image: postmatesLogo,
    },
  ];

  return (
    <div className={styles.SocialMediaLinksContainer}>
      <div
        className={styles.SocialMediaLinks}
        data-testid="social-media-links-container"
      >
        <PageTitle
          title="Social Media Links"
          subtitle={`Customize which social media links are displayed on your website.`}
        />
        {socialMediaTextInputs.map((input) => {
          return (
            <div key={input.testId}>
              <div className={styles.socialMediaLabelContainer}>
                <img src={input.image} className={styles.socialMediaImage} />
                <h3 className={styles.socialMediaLabel}>{input.label}</h3>
              </div>
              <TextInput
                className={styles.socialMediaContainer}
                inputClassName={styles.socialMediaInput}
                data-testid={input.testId}
                value={input.value}
                onChangeText={(newText) => input.onChangeText(newText)}
                placeholder={input.placeholder}
              />
            </div>
          );
        })}
      </div>
      {isThereChangesToSocialMedias && (
        <div
          data-testid="social-media-links-save-button"
          className={styles.bottomSaveButton}
          onClick={saveChanges}
        >
          {isLoading ? (
            <ReactLoading
              data-testid={"social-media-links-loading-indicator"}
              type="spin"
              color={palette.white}
              height={30}
              width={30}
            />
          ) : (
            <p className={styles.saveText}>Save Changes</p>
          )}
        </div>
      )}
    </div>
  );
};
