// Root reducer for the web
import { combineReducers } from "redux";
import { authReducer } from "src/state/auth/reducer";
import { restaurantReducer } from "src/state/restaurant/reducer";
import { categoryReducer } from "src/state/category/reducer";
import { itemReducer } from "src/state/item/reducer";
import { optionReducer } from "src/state/option/reducer";
import { dealReducer } from "src/state/deal/reducer";
import { restaurantAnalyticsReducer } from "src/state/restaurantAnalytics/reducer";
import { orderReducer } from "src/state/order/reducer";
import { AUTH_ACTION } from "src/state/auth/actions";
import { cateringReducer } from "src/state/catering/reducer";
import { sessionReducer } from "src/state/session/reducer";

const appReducer = combineReducers({
  auth: authReducer,
  restaurants: restaurantReducer,
  categories: categoryReducer,
  items: itemReducer,
  options: optionReducer,
  deals: dealReducer,
  restaurantAnalytics: restaurantAnalyticsReducer,
  orders: orderReducer,
  catering: cateringReducer,
  session: sessionReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: any) => {
  if (
    action.type === "CLEAR_REDUX_STATE" ||
    action.type === AUTH_ACTION.SIGN_OUT_USER_ACTION
  ) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
