// Actions associated with deal
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { DealFragment, DEAL_ENTITY_TYPE } from "src/state/deal/types";
import {
  ITEM_ACTION,
  UpdatePublishedStatusForMultipleItemsInDatabaseAction,
} from "src/state/item/actions";
import {
  CATEGORY_ACTION,
  UpdatePublishedStatusForMultipleCategoriesInDatabaseAction,
} from "src/state/category/actions";
import { State } from "src/state/state";

export enum DEAL_ACTION {
  UPDATE_DEAL_PUBLISHED_STATUS_ACTION = "UPDATE_DEAL_PUBLISHED_STATUS_ACTION",
  GET_DEALS_FOR_RESTAURANT_ACTION = "GET_DEALS_FOR_RESTAURANT_ACTION",
  DELETE_DEAL_FROM_DATABASE_ACTION = "DELETE_DEAL_FROM_DATABASE_ACTION",
}

export interface UpdateDealPublishStatusAction {
  type: DEAL_ACTION.UPDATE_DEAL_PUBLISHED_STATUS_ACTION;
  response: DealFragment;
}

export const updateDealPublishStatusAction = (
  deal: DealFragment,
  isPublished: boolean,
) => {
  return async (
    dispatch: Dispatch<
      | UpdateDealPublishStatusAction
      | UpdatePublishedStatusForMultipleCategoriesInDatabaseAction
      | UpdatePublishedStatusForMultipleItemsInDatabaseAction
    >,
    getState: () => State,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/deal/update_published_status",
      params: {
        ids: [deal.id],
        isPublished,
      },
    })) as DealFragment[];

    const currentReduxState = getState();

    if (isPublished) {
      for (let i = 0; i < deal.entityIds.length; i++) {
        const entityId = deal.entityIds[i];
        const entityType = deal.entityTypes[i];

        if (entityType === DEAL_ENTITY_TYPE.CATEGORY) {
          const category =
            currentReduxState.categories[deal.restaurantId][entityId];

          const action = {
            type: CATEGORY_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION,
            restaurantId: deal.restaurantId,
            response: [{ ...category, isPublished }],
          } as UpdatePublishedStatusForMultipleCategoriesInDatabaseAction;
          dispatch(action);
        } else if (entityType === DEAL_ENTITY_TYPE.ITEM) {
          const item = currentReduxState.items[deal.restaurantId][entityId];
          const category =
            currentReduxState.categories[deal.restaurantId][item.categoryId];

          const updatePublishStatusForItemAction = {
            type: ITEM_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_ITEMS_IN_DATABASE_ACTION,
            response: {
              itemIds: [item.id],
              isPublished,
              restaurantId: item.restaurantId,
            },
          } as UpdatePublishedStatusForMultipleItemsInDatabaseAction;
          dispatch(updatePublishStatusForItemAction);

          const action = {
            type: CATEGORY_ACTION.UPDATE_PUBLISHED_STATUS_FOR_MULTIPLE_CATEGORIES_IN_DATABASE_ACTION,
            restaurantId: deal.restaurantId,
            response: [
              {
                ...category,
                isPublished,
              },
            ],
          } as UpdatePublishedStatusForMultipleCategoriesInDatabaseAction;
          dispatch(action);
        }
      }
    }

    const action = {
      type: DEAL_ACTION.UPDATE_DEAL_PUBLISHED_STATUS_ACTION,
      response: response[0],
    } as UpdateDealPublishStatusAction;

    dispatch(action);

    return action;
  };
};

export interface GetDealsForRestaurantAction {
  type: DEAL_ACTION.GET_DEALS_FOR_RESTAURANT_ACTION;
  restaurantId: string;
  response: DealFragment[];
}

export const getDealsForRestaurantAction = (restaurantId: string) => {
  return async (dispatch: Dispatch<GetDealsForRestaurantAction>) => {
    const deals = (await post({
      service: "thanos",
      endpoint: "/deal/list",
      params: {
        restaurantId,
      },
    })) as DealFragment[];

    const action = {
      type: DEAL_ACTION.GET_DEALS_FOR_RESTAURANT_ACTION,
      restaurantId,
      response: deals.map((deal) => ({
        ...deal,
        // Resets the image url to force a reload
        imageUrl: `${deal.imageUrl}?${Date.now()}`,
      })),
    } as GetDealsForRestaurantAction;

    dispatch(action);

    return action;
  };
};

export interface DeleteDealFromDatabaseAction {
  type: DEAL_ACTION.DELETE_DEAL_FROM_DATABASE_ACTION;
  restaurantId: string;
  response: {
    dealId: string;
  };
}

export const deleteDealFromDatabaseAction = (
  dealId: string,
  restaurantId: string,
) => {
  return async (dispatch: Dispatch<DeleteDealFromDatabaseAction>) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/deal/delete",
      params: {
        id: dealId,
      },
    })) as {
      dealId: string;
    };

    const action = {
      type: DEAL_ACTION.DELETE_DEAL_FROM_DATABASE_ACTION,
      restaurantId,
      response,
    } as DeleteDealFromDatabaseAction;

    dispatch(action);

    return action;
  };
};
