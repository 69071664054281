import styles from "src/components/TextInput/styles.module.scss";
import classNames from "classnames";

interface TextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  className?: string;
  testIdPrefix?: string;
  textPrefix?: string;
  onChangeText?: (text: string) => void;
  label?: string;
  errorMessage?: string;
  inputClassName?: string;
  multiline?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  const {
    className,
    testIdPrefix,
    textPrefix,
    onChangeText,
    label,
    errorMessage,
    inputClassName,
    multiline,
    ...rest
  } = props;

  return (
    <div
      className={classNames(styles.TextInput, className)}
      data-testid={`${testIdPrefix}-container`}
    >
      {label && (
        <h3
          className={classNames(styles.label, {
            [styles.errorLabel]: errorMessage !== undefined,
          })}
          data-testid={`${testIdPrefix}-label`}
        >
          {label}
        </h3>
      )}
      <div className={styles.inputPrefixContainer}>
        {textPrefix && <h3 className={styles.textPrefix}>{textPrefix}</h3>}
        {multiline ? (
          <textarea
            data-testid={`${testIdPrefix}-input`}
            className={classNames(
              styles.input,
              styles.textarea,
              {
                [styles.errorInput]: errorMessage !== undefined,
              },
              inputClassName,
            )}
            onChange={(e) => onChangeText && onChangeText(e.target.value)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(rest as any)}
          />
        ) : (
          <input
            data-testid={`${testIdPrefix}-input`}
            className={classNames(
              styles.input,
              {
                [styles.errorInput]: errorMessage !== undefined,
              },
              inputClassName,
            )}
            onChange={(e) => onChangeText && onChangeText(e.target.value)}
            {...rest}
          />
        )}
      </div>
      {errorMessage && (
        <h3
          className={styles.errorMessage}
          data-testid={`${testIdPrefix}-error-message`}
        >
          {errorMessage}
        </h3>
      )}
    </div>
  );
};
