// Actions associated with restaurant analytics
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { RestaurantAnalyticsFragment } from "src/state/restaurantAnalytics/types";

export enum RESTAURANT_ANALYTICS_ACTION {
  GET_RESTAURANT_ANALYTICS_FOR_DATE_RANGE_ACTION = "GET_RESTAURANT_ANALYTICS_FOR_DATE_RANGE_ACTION",
}

export interface GetRestaurantAnalyticsForDateRangeAction {
  type: RESTAURANT_ANALYTICS_ACTION.GET_RESTAURANT_ANALYTICS_FOR_DATE_RANGE_ACTION;
  startDate: string;
  endDate: string;
  response: RestaurantAnalyticsFragment;
}

export const getRestaurantAnalyticsForDateRangeAction = (
  restaurantId: string,
  startDate: string,
  endDate: string,
) => {
  return async (
    dispatch: Dispatch<GetRestaurantAnalyticsForDateRangeAction>,
  ) => {
    const response = (await post({
      service: "thanos",
      endpoint: "/restaurant_analytics/get_analytics_for_dates",
      params: {
        restaurantId,
        startDate,
        endDate,
      },
    })) as RestaurantAnalyticsFragment;

    const action = {
      type: RESTAURANT_ANALYTICS_ACTION.GET_RESTAURANT_ANALYTICS_FOR_DATE_RANGE_ACTION,
      startDate,
      endDate,
      response,
    } as GetRestaurantAnalyticsForDateRangeAction;

    dispatch(action);

    return action;
  };
};
