import { useDispatch, useSelector } from "react-redux";
import { PageTitle, TextInput } from "src/components";
import styles from "src/pages/RestaurantDesign/styles.module.scss";
import {
  RestaurantDesignFragment,
  RestaurantFragment,
} from "src/state/restaurant/types";
import { State } from "src/state/state";
import { useCallback, useMemo, useState } from "react";
import { updateRestaurantDesignSettingsInDatabaseAction } from "src/state/restaurant/actions";
import ReactLoading from "react-loading";
import palette from "src/common/styles/palette.module.scss";
import { showToast } from "src/common/toast";
import { logUpdateDesignSettingsInAnalytics } from "src/common/analytics";

export const RestaurantDesign = () => {
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  ) as RestaurantFragment;
  const designSettings = useSelector(
    (state: State) =>
      state.restaurants.currentRestaurant &&
      state.restaurants.currentRestaurant.restaurantDesign,
  ) as RestaurantDesignFragment;

  const [isLoading, setIsLoading] = useState(false);

  const [announcementBar, setAnnouncementBar] = useState(
    designSettings.announcementBar,
  );

  const dispatch = useDispatch();

  const isThereChangesToDesignSettings = useMemo(() => {
    if (designSettings.announcementBar !== announcementBar) {
      return true;
    }

    return false;
  }, [designSettings, announcementBar]);

  const saveChanges = useCallback(async () => {
    setIsLoading(true);

    await updateRestaurantDesignSettingsInDatabaseAction(
      restaurant.id,
      restaurant.restaurantDesign.id,
      announcementBar,
    )(dispatch);

    logUpdateDesignSettingsInAnalytics(restaurant.id, announcementBar);

    showToast("Design settings saved successfully");

    setIsLoading(false);
  }, [announcementBar, restaurant, dispatch]);

  return (
    <div className={styles.RestaurantDesignContainer}>
      <div className={styles.RestaurantDesign} data-testid="design-container">
        <PageTitle
          title="Design"
          subtitle={`Manage your restaurant's visual and design settings`}
        />
        <h3 className={styles.settingTitle}>Announcement Bar Text</h3>
        <h4 className={styles.settingSubtitle}>
          Announcement bar text will be displayed at the top of your website and
          app.
        </h4>
        <TextInput
          inputClassName={styles.announcementBarInput}
          data-testid="design-announcement-bar-input"
          value={announcementBar}
          onChangeText={(newText) => setAnnouncementBar(newText)}
          placeholder="Enter some text here..."
        />
      </div>
      {isThereChangesToDesignSettings && (
        <div
          data-testid="design-save-button"
          className={styles.bottomSaveButton}
          onClick={saveChanges}
        >
          {isLoading ? (
            <ReactLoading
              data-testid={"design-loading-indicator"}
              type="spin"
              color={palette.white}
              height={30}
              width={30}
            />
          ) : (
            <p className={styles.saveText}>Save Changes</p>
          )}
        </div>
      )}
    </div>
  );
};
